import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Footer, Navbar } from "../../components";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME,Image_Base_Url,ACCESS_Role_ID,ACCESS_Log_In_User_Id } from '../../constants/apiConstants';

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Importing images
import ManageEditIcon from "../../assets/images/icons/manage_edit.svg";
import StudentSidebar from '../../components/StudentSidebar';
import GlobalSearch from '../../components/GlobalSearch';
import CommanStudentTeacherHeader from '../../components/CommanStudentTeacherHeader';


const DashboardPost = () => {
  const navigate = useNavigate();
  const [postBasedOnUserid, setPostBasedOnUserid] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
    const [error, setError] = useState(null); // State to hold error messages
  // useEffect(() => {

  //   const fetchPostBasedOnUserid = async () => {
  //       const payload = {
  //           "user_id": ACCESS_Log_In_User_Id(),
  //       }
  //       try {
  //           axios.post(API_BASE_URL + '/getPostByUserId', payload)
  //               .then(function (response) {
  //                 console.log(response);
  //                   if (response.data.code == 200) {
  //                       console.log(response.data);
  //                       console.log(response.data.data);
  //                       setPostBasedOnUserid(response.data.data);
  //                   }
  //               })
  //               .catch(function (error) {
  //                   console.log(error);
  //               });

  //       } catch (err) {
  //           // Handle errors
  //           setError(err.message);
  //       } finally {
  //           setLoading(false);
  //       }
  //   };
  //   fetchPostBasedOnUserid();
  // }, []); // Empty depend

  useEffect(() => {
    const fetchPostBasedOnUserid = async () => {
      const payload = {
        "user_id": ACCESS_Log_In_User_Id(), // Replace with actual user ID retrieval logic
      };
  
      try {
        const response = await axios.post(
          `${API_BASE_URL}/getPostByUserId`,
          payload,
          {
            headers: {
              "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace with your actual token
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.data.code === 200) {
          console.log("Response Data:", response.data);
          setPostBasedOnUserid(response.data.data);
        }
      } catch (err) {
        console.error("Error fetching posts based on user ID:", err); // Log error for debugging
        setError(err.message); // Handle errors
      } finally {
        setLoading(false);
      }
    };
  
    fetchPostBasedOnUserid();
  }, []); // Empty dependency array to run only on mount
  
  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
    <CommanStudentTeacherHeader/>

    

      <div className="container">
        <div className="row">
         <StudentSidebar/>

          <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5">
          
            <GlobalSearch/>

            <div className="row align-items-center my-3">
              <div className="col-11">
                <p className="learm-tilte my-3">Enrolled Courses</p>
              </div>
              <div className="col-1 text-end">
                <img src={ManageEditIcon} alt="Manage Edit" />
              </div>
            </div>

            {/* Feed Images */}
            <div className="row">
             {/* {[...Array(7)].map((_, index) => ( */}
             {postBasedOnUserid.map((innerArray, i) => (
                <div key={i} class="col-sm-4 col-md-6 col-lg-6 col-xl-4 mb-4 feed_imgs">
                  <Link to={`/dashboardPostDetail/${innerArray.id}`} className="feed_imgs">
                  <img src={`${Image_Base_Url}${innerArray.post_image_path}`} alt="Feed" />
                  </Link>
                </div>
               ))}
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
   <Footer/>
    </div>
  );
};

export default DashboardPost;
