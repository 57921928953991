import React from 'react'
import { Footer, Navbar } from "../components";
const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3"  >
        <h1 className="text-center" style={{ paddingTop: '80px' }}>About Us</h1>
        <hr />
      </div>
      <Footer />
    </>
  )
}

export default AboutPage