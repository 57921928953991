// // export const API_BASE_URL = "http://127.0.0.1:8000/api/rest";//process.env.REACT_APP_SERVER_URL;
// export const API_BASE_URL = "https://api.padhaidekho.com/api/rest";
// export const ACCESS_TOKEN_NAME = localStorage.getItem('ACCESS_TOKEN_NAME');
// export const ACCESS_Role_ID = localStorage.getItem('ACCESS_Role_ID');
// export const ACCESS_Log_In_User_Id = localStorage.getItem('ACCESS_Log_In_User_Id');
// export const Image_Base_Url = 'http://localhost:8000/';


// constants/apiConstants.js
export const API_BASE_URL = "https://api.padhaidekho.com/api/rest";

export const ACCESS_TOKEN_NAME = () => localStorage.getItem('ACCESS_TOKEN_NAME');
export const ACCESS_Role_ID = () => localStorage.getItem('ACCESS_Role_ID');
export const ACCESS_Log_In_User_Id = () => localStorage.getItem('ACCESS_Log_In_User_Id');

export const Image_Base_Url = "https://api.padhaidekho.com/";
