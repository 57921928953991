import React, { useState, useEffect } from 'react';
import '../role/../header/style.css';
//import './styles.css';
import { Header } from "../role";
import { Adminmain, AdminNavbar } from "../role";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url } from '../../../constants/apiConstants';
function ListCourse() {
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [courses, setCourses] = useState([]); // State to hold roles data

    // useEffect(() => {
    //     const url = API_BASE_URL + '/getCourses'
    //     const fetchRoles = async () => {
    //         try {

    //             const response = await axios.get(url);
    //             console.log(response.data);
    //             setCourses(response.data); // Set roles data to state
    //             console.log(courses);
    //         } catch (err) {
    //             // setError(err.message); // Handle error

    //         }
    //     };

    //     fetchRoles(); // Call the fetch function
    // }, []); // Empty dependency array to run only on mount
    // const handelDelete = async (id) => {
    //     console.log("id : -", id);
    //     axios.delete(API_BASE_URL + '/deleteCoursesDataById/' + id)
    //         .then(function (response) {
    //             // console.log(response.data.access_token);
    //             if (response.status === 200) { 
    //                 setCourses(courses.filter((item) => item.id !== id));                   
    //                 navigate("/admin/listCourse");
    //             }
    //             else {
    //                // props.showError("Username does not exists");
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
        
    // };


    useEffect(() => {
        const url = API_BASE_URL + '/getCourses';
        const fetchRoles = async () => {
            try {
                const response = await axios.get(url, {
                    headers: {
                        "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace `yourAccessToken` with the actual token
                        "Content-Type": "application/json",
                    },
                });
                console.log(response.data);
                setCourses(response.data); // Set roles data to state
                console.log(courses);
            } catch (err) {
                console.error(err); // Handle error
            }
        };
    
        fetchRoles(); // Call the fetch function
    }, []); // Empty dependency array to run only on mount
    
    const handelDelete = async (id) => {
        console.log("id : -", id);
    
        axios
            .delete(API_BASE_URL + '/deleteCoursesDataById/' + id, {
                headers: {
                    "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace `yourAccessToken` with the actual token
                    "Content-Type": "application/json",
                },
            })
            .then(function (response) {
                if (response.status === 200) {
                    setCourses(courses.filter((item) => item.id !== id));
                    navigate("/admin/listCourse");
                } else {
                    // Handle other status codes if needed
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    };
    
    return (
        <div>
            <Header />
            <div className="main-container">
                <AdminNavbar />
                {/* <Adminmain /> */}
                <div className="main">
                    <div className="report-container">
                        <div className="report-header">
                            <h1 className="recent-Articles"> Courses List</h1>
                            <Link class="recent-Articles" to={`/admin/createCourse`}> Create New Courses </Link>
                        </div>

                        <div className="report-body">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {courses.map((item, i) => (
                                        <tr key={item.id}>
                                            <td>{i + 1}</td>
                                            <td>{item.course_name}</td>
                                            <td>{item.status}</td>
                                            <td>
                                                <Link to={`/admin/editCourse/${item.id}`}>
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                </Link>

                                                <i
                                                    className="fa fa-trash-o"
                                                    aria-hidden="true"
                                                    onClick={() => handelDelete(item.id)}
                                                ></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListCourse;