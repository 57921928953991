// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.slick-prev {
  left: -60px; /* Adjust the left position to move it outside the slider */
}

.slick-next {
  right: -60px; /* Adjust the right position to move it outside the slider */
}

.slick-prev:hover, .slick-next:hover {
  background-color: orange; /* Change color on hover */
}

.slick-disabled {
  opacity: 0.5; /* Make the arrow look disabled when not clickable */
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/MathSlider.css"],"names":[],"mappings":";AACA;EACE,WAAW,EAAE,2DAA2D;AAC1E;;AAEA;EACE,YAAY,EAAE,4DAA4D;AAC5E;;AAEA;EACE,wBAAwB,EAAE,0BAA0B;AACtD;;AAEA;EACE,YAAY,EAAE,oDAAoD;AACpE","sourcesContent":["\n.slick-prev {\n  left: -60px; /* Adjust the left position to move it outside the slider */\n}\n\n.slick-next {\n  right: -60px; /* Adjust the right position to move it outside the slider */\n}\n\n.slick-prev:hover, .slick-next:hover {\n  background-color: orange; /* Change color on hover */\n}\n\n.slick-disabled {\n  opacity: 0.5; /* Make the arrow look disabled when not clickable */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
