// import { Navbar, Main, Footer,ScriptsLoader } from "../components";
// //import { ScriptsLoader } from "../components/ScriptsLoader";
// function Home() {
//   return (
//     <>
//       <Navbar />
//       <Main />
//       <Footer />
//       <ScriptsLoader />
//     </>
//   )
// }

// export default Home


// import { Navbar, Main, Footer, ScriptsLoader } from "../components";

// function Home() {
  
//   return (
//     <>
//       {/* <div style={{ position: 'fixed', width: '100%', top: 0, zIndex: 1000 }}> */}
//         <Navbar />
//       {/* </div> */}
//       <div style={{ marginTop: '70px' }}>
//         <Main />
//       </div>
//       <Footer />
//       <ScriptsLoader />
//     </>
//   );
// }

// export default Home;


import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Main, Footer, ScriptsLoader } from "../components";

function Home() {
  const location = useLocation();

  useEffect(() => {
    // This effect will run whenever the component is visited or revisited
    console.log("Home component refreshed or revisited");

    // Add any refresh logic here
    // Example: Fetch data, reset states, etc.
  }, [location]); // Triggered whenever the location changes

  return (
    <>
      <Navbar />
      <div style={{ marginTop: "70px" }}>
        <Main />
      </div>
      <Footer />
      <ScriptsLoader />
    </>
  );
}

export default Home;
