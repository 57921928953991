import React, { useEffect, useState } from 'react';
import { Footer, Navbar } from "../components";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url, ACCESS_Role_ID, ACCESS_Log_In_User_Id } from '../constants/apiConstants';
import { formatDate, calculateDifference, dateMonthYears, renderStars } from "./Utils";
import "../assets/css/bootstrap.min.css";
import "../assets/css/fontawesome-all.min.css";
import "../assets/css/slick.css";
import "../assets/css/line-awesome.min.css";
import "../assets/css/main.css";
import "../assets/css/responsive.css";

import MajesticonImage from "../../src/assets/images/icons/majesticons_comment-line.svg";
import CourseImage from '../../src/assets/images/course/image-1.png'
import SearchImage from "../../src/assets/images/all/search.svg";
import LogoImage from "../../src/assets/images/all/scan-svgrepo-com 1.svg";
import { debounce } from 'lodash'; // Optional for debouncing
import shapeImage from "../../src/assets/images/all/shape_1.png"
import shapeImage1 from "../../src/assets/images/icons/Unions.svg"

function Learn() {
  const [course, setCourse] = useState([]); // State to hold roles data
  const navigate = useNavigate();
  // Search Start
  const [query, setQuery] = useState('');
  const [searchCourses, setSearchCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [followedBasedOnUserId, setFollowedBasedOnUserId] = useState([]);
  const [courseFollower, setCourseFollower] = useState([]);

  const redirectToLogin = () => {
    navigate("/login");
}
  // Handle search input change
  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  //Debounced search function to limit API calls
  // const handleSearch = debounce(async () => {
  //   if (!query.trim()) return;

  //   setLoading(true);
  //   setError('');

  //   try {
  //     const response = await axios.get(API_BASE_URL + `/courseSearch?query=${query}`);
  //     console.log(response.data.data);
  //     // setTeachers(response.data.teachers);
  //     setSearchCourses(response.data.data);
  //     // setInstitutes(response.data.institutes);
  //   } catch (err) {
  //     setError('An error occurred while searching.');
  //   } finally {
  //     setLoading(false);
  //   }
  // }, 500); // Debounce delay of 500ms

  const handleSearch = debounce(async () => {
    if (!query.trim()) return;

    setLoading(true);
    setError('');

    try {
        const response = await axios.get(
            `${API_BASE_URL}/courseSearch?query=${query}`,
            {
                headers: {
                    "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace with your actual token
                    "Content-Type": "application/json",
                },
            }
        );
        console.log(response.data.data);
        setSearchCourses(response.data.data);
    } catch (err) {
        console.error("Error during search:", err); // Log the error
        setError('An error occurred while searching.');
    } finally {
        setLoading(false);
    }
}, 500); // Debounce delay of 500ms


  // Trigger search on key press (after debouncing)
  useEffect(() => {
    handleSearch();
  }, [query]); // Trigger handleSearch when the query changes
  //Handle course click to navigate to the detail page
  const handleCourseClick = (courseId) => {
    navigate(`/CourseDetail/${courseId}`); // Navigate to the course detail page
  };

  // Search End
  // Get Followe for course based on course detail id start 

  // useEffect(() => {
  //   const fetchCourseFollowers = async () => {
  //     try {
  //       // Create an array of promises for each product API call
  //       const promises = course.map(item =>
  //         axios.get(`${API_BASE_URL}/getCoursefollowers?course_details_id=${item.id}`)
  //       );
  //       // Wait for all the promises to resolve
  //       const responses = await Promise.all(promises);
  //       const courseFollowers = responses.map(response => response.data);
  //       setCourseFollower(courseFollowers);
  //     } catch (err) {
  //       // Handle errors
  //       setError(err.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchCourseFollowers();
  // }, [course]); // Run the effect when cartItems changes

  useEffect(() => {
    const fetchCourseFollowers = async () => {
      try {
        // Create an array of promises for each product API call
        const promises = course.map(item =>
          axios.get(
            `${API_BASE_URL}/getCoursefollowers?course_details_id=${item.id}`,
            {
              headers: {
                "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace with your actual token
                "Content-Type": "application/json",
              },
            }
          )
        );
  
        // Wait for all the promises to resolve
        const responses = await Promise.all(promises);
        const courseFollowers = responses.map(response => response.data);
        setCourseFollower(courseFollowers);
      } catch (err) {
        console.error("Error fetching course followers:", err); // Log the error
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchCourseFollowers();
  }, [course]); // Dependency array to re-run when `course` changes
  

  // Get Followe for course based on course detail id End 

  
  const url = API_BASE_URL + '/getCoursesDetails'
  // const fetchCourse = async () => {
  //   try {

  //     const response = await axios.get(url);
  //     setCourse(response.data); // Set roles data to state
  //     setLoading(false); // Set loading to false
  //   } catch (err) {
  //     setError(err.message); // Handle error
  //     setLoading(false); // Set loading to false
  //   }
  // };

  const fetchCourse = async () => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace with your actual token
          "Content-Type": "application/json",
        },
      });
      setCourse(response.data); // Set roles data to state
    } catch (err) {
      console.error("Error fetching course:", err); // Log the error
      setError(err.message); // Handle error
    } finally {
      setLoading(false); // Set loading to false
    }
  };
  

  useEffect(() => {
    fetchFollowBasedOnUserId();
    fetchCourse(); // Call the fetch function
  }, []); // Empty dependency array to run only on mount

  // Follow and unfollow start
  // const fetchFollowBasedOnUserId = async () => {
  //   try {
  //     const response = await axios.get(API_BASE_URL + `/followers?user_id= ${ACCESS_Log_In_User_Id()}`) // Replace with actual user id

  //     console.log("response");
  //     console.log(response.data);
  //     setFollowedBasedOnUserId(response.data);
  //     console.log("response");
  //     //setCourse(response.data); // Set roles data to state
  //     setLoading(false); // Set loading to false
  //   } catch (err) {
  //     setError(err.message); // Handle error
  //     setLoading(false); // Set loading to false
  //   }
  // };


  const fetchFollowBasedOnUserId = async () => {
    try {
      const userId = ACCESS_Log_In_User_Id(); // Ensure this function returns the correct user ID
      if (!userId) {
        throw new Error("User ID is missing");
      }
  
      const response = await axios.get(
        `${API_BASE_URL}/followers?user_id=${userId}`, // Use proper string interpolation
        {
          headers: {
            "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace with your actual token
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log("Followers response:", response.data);
      setFollowedBasedOnUserId(response.data);
    } catch (err) {
      console.error("Error fetching followers:", err.message); // Log the error
      setError(err.message); // Handle error
    } finally {
      setLoading(false); // Ensure loading is set to false in all cases
    }
  };
  
  const [followingStatus, setFollowingStatus] = useState(
    course.reduce((acc, item) => {
      acc[item.id] = item.isFollowing || false; // Initialize each course with its follow status
      return acc;
    }, {})
  );

  // const toggleFollow = async (id) => {
  //   const newFollowStatus = !followingStatus[id]; // Toggle follow status for the specific course
  //   setFollowingStatus((prevState) => ({
  //     ...prevState,
  //     [id]: newFollowStatus, // Update the follow status of the specific course
  //   }));

  //   const apiUrl = newFollowStatus ? API_BASE_URL + '/follow' : API_BASE_URL + '/unfollow';
  //   try {
  //     const response = await axios.post(apiUrl, {
  //       user_id: ACCESS_Log_In_User_Id(),
  //       course_details_id: id,
  //     });
  //     fetchFollowBasedOnUserId();
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error(error);
  //     // Revert the state change if the API request fails
  //     setFollowingStatus((prevState) => ({
  //       ...prevState,
  //       [id]: !newFollowStatus, // Revert the follow status in case of an error
  //     }));
  //   }
  // };
  // Follow and unfollow end

  const toggleFollow = async (id) => {
    const newFollowStatus = !followingStatus[id]; // Toggle follow status for the specific course
    setFollowingStatus((prevState) => ({
      ...prevState,
      [id]: newFollowStatus, // Update the follow status of the specific course
    }));
  
    const apiUrl = newFollowStatus ? API_BASE_URL + '/follow' : API_BASE_URL + '/unfollow';
  
    try {
      const response = await axios.post(
        apiUrl,
        {
          user_id: ACCESS_Log_In_User_Id(), // Replace with actual user ID
          course_details_id: id,
        },
        {
          headers: {
            "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace with your actual token
            "Content-Type": "application/json",
          },
        }
      );
  
      fetchFollowBasedOnUserId(); // Refresh the follow data after a successful request
      console.log("Toggle follow response:", response.data);
    } catch (error) {
      console.error("Error toggling follow:", error);
  
      // Revert the state change if the API request fails
      setFollowingStatus((prevState) => ({
        ...prevState,
        [id]: !newFollowStatus, // Revert the follow status in case of an error
      }));
    }
  };
  
  return (
    <div className="App">
      {/* Scroll to Top */}
      <div className="progress-wrap">
        <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>

      {/* Mobile Menu */}
      {/* <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">Home</a>
              </li>
              <li className="nav-menu__item">
                <a href="course.html" className="nav-menu__link">Courses</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Feed</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Videos</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Teachers</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Institutes</a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="javascript:void(0)" className="nav-menu__link">My Profile</a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Edit Profile</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Enrolled Courses</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Your Posts</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Certificates</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Chats</a>
                  </li>
                </ul>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Scan OR Code</a>
              </li>
            </ul>
            <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
              <a href="/" className="btn btn-main pill sign_btn px-sm-4">Signup/Login</a>
            </div>
          </div>
        </div>
      </div> */}

      {/* Shapes */}
      <img className="shape_top d-lg-block d-none" src={shapeImage} alt="" />
      <img className="shape_top_6 d-xl-block d-none" src={shapeImage1} alt="" />

      <main className="change-gradient">
        {/* Header */}
        {/* <header>
        <div className="logo"></div>
        <div className="header-menu d-lg-block d-none">
          <ul className="nav-menu flx-align">
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Home
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/course.html" className="nav-menu__link">
                Courses
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Feed
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Learn with us
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Teachers
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Institute
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                About Us
              </a>
            </li>
          </ul>
        </div>
        <div className="header-right flx-align pe-lg-5">
          <div className="header-right__inner gap-3 flx-align d-lg-flex d-none">
            <a href="/" className="btn btn-main pill sign_btn px-sm-4">
              Signup/Login
            </a>
            <a href="/" className="btn cicrle_btn btn-main">
              <img src={LogoImage} alt="Logo" />
            </a>
          </div>
          <button type="button" className="toggle-mobileMenu d-lg-none">
            <i className="las la-bars"></i>
          </button>
        </div>
      </header> */}
        <Navbar />

        {/* Hero Section */}
        <section className="hero section-bg z-index-1">
          <div className="container py-5 container-two">
            <div className="row align-items-center gy-sm-5 gy-4">
              <div className="col-lg-12 order-1 order-lg-0" style={{ marginTop: '110px' }}>
                <div className="position-relative">
                  <div className="search-box">
                    {/* <input type="text" className="common-input common-input--lg pill shadow-sm auto-suggestion-input" placeholder="Search" />
                    <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                      <img src={SearchImage} alt="Search" />
                    </button> */}
                    <input
                      type="text"
                      value={query}
                      onChange={handleChange}
                      placeholder="Search for courses"
                      className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                    />

                    {loading && <p>Loading...</p>}
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    {searchCourses.length > 0 ? (
                      <ul>
                        {searchCourses.map((course) => (
                          <li key={course.id} onClick={() => handleCourseClick(course.id)}>
                            {course.course_title} - {course.course_description}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No courses found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Courses Section */}
        <section className="pb-5">
          <div className="container">
            <div className="row gy-4 justify-content-center">
              {course.map((item, i) => (
                <div className="col-lg-10 col-xl-8 col-sm-12 col-md-12" key={i}>
                  <div className="learn_withcard position-relative">
                    <div className="row g-0">
                      <div className="text-end position-absolute" style={{ right: "30px" }}>
                        {ACCESS_TOKEN_NAME() ? (
                          // Check if there are any followed courses
                          followedBasedOnUserId && followedBasedOnUserId.length > 0 ? (
                            // Find if the current item's ID is in followedBasedOnUserId
                            <button
                              key={item.id} className="btn btn_cousre learn_follow px-4 py-2"
                              onClick={() => toggleFollow(item.id)}
                            >
                              {followedBasedOnUserId.some(items => items.course_details_id === item.id) ? 'Unfollow' : 'Follow'}
                            </button>
                          ) : (
                            <button className="btn btn_cousre learn_follow px-4 py-2" onClick={() => toggleFollow(item.id)}
                            >
                              {followingStatus[item.id] ? 'Unfollow' : 'Follow'} </button>
                          )
                        ) : (
                          // If there's no access token, show a default follow button
                          <button className="btn btn_cousre learn_follow px-4 py-2" onClick={() => redirectToLogin()}>
                            Follow </button>
                        )}
                      </div>
                      <div className="col-md-4 img">
                        <img src={`${Image_Base_Url}${item.course_image_path}`} alt="How to Solve Physics Problems" className="img-cover w-100 h-100" />
                      </div>
                      <div className="col-md-8 ms-4">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h4 className="course-title learm-tilte">{item.course_title}</h4>
                            {/* <span className="course-time learm-tilte2">30k Followers</span> */}
                            {courseFollower.map((follower, index) => (
                              index === i ? (
                                <span key={index} className="learn-title2">{follower} Followers</span>
                              ) : null
                            ))}
                          </div>
                        </div>
                        <p className="course-description text-muted mt-3">
                          {item.course_description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>


        {/* Footer */}
        <footer className="footer-section">
          <div className="container container-two">
            <div className="row gy-sm-5">
              <div className="col-xl-6 col-sm-6">
                <div className="position-relative mb-5">
                  <div className="search-box">
                    <input type="text" className="common-input common-input--lg pill shadow-sm auto-suggestion-input" placeholder="Search" />
                    <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                      <img src={SearchImage} alt="Search" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 col-xs-6">
                    <div className="footer-widget">
                      <ul className="footer-lists">
                        <li className="footer-lists__item"><a href="/" className="footer-lists__link">Home</a></li>
                        <li className="footer-lists__item"><a href="/" className="footer-lists__link">Courses</a></li>
                        <li className="footer-lists__item"><a href="/" className="footer-lists__link">Feed</a></li>
                        <li className="footer-lists__item"><a href="/" className="footer-lists__link">Teachers</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xs-6 ps-xl-5">
                    <div className="footer-widget">
                      <ul className="footer-lists">
                        <li className="footer-lists__item"><a href="/" className="footer-lists__link">About Us</a></li>
                        <li className="footer-lists__item"><a href="/" className="footer-lists__link">Payment</a></li>
                        <li className="footer-lists__item"><a href="/" className="footer-lists__link">My Profile</a></li>
                        <li className="footer-lists__item"><a href="/" className="footer-lists__link">Institutes</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="footer-widget mt-4">
                  <h3>Follow us on</h3>
                  <div className="footer-widget__social">
                    <ul className="social-icon-list">
                      <li className="social-icon-list__item">
                        <a href="https://www.facebook.com" className="social-icon-list__link flx-center"><i className="fab fa-facebook-f"></i></a>
                      </li>
                      <li className="social-icon-list__item">
                        <a href="https://www.youtube.com" className="social-icon-list__link flx-center"><i className="fab fa-youtube"></i></a>
                      </li>
                      <li className="social-icon-list__item">
                        <a href="https://www.linkedin.com" className="social-icon-list__link flx-center"><i className="fab fa-linkedin-in"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 offset-xl-1 col-sm-6">
                <div className="footer-widget">
                  <div className="map-box mb-30 mt-5">
                    <li className="footer-lists__item"><a href="/" className="footer-lists__link">Location</a></li>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.330999415546!2d78.44399507462819!3d17.44386460117675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917f6ecb6987%3A0x7d6d85b768a4c33b!2sFg%20Designs!5e0!3m2!1sen!2sin!4v1707451998655!5m2!1sen!2sin"
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
}

export default Learn;
