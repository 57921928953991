import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Footer, Navbar } from "../components";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME,Image_Base_Url,ACCESS_Role_ID,ACCESS_Log_In_User_Id } from '../constants/apiConstants';
import '../assets/css/bootstrap.min.css';
import '../assets/css/fontawesome-all.min.css';
import '../assets/css/slick.css';
import '../assets/css/line-awesome.min.css';
import '../assets/css/main.css';
import '../assets/css/responsive.css';

// Import images if they are inside src/assets folder
import ProfileImage from '../../src/assets/images/all/profiel.png';
import FeedDetailsImage from '../../src/assets/images/all/feed-details.png';
import HeartIcon from '../../src/assets/images/icons/iconoir_heart.svg';
import CommentIcon from '../../src/assets/images/icons/majesticons_comment-line.svg';
import SendIcon from '../../src/assets/images/icons/mingcute_send-line.svg';
import MoreIcon from '../../src/assets/images/icons/solar_menu-dots-bold.svg';
import ScanIcon from '../../src/assets/images/all/scan-svgrepo-com 1.svg';
import SearchIcon from '../../src/assets/images/all/search.svg';
import LogoImage from '../../src/assets/images/all/scan-svgrepo-com 1.svg'
import SearchImage from '../../src/assets/images/all/search.svg'
import unionImage from '../../src/assets/images/icons/Union-1.svg'

function FeedDetail(props) {

    const { id } = useParams();
    console.log(id);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [feeds, setFeeds] = useState(""); // State to hold roles data
    const [comments, setComments] = useState([]);
    const [reply, setreply] = useState([]);
    const [commentInput, setCommentInput] = useState('');
    const [replyContent, setReplyContent] = useState('');
    const [author, setAuthor] = useState('');


    useEffect(() => {
        fetchFeeds(); // Call the fetch function
    }, []); // Empty dependency array to run only on mount

    const url = API_BASE_URL + '/getFeedsDetailsById/' + id
    const url2 = API_BASE_URL + '/getPostComments/' + id
    const fetchFeeds = async () => {
        try {
            const [response, response2] = await Promise.all([
                axios.get(url),
                axios.get(url2)
            ]);
            setFeeds(response.data[0]); // Set roles data to state
            console.log(response2.data);
            setComments(response2.data);
        } catch (err) {
            // setError(err.message); // Handle error

        }
    };
    const handleInputChange = (e) => {
        setCommentInput(e.target.value);
    };

    // const handlePostComment = async (event) => {
    //     event.preventDefault();
    //     const addComment = API_BASE_URL + '/storePostComment/' + id
    //     if (commentInput.trim()) {
    //         try {
    //             await axios.post(addComment, {
    //                 body: commentInput,
    //             });
    //             setCommentInput('');
    //             fetchFeeds(); // Refresh comments after posting
    //         } catch (error) {
    //             console.error('Error posting comment:', error);
    //         }
    //     }
    // };
    // const handleReplySubmit = async (e, commentId) => {
    //     e.preventDefault();
    //     const addReply = API_BASE_URL + '/storeReply/' + commentId
    //     try {
    //         await axios.post(addReply, {
    //             author,
    //             body: replyContent,
    //         });
    //         setReplyContent('');
    //         fetchFeeds(); // Refresh comments
    //     } catch (error) {
    //         console.error('Error posting reply:', error);
    //     }
    // };

    const handlePostComment = async (event) => {
        event.preventDefault();
        const addComment = API_BASE_URL + '/storePostComment/' + id;
        if (commentInput.trim()) {
            try {
                await axios.post(
                    addComment,
                    {
                        body: commentInput,
                    },
                    {
                        headers: {
                            "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace with your actual token
                            "Content-Type": "application/json",
                        },
                    }
                );
                setCommentInput('');
                fetchFeeds(); // Refresh comments after posting
            } catch (error) {
                console.error('Error posting comment:', error);
            }
        }
    };
    
    const handleReplySubmit = async (e, commentId) => {
        e.preventDefault();
        const addReply = API_BASE_URL + '/storeReply/' + commentId;
        try {
            await axios.post(
                addReply,
                {
                    author,
                    body: replyContent,
                },
                {
                    headers: {
                        "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace with your actual token
                        "Content-Type": "application/json",
                    },
                }
            );
            setReplyContent('');
            fetchFeeds(); // Refresh comments
        } catch (error) {
            console.error('Error posting reply:', error);
        }
    };
    
    return (
        <div>
            <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>

            {/* Mobile Menu */}
            <div className="mobile-menu d-lg-none d-block">
                <button type="button" className="close-button">
                    <i className="las la-times"></i>
                </button>
                <div className="mobile-menu__inner">
                    <div className="mobile-menu__menu">
                        <ul className="nav-menu flx-align nav-menu--mobile">
                            <li className="nav-menu__item activePage">
                                <a href="/" className="nav-menu__link">Home</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="course.html" className="nav-menu__link">Courses</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Feed</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Videos</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Teachers</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Institutes</a>
                            </li>
                            <li className="nav-menu__item has-submenu">
                                <a href="/" className="nav-menu__link">My Profile</a>
                                <ul className="nav-submenu">
                                    <li className="nav-submenu__item">
                                        <a href="/" className="nav-submenu__link"> Edit Profile</a>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <a href="/" className="nav-submenu__link"> Enrolled Courses</a>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <a href="/" className="nav-submenu__link"> Your Posts</a>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <a href="/" className="nav-submenu__link"> Certificates</a>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <a href="/" className="nav-submenu__link"> Chats</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Scan OR Code</a>
                            </li>
                        </ul>
                        <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
                            <a href="/" className="btn btn-main pill sign_btn px-sm-4">Signup/Login</a>
                        </div>
                    </div>
                </div>
            </div>

            <main className="change-gradient">
                {/* <header>
                    <div className="logo"></div>
                    
                    <div className="header-menu d-lg-block d-none ">
                        <ul className="nav-menu flx-align">
                            <li className="nav-menu__item">
                                <a href="" className="nav-menu__link">Home</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="course.html" className="nav-menu__link">Courses</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="" className="nav-menu__link">Feed</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="" className="nav-menu__link">Learn with us</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="" className="nav-menu__link">Teachers</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="" className="nav-menu__link">Institute</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="" className="nav-menu__link">About Us</a>
                            </li>
                        </ul>
                    </div>
                   
                    <div className="header-right flx-align pe-lg-5">
                        <div className="header-right__inner gap-3 flx-align d-lg-flex d-none">
                            <a href="" className="btn btn-main pill sign_btn px-sm-4">
                                Signup/Login
                            </a>
                            <a href="" className="btn cicrle_btn btn-main">
                                <img src={LogoImage} alt="" />
                            </a>
                        </div>
                        <button type="button" className="toggle-mobileMenu d-lg-none">
                            <i className="las la-bars"></i>
                        </button>
                    </div>
                 
                </header> */}

                <Navbar/>

                <section className="hero section-bg z-index-1">
                    <div className="container py-5 container-two">
                        <div className="row align-items-center gy-sm-5 gy-4">
                            <div className="col-lg-12 order-1 order-lg-0" style={{ marginTop: '110px' }}>
                                <div className="position-relative">
                                    <div className="search-box">
                                        <input
                                            type="text"
                                            className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                                            placeholder="Search"
                                        />
                                        <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                                            <img src={SearchIcon} alt="Search" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-5">
                    <div className="container">
                        <div className="row gy-3 justify-content-center">
                            {/* {[...Array(2)].map((_, index) => ( */}
                            <div className="col-lg-10 col-sm-10 col-md-12">
                                <div className="feed_card card mb-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="d-flex align-items-center">
                                                <div className="feed_img me-3">
                                                    <img src={ProfileImage} alt="Profile" />
                                                </div>
                                                @prakharmishra
                                            </span>
                                        </div>
                                        <div className="col-12">
                                            <div className="feed_details_img">
                                                <img src={`${Image_Base_Url}${feeds.post_image_path}`} alt="Feed" />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-10">
                                                    <img src={HeartIcon} alt="Heart" />
                                                    <img src={CommentIcon} alt="Comment" />
                                                    <img src={SendIcon} alt="Send" />
                                                </div>
                                                <div className="col-2 text-end">
                                                    <img src={MoreIcon} alt="More" />
                                                </div>
                                            </div>
                                        </div>
                                        <p className="my-4">
                                            {feeds.post_description}
                                        </p>
                                        <p>Add a comment...</p>
                                        <div className="comment-section">
                                            <h2>Comments</h2>
                                            {/* <div className="comments-list">
                                                {comments.map((comment) => (
                                                    <div key={comment.id} className="comment">
                                                        {comment.body}
                                                    </div>
                                                ))}
                                            </div> */}
                                            <input
                                                type="text"
                                                value={commentInput}
                                                onChange={handleInputChange}
                                                placeholder="Write a comment..."
                                            />
                                            <button onClick={handlePostComment}>Post Comment</button>


                                            <ul>

                                                {comments.map((comment) => (
                                                    <li key={comment.id}>
                                                        <hr style={{ border: '1px solid #ccc', margin: '20px 0' }} />
                                                        Comments:<strong>{comment.body}</strong>: {comment.body}
                                                        <div>
                                                            <h4>Replies</h4>
                                                            <ul>
                                                                {comment.replies.map((reply) => (
                                                                    <li key={reply.id}>
                                                                        Reply:<strong>{reply.author}</strong>: {reply.body}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            <form onSubmit={(e) => handleReplySubmit(e, comment.id)}>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Your name"
                                                                    value={author}
                                                                    onChange={(e) => setAuthor(e.target.value)}
                                                                    required
                                                                />
                                                                <textarea
                                                                    placeholder="Your reply"
                                                                    value={replyContent}
                                                                    onChange={(e) => setReplyContent(e.target.value)}
                                                                    required
                                                                />
                                                                <button type="submit">Post Reply</button>
                                                            </form>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                {/* Footer */}

                <Footer />

                {/* <footer className="footer-section">
            <img className="shape_top_10 d-lg-block d-none" src={unionImage} alt="Decoration Shape" />
            <div className="container container-two">
                <div className="row gy-sm-5">
                    <div className="col-xl-6 col-sm-6">
                        <div className="position-relative mb-5">
                            <div className="search-box">
                                <input 
                                    type="text" 
                                    className="common-input common-input--lg pill shadow-sm auto-suggestion-input" 
                                    placeholder="Search" 
                                />
                                <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                                    <img src={SearchIcon} alt="Search Icon" />
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-5 col-xs-6">
                                <div className="footer-widget">
                                    <ul className="footer-lists">
                                        <li className="footer-lists__item">
                                            <a href="/" className="footer-lists__link">Home</a>
                                        </li>
                                        <li className="footer-lists__item">
                                            <a href="/courses" className="footer-lists__link">Courses</a>
                                        </li>
                                        <li className="footer-lists__item">
                                            <a href="/feed" className="footer-lists__link">Feed</a>
                                        </li>
                                        <li className="footer-lists__item">
                                            <a href="/teachers" className="footer-lists__link">Teachers</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-6 col-xs-6 ps-xl-5">
                                <div className="footer-widget">
                                    <ul className="footer-lists">
                                        <li className="footer-lists__item">
                                            <a href="/about-us" className="footer-lists__link">About Us</a>
                                        </li>
                                        <li className="footer-lists__item">
                                            <a href="/payment" className="footer-lists__link">Payment</a>
                                        </li>
                                        <li className="footer-lists__item">
                                            <a href="/profile" className="footer-lists__link">My Profile</a>
                                        </li>
                                        <li className="footer-lists__item">
                                            <a href="/institutes" className="footer-lists__link">Institutes</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="footer-widget mt-4">
                                <h3>Follow us on</h3>
                                <div className="footer-widget__social">
                                    <ul className="social-icon-list">
                                        <li className="social-icon-list__item">
                                            <a href="https://www.facebook.com" className="social-icon-list__link flx-center">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li className="social-icon-list__item">
                                            <a href="https://www.youtube.com" className="social-icon-list__link flx-center">
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                        <li className="social-icon-list__item">
                                            <a href="https://www.linkedin.com" className="social-icon-list__link flx-center">
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 offset-xl-1 col-sm-6">
                        <div className="footer-widget">
                            <div className="map-box mb-30 mt-5">
                                <li className="footer-lists__item">
                                    <a href="/" className="footer-lists__link">Location</a>
                                </li>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.330999415546!2d78.44399507462819!3d17.44386460117675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917f6ecb6987%3A0x7d6d85b768a4c33b!2sFg%20Designs!5e0!3m2!1sen!2sin!4v1707451998655!5m2!1sen!2sin"
                                    width="100%"
                                    height="300"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer> */}
            </main>
        </div>
    );
}

export default FeedDetail;
