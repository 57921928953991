import React, { useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../src/assets/css/bootstrap.min.css";
import "../../src/assets/css/fontawesome-all.min.css";
import "../../src/assets/css/slick.css";
import "../../src/assets/css/line-awesome.min.css";
import "../../src/assets/css/main.css";
import LogoImage from "../../src/assets/images/all/scan-svgrepo-com 1.svg";



const isActive = (path) => location.pathname === path;

const isHomePage = location.pathname === "/";

const CommanStudentTeacherHeader = () => {
    const state = useSelector((state) => state.handleCart);
  const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
      console.log("Header refreshed on route change:", location.pathname);
      // Add logic here if needed, such as fetching data or resetting state
    }, [location]);
  

  // Navigate to login
  const handleLoginNavigate = () => {
    navigate("/login"); // Navigate to login screen
  };

  // Log out and refresh page
  const handleLogOutNavigate = () => {
    localStorage.removeItem("ACCESS_TOKEN_NAME"); // Remove token
    navigate("/logOut"); // Navigate to logout route
    window.location.reload(); // Refresh the page
  };

  // Navigate to QR Reader
  const handleNavigateToQRReader = () => {
    navigate("/qrReader"); // Navigate to QR Reader screen
  };
  return (
    <>
      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">
                  Home
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="/course" className="nav-menu__link">
                  Courses
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="/Feed" className="nav-menu__link">
                  Feed
                </a>
              </li>
             
              <li className="nav-menu__item">
                <a href="/teacher" className="nav-menu__link">
                  Teachers
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="/institute" className="nav-menu__link">
                  Institutes
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="/collage" className="nav-menu__link">
                College
                </a>
              </li>
             
              <li className="nav-menu__item">
                <a href="/qrReader" className="nav-menu__link">
                  Scan QR Code
                </a>
              </li>
            </ul>
            <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
             
                {localStorage.getItem("ACCESS_TOKEN_NAME") ? (
                  <button
                    onClick={handleLogOutNavigate}
                    className="btn btn-main pill sign_btn px-sm-4"
                  >
                    Logout
                  </button>
                ) : (
                  <button
                    onClick={handleLoginNavigate}
                    className="btn btn-main pill sign_btn px-sm-4"
                  >
                    Signup/Login
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>

      {/* Header */}
      <header className="header">
                <div className="container container-full">
                    <nav className="header-inner flx-between">
                        <div className="logo"></div>
                        <div className="header-right flx-align pe-lg-5">
                            <button type="button" className="toggle-mobileMenu d-lg-none">
                                <i className="las la-bars"></i>
                            </button>
                        </div>
                    </nav>
                </div>
            </header>
    </>
  );
};

export default CommanStudentTeacherHeader;



