import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Importing assets
import ProfileImage from "../../assets/images/all/profiel.png";
import NotificationIcon from "../../assets/images/icons/notification-bing-svgrepo-com 1.svg";
import EditIcon from "../../assets/images/icons/edit-1-svgrepo-com.svg";
import LocationIcon from "../../assets/images/icons/location-pin-alt-1-svgrepo-com.svg";
import SearchIcon from "../../assets/images/all/search.svg";

import Footer from "../../components/Footer";
import StudentSidebar from "../../components/StudentSidebar";
import { Navbar } from "../../components";

const StudentProfile = () => {
     const [isEditable, setIsEditable] = useState(false);
     const [isEditable1, setIsEditable1] = useState(false);
     const [profileImage, setProfileImage] = useState(ProfileImage);
     const navigate = useNavigate();

  const [profileData, setProfileData] = useState({
    fullName: "aarizmahood56",
    email: "aarizmahood56@gmail.com",
    username: "aarizmahood",
    location: "Hyderabad, India",
    bio: "Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  });
  const [profileData1, setProfileData1] = useState({
    bio: "Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  });

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const toggleEdit1= () => {
    setIsEditable1(!isEditable1);
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setProfileData1((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log("ddndmndmndmnd",e)
        setProfileImage(e.target.result); // Update the profile image with the uploaded file
      };
      reader.readAsDataURL(file);
    }
  };
  

  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
      {/* <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">
                  Home
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="/course" className="nav-menu__link">
                  Courses
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="/feed" className="nav-menu__link">
                  Feed
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="/videos" className="nav-menu__link">
                  Videos
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="/teachers" className="nav-menu__link">
                  Teachers
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="/institutes" className="nav-menu__link">
                  Institutes
                </a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="/profile" className="nav-menu__link">
                  My Profile
                </a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item">
                    <a href="/edit-profile" className="nav-submenu__link">
                      Edit Profile
                    </a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/enrolled-courses" className="nav-submenu__link">
                      Enrolled Courses
                    </a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/posts" className="nav-submenu__link">
                      Your Posts
                    </a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/certificates" className="nav-submenu__link">
                      Certificates
                    </a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/chats" className="nav-submenu__link">
                      Chats
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <Navbar/>

      {/* Main Container */}
      <div className="container">
        <div className="row">
          {/* Sidebar */}
          <StudentSidebar />

          {/* Main Content */}
          <div className="col-sm-9 col-md-12 col-lg-8 col-xl-9 px-md-5 ps-xl-5">
            {/* Search and Profile Section */}
            <div className="row mt-5">
              <div className="col-md-9 ps-0">
                <div className="search-box dashboard_search">
                  <input
                    type="text"
                    className="common-input common-input--lg dashboard_search_input pill shadow-sm auto-suggestion-input"
                    placeholder="Search"
                  />
                  <button type="submit" className="btn btn-main spl_burron border-0">
                    <img src={SearchIcon} alt="Search" />
                  </button>
                </div>
              </div>
              <div className="col-md-2 text-xl-center d-none d-md-block mt-3">
                <img src={NotificationIcon} alt="Notifications" />
              </div>
              <div className="col-md-1 d-none d-md-block">
                <div className="dashboard_profile ms-auto">
                  <img src={ProfileImage} alt="Profile" />
                </div>
              </div>
            </div>

            {/* Edit Profile Section */}
            <p className="learm-tilte my-3">Edit Profile</p>
            <div className="row p-0 mb-5">
              <div className="dashboard_card p-0">
                <div className="row align-items-center p-4">
                  <div className="col-sm-4 col-lg-4 col-xl-2">
                    <div className="dasboard_profile mx-auto">
                      <img src={profileImage} alt="Profile" className="rounded-circle" />
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-8 col-xl-10 text-center text-lg-start">
                    <button className="btn btn-outline-light my-2 my-lg-0"  onClick={() => document.getElementById("fileInput").click()}>Upload new photo</button>

                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                    <p className="mt-2 profiel_text text-start">
                      At least 800x800 px recommended. JPG or PNG is allowed.
                    </p>
                  </div>
                </div>
                <hr className="dashboard_line" />

                {/* Personal Info Section */}
                <div className="row py-4 px-5">
                  <div className="dashboard_sub_card">
                    <div className="row px-0 px-sm-3">
                      <div className="col-7 col-sm-8">
                        <p className="profile_text_2">Personal Info</p>
                      </div>
                      <div className="col-sm-4 col-5 text-end">
                        <button onClick={toggleEdit}>
                          <img src={EditIcon} alt="Edit" />
                          {isEditable ? "Save" : "Edit"}
                        </button>
                      </div>
                      <div className="col-sm-12 d-flex justify-content-between flex-column flex-lg-row mt-4">
                        <div>
                          {/* <p>Full Name</p>
                          <p className="profile_text_1 mt-2">aarizmahood56</p> */}
                           <p>Full Name</p>
                          {isEditable ? (
                            <input
                              type="text"
                              name="fullName"
                              value={profileData.fullName}
                              onChange={handleChange}
                              
                              className="common-input dashboard_editable_input"
                            />
                          ) : (
                            <p className="profile_text_1 mt-2">{profileData.fullName}</p>
                          )}
                        </div>
                        <div>
                        <p>Email</p>
                          {isEditable ? (
                            <input
                              type="email"
                              name="email"
                              value={profileData.email}
                              onChange={handleChange}
                              className="common-input dashboard_editable_input"
                            />
                          ) : (
                            <p className="profile_text_1 mt-2">{profileData.email}</p>
                          )}
                        </div>
                        <div>
                        <p>Username</p>
                          {isEditable ? (
                            <input
                              type="text"
                              name="username"
                              value={profileData.username}
                              onChange={handleChange}
                              className="common-input dashboard_editable_input"
                            />
                          ) : (
                            <p className="profile_text_1 mt-2">{profileData.username}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 mt-4">
                        <p className="profile_text_2 mb-3">Location</p>
                        <div className="position-relative">
                          <input
                            type="url"
                            className="common-input common-input--md dash_borad_input common-input--withLeftIcon"
                            id="facebookUrl"
                            placeholder="Facebook Profile Url"
                          />
                          <span className="input-icon input-icon--left text-main">
                            <img src={LocationIcon} alt="Location" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Bio Section */}
                <div className="row px-5 pb-4">
                  <div className="dashboard_sub_card">
                    <div className="row px-0 px-sm-3">
                      <div className="col-7 col-sm-8">
                        <p className="profile_text_2">Bio</p>
                      </div>
                      <div className="col-sm-4 col-5 text-end">
                        <button onClick={toggleEdit1}>
                          <img src={EditIcon} alt="Edit" />
                          {isEditable1 ? "Save" : "Edit"}
                        </button>
                      </div>
                      <div className="col-sm-12 mt-4">
                      {isEditable1 ? (
                          <textarea
                            name="bio"
                            value={profileData1.bio}
                            onChange={handleChange1}
                            className="common-input dashboard_editable_input"
                            rows="4"
                          ></textarea>
                        ) : (
                          <p>{profileData1.bio}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default StudentProfile;
