(function ($) {
  "use strict";
  
  // ==========================================
  //      Start Document Ready function
  // ==========================================
  $(document).ready(function () {



  // ============================== Auto Suggestion Js Start ============================== 
  $('.auto-suggestion-input').on('input', function (event) {
    event.stopPropagation(); 
    $(this).addClass('active'); 
    $('.auto-suggestion-list').addClass('active'); 
  });

  $('body').on('click', function () {
    $('.auto-suggestion-input').removeClass('active'); 
    $('.auto-suggestion-list').removeClass('active'); 
  }); 
  // ============================== Auto Suggestion Js End ============================== 


  // ============================== Auto Suggestion Text value place to the input field Js End ============================== 
  $('.auto-suggestion-list__item').on('click', function (event) {
    event.preventDefault();
    const textValue = $(this).text().trim();
    $('.auto-suggestion-input').val(textValue);
  }); 
  // ============================== Auto Suggestion Text value place to the input field Js End ============================== 
    

  // ============== Mobile Menu Sidebar Js Start ========
  $('.toggle-mobileMenu').on('click', function () {
    $('.mobile-menu').addClass('active');
    $('.side-overlay').addClass('show');
    $('body').addClass('scroll-hide-sm');
  }); 

  $('.close-button, .side-overlay').on('click', function () {
    $('.mobile-menu').removeClass('active');
    $('.side-overlay').removeClass('show');
    $('body').removeClass('scroll-hide-sm');
  }); 
  // ============== Mobile Menu Sidebar Js End ========
  
  // ============== Mobile Nav Menu Dropdown Js Start =======================
  var windowWidth = $(window).width(); 
  
  $('.has-submenu').on('click', function () {
    var thisItem = $(this); 
    
    if(windowWidth < 992) {
      if(thisItem.hasClass('active')) {
        thisItem.removeClass('active')
      } else {
        $('.has-submenu').removeClass('active')
        $(thisItem).addClass('active')
      }
      
      var submenu = thisItem.find('.nav-submenu');
      
      $('.nav-submenu').not(submenu).slideUp(300);
      submenu.slideToggle(300);
    }
    
  });
  // ============== Mobile Nav Menu Dropdown Js End =======================
  
  // ======================== Tooltip Js Start ====================
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
  // ======================== Tooltip Js End ====================
    
  // ===================== Scroll Back to Top Js Start ======================
  // var progressPath = document.querySelector('.progress-wrap path');
  // var pathLength = progressPath.getTotalLength();
  // progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
  // progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
  // progressPath.style.strokeDashoffset = pathLength;
  // progressPath.getBoundingClientRect();
  // progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';
  // var updateProgress = function () {
  //   var scroll = $(window).scrollTop();
  //   var height = $(document).height() - $(window).height();
  //   var progress = pathLength - (scroll * pathLength / height);
  //   progressPath.style.strokeDashoffset = progress;
  // }
  // updateProgress();
  // $(window).scroll(updateProgress);
  // var offset = 50;
  // var duration = 550;
  // jQuery(window).on('scroll', function() {
  //   if (jQuery(this).scrollTop() > offset) {
  //     jQuery('.progress-wrap').addClass('active-progress');
  //   } else {
  //     jQuery('.progress-wrap').removeClass('active-progress');
  //   }
  // });
  // jQuery('.progress-wrap').on('click', function(event) {
  //   event.preventDefault();
  //   jQuery('html, body').animate({scrollTop: 0}, duration);
  //   return false;
  // })
  // ===================== Scroll Back to Top Js End ======================

  // ========================== add active class to ul>li top Active current page Js Start =====================
  function dynamicActiveMenuClass(selector) {
    let FileName = window.location.pathname.split("/").reverse()[0];

    selector.find("li").each(function () {
      let anchor = $(this).find("a");
      if ($(anchor).attr("href") == FileName) {
        $(this).addClass("activePage");
      }
    });
    // if any li has activePage element add class
    selector.children("li").each(function () {
      if ($(this).find(".activePage").length) {
        $(this).addClass("activePage");
      }
    });
    // if no file name return
    if ("" == FileName) {
      selector.find("li").eq(0).addClass("activePage");
    }
  }
  if ($('ul').length) {
    dynamicActiveMenuClass($('ul'));
  }
  // ========================== add active class to ul>li top Active current page Js End =====================

  // ================================ Remove Sale Offer Js Start =============================
  $('.sale-offer__close').on('click', function () {
    $(this).closest('.sale-offer').addClass('d-none')
  }); 
  // ================================ Remove Sale Offer Js End =============================
  
  // ================================ CountDown Js Start =============================
  if (document.querySelector('.countdown')) {
    const myCountdown = new countdown({
      target: '.countdown',
      dayWord: ' Days:',
      hourWord: ' Hour: ',
      minWord: ' Min:',
      secWord: ' Sec:'
    });
  }
  // ================================ CountDown Js End =============================
  
  // ========================= popular Category Js Start ==============
  // $('.popular-slider').slick({
  //   slidesToShow: 6,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   speed: 1500,
  //   dots: false,
  //   pauseOnHover: true,
  //   arrows: true,
  //   draggable: true,
  //   speed: 900,
  //   infinite: true,
  //   prevArrow: '<button type="button" class="slick-prev"><i class="las la-arrow-left"></i></button>',
  //   nextArrow: '<button type="button" class="slick-next"><i class="las la-arrow-right"></i></button>',
  //   responsive: [
  //     {
  //       breakpoint: 1199,
  //       settings: {
  //         slidesToShow: 5,
  //       }
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 4,
  //       }
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 3,
  //       }
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 2,
  //       }
  //     },
  //   ]
  // });
  // // ========================= popular Category Js End ===================
  
  // ========================= Wishlist Js Start ===================
  $('.product-item__wishlist').on('click', function() {
    $(this).toggleClass('active')
  }); 
  // ========================= Wishlist Js End ===================
  
  // ========================= Selling Product Js Start ==============
  // $('.selling-product-slider').slick({
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   speed: 1500,
  //   dots: true,
  //   pauseOnHover: true,
  //   arrows: true,
  //   draggable: true,
  //   speed: 900,
  //   infinite: true,
  //   prevArrow: '<button type="button" class="slick-prev left_1"><i class="fas fa-angle-left"></i></button>',
  //   nextArrow: '<button type="button" class="slick-next right_1"><i class="fas fa-angle-right"></i></button>',
  //   responsive: [
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 2,
  //       }
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 2,
  //       }
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 1,
  //       }
  //     },
  //   ]
  // });
  // ========================= Selling Product Js End ===================

  // ========================= Testimonial Slider Js Start ==============
  // $('.testimonial-slider').slick({
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   speed: 1500,
  //   dots: true,
  //   pauseOnHover: true,
  //   arrows: true,
  //   draggable: true,
  //   speed: 900,
  //   infinite: true,
  //   prevArrow: '<button type="button" class="slick-prev"><i class="las la-arrow-left"></i></button>',
  //   nextArrow: '<button type="button" class="slick-next"><i class="las la-arrow-right"></i></button>',
  //   responsive: [
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 1,
  //       }
  //     },
  //   ]
  // });
  // ========================= Testimonial Slider Js End ===================
  
  // ========================= Selling Product Js Start ==============
  // $('.resource-slider').slick({
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   speed: 1500,
  //   dots: true,
  //   pauseOnHover: true,
  //   arrows: true,
  //   draggable: true,
  //   speed: 900,
  //   infinite: true,
  //   prevArrow: '<button type="button" class="slick-prev"><i class="las la-arrow-left"></i></button>',
  //   nextArrow: '<button type="button" class="slick-next"><i class="las la-arrow-right"></i></button>',
  //   responsive: [
  //     {
  //       breakpoint: 1199,
  //       settings: {
  //         slidesToShow: 3,
  //       }
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 2,
  //       }
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 1,
  //       }
  //     },
  //   ]
  // });
  // ========================= Selling Product Js End ===================

  // ========================= Brand Slider Js Start ==============
  // $('.brand-slider').slick({
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   speed: 1500,
  //   dots: false,
  //   pauseOnHover: true,
  //   arrows: false,
  //   draggable: true,
  //   speed: 900,
  //   infinite: true,
  //   prevArrow: '<button type="button" class="slick-prev"><i class="las la-arrow-left"></i></button>',
  //   nextArrow: '<button type="button" class="slick-next"><i class="las la-arrow-right"></i></button>',
  //   responsive: [
  //     {
  //       breakpoint: 1199,
  //       settings: {
  //         slidesToShow: 4,
  //       }
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 4,
  //       }
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 3,
  //       }
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 2,
  //       }
  //     },
  //   ]
  // });
  // ========================= Brand Slider Js End ===================


  // ========================= Brand Three Slider Js Start ==============
  // $('.brand-three-slider').slick({
  //   slidesToShow: 7,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   speed: 1500,
  //   dots: false,
  //   pauseOnHover: true,
  //   arrows: false,
  //   draggable: true,
  //   speed: 900,
  //   infinite: true,
  //   prevArrow: '<button type="button" class="slick-prev"><i class="las la-arrow-left"></i></button>',
  //   nextArrow: '<button type="button" class="slick-next"><i class="las la-arrow-right"></i></button>',
  //   responsive: [
  //     {
  //       breakpoint: 1199,
  //       settings: {
  //         slidesToShow: 6,
  //       }
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 5,
  //       }
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 4,
  //       }
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 3,
  //       }
  //     },
  //     {
  //       breakpoint: 425,
  //       settings: {
  //         slidesToShow: 2,
  //       }
  //     },
  //   ]
  // });
  // ========================= Brand Three Slider Js End ===================

  // ========================= Service Item Js Start ===================
  $('.service-three-item__button').on('click', function () {
    var $serviceItem = $(this).closest('.service-three-item');
    
    if ($serviceItem.hasClass('active')) {
      $serviceItem.removeClass('active');
    } else {
      $('.service-three-item').removeClass('active');
      $serviceItem.addClass('active');
    }
  });
  // ========================= Service Item Js End ===================
  
  // ========================= Latest Project Slider Into Tab Js Start ==============
  // $('.latest-project-slider').slick({
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   speed: 1500,
  //   dots: false,
  //   pauseOnHover: true,
  //   arrows: true,
  //   draggable: true,
  //   speed: 900,
  //   infinite: true,
  //   prevArrow: '<button type="button" class="slick-prev"><i class="las la-arrow-left"></i></button>',
  //   nextArrow: '<button type="button" class="slick-next"><i class="las la-arrow-right"></i></button>',
  //   responsive: [
  //     {
  //       breakpoint: 1199,
  //       settings: {
  //         slidesToShow: 2,
  //       }
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 1,
  //       }
  //     },
  //   ]
  // });
  // ========================= Latest Project Slider Into Tab Js End ===================

  // ========================= Selling Product Js Start ==============
  // $('.team-item-slider').slick({
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   speed: 1500,
  //   dots: true,
  //   pauseOnHover: true,
  //   arrows: true,
  //   draggable: true,
  //   speed: 900,
  //   infinite: true,
  //   prevArrow: '<button type="button" class="slick-prev"><i class="las la-arrow-left"></i></button>',
  //   nextArrow: '<button type="button" class="slick-next"><i class="las la-arrow-right"></i></button>',
  //   responsive: [
  //     {
  //       breakpoint: 1199,
  //       settings: {
  //         slidesToShow: 3,
  //       }
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 2,
  //       }
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 1,
  //       }
  //     },
  //   ]
  // });
  // ========================= Selling Product Js End ===================

  // ========================= Testimonial Slider Js Start ===================
  // $('.testimonial-three-thumb-slider').slick({
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   dots: true,
  //   fade: true,
  //   asNavFor: '.testimonial-three-item-slider',
  //   prevArrow: '<button type="button" class="slick-prev"><i class="las la-arrow-left"></i></button>',
  //   nextArrow: '<button type="button" class="slick-next"><i class="las la-arrow-right"></i></button>',
  //   responsive: [
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         dots: false,
  //       }
  //     },
  //   ]
  // });
  // $('.testimonial-three-item-slider').slick({
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   asNavFor: '.testimonial-three-thumb-slider',
  //   dots: false,
  //   arrows: false,
  //   centerMode: true,
  //   focusOnSelect: true
  // });
  // ========================= Testimonial Slider Js End ===================

  // ========================== Text Slide Js Start =====================
  // $('.text-slider').marquee({
  //   pauseOnHover: true,
    
  //   allowCss3Support: true,
  //   css3easing: 'linear',
  //   easing: 'linear',
  //   delayBeforeStart: 1000,
  //   duration: 7000,
  //   gap: 20,
  //   pauseOnCycle: false,
  //   startVisible: false
  // });
  // ========================== Text Slide Js End =====================
  
  
  // ========================= Payment Method Slider Js Start ==============
  // $('.payment-method__slider').slick({
  //   slidesToShow: 10,
  //   slidesToScroll: 1,
  //   autoplay: false,
  //   autoplaySpeed: 2000,
  //   speed: 1500,
  //   dots: false,
  //   pauseOnHover: true,
  //   arrows: true,
  //   draggable: true,
  //   speed: 900,
  //   infinite: true,
  //   prevArrow: '<button type="button" class="slick-prev"><i class="las la-arrow-left"></i></button>',
  //   nextArrow: '<button type="button" class="slick-next"><i class="las la-arrow-right"></i></button>',
  //   responsive: [
  //     {
  //       breakpoint: 1299,
  //       settings: {
  //         slidesToShow: 8,
  //       }
  //     },
  //     {
  //       breakpoint: 1199,
  //       settings: {
  //         slidesToShow: 6,
  //       }
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 4,
  //       }
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 4,
  //       }
  //     },
  //     {
  //       breakpoint: 425,
  //       settings: {
  //         slidesToShow: 3,
  //       }
  //     },
  //   ]
  // });
  // ========================= Payment Method Slider Js End ===================

  // ========================= Follow Button Js Start ==========================
  $('.follow-btn').on('click', function () {
    var buttonText = $(this).text();
    $(this).text(buttonText === 'Follow' ? 'Following' : 'Follow');
    $(this).toggleClass('active')
  });
  // ========================= Follow Button Js End ==========================

  // ========================= Text Rotation Js Start ==========================
    const text = document.querySelector(".circle__text");

    if(text) {
      text.innerHTML = text.innerText
      .split("")
      .map(
        (char, i) => `<span style="transform:rotate(${i * 11.5}deg)">${char}</span>`
        )
      .join("");
    }

    // Text Two
    const textTwo = document.querySelector(".circle__desc");

    if(textTwo) {
      textTwo.innerHTML = textTwo.innerText
      .split("")
      .map(
        (char, i) => `<span style="transform:rotate(${i * 11.5}deg)">${char}</span>`
        )
      .join("");
    }
  // ========================= Text Rotation Js End ==========================


  // ========================= Filter Sidebar Js Start ===================
  $('.filter-sidebar__button').on('click', function () {
    $(this).toggleClass('active')
    $(this).siblings('.filter-sidebar__content').slideToggle(); 
  }); 
  // ========================= Filter Sidebar Js End ===================

  // ========================== Grid & List View Js Start =====================
  $('.list-button').on('click', function () {
    $('body').addClass('list-view'); 
    $(this).addClass('active'); 
    $('.grid-button').removeClass('active'); 
  }); 
  $('.grid-button').on('click', function () {
    $('body').removeClass('list-view'); 
    $('.list-button').removeClass('active'); 
    $(this).addClass('active'); 
  }); 
  // ========================== Grid & List View Js End =====================

  // ========================== Filter Form Show hide Js Start =====================
  $('.filter-tab__button').on('click', function () {
    $('.filter-form').slideToggle(); 
    $(this).toggleClass('active'); 
  }); 
  // ========================== Filter Form Show hide Js End =====================

  // ========================== Filter Sidebar Show hide Js Start =====================
  $('.sidebar-btn').on('click', function () {
    $('.filter-sidebar').addClass('show'); 
    $('.side-overlay').addClass('show'); 
    $('body').addClass('scroll-hide-sm'); 
  }); 
  $('.filter-sidebar__close, .side-overlay').on('click', function () {
    $('.filter-sidebar').removeClass('show'); 
    $('.side-overlay').removeClass('show'); 
    $('body').removeClass('scroll-hide-sm'); 
  }); 
  // ========================== Filter Sidebar Show hide Js End =====================

  // ========================= Social Share Js Start ===========================
  $('.social-share__button').on('click', function(event) {
    event.stopPropagation(); 
    $('.social-share__icons').toggleClass('show')
  }); 

  $('body').on('click', function(event) {
    $('.social-share__icons').removeClass('show')
  }); 

  // For device width size js start
  // let screenSize = screen.width
  // alert(' Your Screen Size is: ' + screenSize + 'pixel'); 
  // For device width size js start

  let socialShareBtn = $('.social-share');
  // Check if the element exists
  if (socialShareBtn.length > 0) {
    let leftDistance = socialShareBtn.offset().left;
    let rightDistance = $(window).width() - (leftDistance + socialShareBtn.outerWidth());

    if (leftDistance < rightDistance) {
      $('.social-share__icons').addClass('left');
    }
  }
  // ========================= Social Share Js End ===========================

  // ========================= License Dropdown Js Start ===========================
  $('.btn-has-dropdown').on('click', function (event) {
    event.stopPropagation(); 
    $('.license-dropdown').toggleClass('active'); 
  }); 

  $('.license-dropdown').on('click', function(event) {
    event.stopPropagation(); 
  }); 

  $('body').on('click', function () {
    $('.license-dropdown').removeClass('active'); 
  }); 
  // ========================= License Dropdown Js End ===========================

  // ========================= Select License Option Js Start ===========================
  $('.license-dropdown__item').on('click', function() {
    $('.license-dropdown__item').removeClass('activeSelectItem');
    $(this).addClass('activeSelectItem');
    let titleText = $(this).find('.license-dropdown__title').text(); 
    $('.btn-has-dropdown').text(""); 
    $('.btn-has-dropdown').text(titleText); 
    $('.license-dropdown').removeClass('active'); 
  }); 
  // ========================= Select License Option Js End ===========================

  
  // ========================== Increment & Decrement Js Start =====================
  $(function() {
    $('[data-decrease]').click(decrease);
    $('[data-increase]').click(increase);
    $('[data-value]').on('change input', valueChange);
  });
  
  function decrease() {
    var value = $(this).parent().find('[data-value]').val();
    if(value > 1) {
      value--;
      $(this).parent().find('[data-value]').val(value);
    }
  }
  
  function increase() {
    var value = $(this).parent().find('[data-value]').val();
    if(value < 100) {
      value++;
      $(this).parent().find('[data-value]').val(value);
    }
  }
  
  function valueChange() {
    var value = $(this).val();
    if(value == undefined || isNaN(value) == true || value <= 0) {
      $(this).val(1);
    } else if(value >= 101) {
      $(this).val(100);
    }
  }
  // ========================== Increment & Decrement Js End =====================

  // ========================== Cart Item Delete Js Start =====================
  $('.delete-btn').on('click', function() {
    $(this).closest('tr').addClass('d-none')
  }); 
  // ========================== Cart Item Delete Js End =====================

  // ========================== Password Show Hide Js Start =====================
  $(".toggle-password").on('click', function() {
    var input = $($(this).attr("id"));

    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });
  
  $(".toggle-password-two").on('click', function() {
    $(this).toggleClass(" la-eye-slash");
    var input = $($(this).attr("id"));

    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });
  // ========================== Password Show Hide Js End =====================



  // ==================== Dashboard User Profile Dropdown Start ==================
  $('.user-profile__button').on('click', function(event) {
    event.stopPropagation();
    $('.user-profile-dropdown').toggleClass('show'); 
  }); 

  $('.user-profile-dropdown').on('click', function (event) {
    event.stopPropagation();
    $('.user-profile-dropdown').addClass('show')
  }); 

  $('body').on('click', function() {
    $('.user-profile-dropdown').removeClass('show'); 
  })
// ==================== Dashboard User Profile Dropdown End ==================

  // ========================== Image Upload Js Start =====================
  function readURL(input, previewId) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
          $(previewId).css('background-image', 'url(' + e.target.result + ')');
          $(previewId).hide();
          $(previewId).fadeIn(650);
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  $("#imageUpload").on('change', function () {
    readURL(this, '#imagePreview');
  });

  $("#imageUploadTwo").on('change', function () {
    readURL(this, '#imagePreviewTwo');
  });
  // ========================== Image Upload Js End =====================

  // ========================== Magnific Popup Js Start =====================
  $('.screenshot-btn').on('click', function() {
    var images = JSON.parse($(this).attr('data-images'));
    var items = [];
    
    for (var i = 0; i < images.length; i++) {
        items.push({
            src: images[i],
            type: 'image'
        });
    }
    
    $.magnificPopup.open({
        items: items,
        gallery: {
            enabled: true
        },
        type: 'image'
    });
  });
  // ========================== Magnific Popup Js End =====================

  // ========================== Footer Has Class section bg Js Start =====================
  if($('.footer').hasClass('section-bg')) {
    $('.brand').addClass('active'); 
    $('.footer.section-bg').addClass('active'); 
  }
  // ========================== Footer Has Class section bg Js End =====================
  
  // ========================= Scroll Spy Js Start ===========================
  // const scrollSpy = new bootstrap.ScrollSpy(document.body, {
  //   target: '#sidebar-scroll-spy'
  // })
  // ========================= Scroll Spy Js End ===========================

  // ========================== Apex Chart Js Start =====================


  });
  // ==========================================
  //      End Document Ready function
  // ==========================================

  // ========================= Preloader Js Start =====================
    $(window).on("load", function(){
      $('.loader-mask').fadeOut(); 
    })
    // ========================= Preloader Js End=====================

    // ========================= Header Sticky Js Start ==============
    $(window).on('scroll', function() {
      if ($(window).scrollTop() >= 260) {
        $('.header').addClass('fixed-header');
      }
      else {
          $('.header').removeClass('fixed-header');
      }
    }); 
    // ========================= Header Sticky Js End===================

})(jQuery);
