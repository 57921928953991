import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Footer, Navbar } from "../../components";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME,Image_Base_Url,ACCESS_Role_ID,ACCESS_Log_In_User_Id } from '../../constants/apiConstants';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Importing assets

import ProfileImage from "../../assets/images/all/profiel.png";
import SearchIcon from "../../assets/images/all/search.svg";
import ManageEditIcon from "../../assets/images/icons/manage_edit.svg";
import HeartIcon from "../../assets/images/icons/iconoir_heart1.svg";
import CommentIcon from "../../assets/images/icons/majesticons_comment-line1.svg";
import SendIcon from "../../assets/images/icons/mingcute_send-line1.svg";
import BinIcon from "../../assets/images/icons/bin-svgrepo-com 11.svg";
import FeedDetailImage from "../../assets/images/all/feed-details.png";
import FeedImage from "../../assets/images/all/fedd.png";

import StudentSidebar from '../../components/StudentSidebar';
import GlobalSearch from '../../components/GlobalSearch';
import CommanStudentTeacherHeader from '../../components/CommanStudentTeacherHeader';

const DashboardPostDetail = (props) => {
  const { id } = useParams();
    console.log(id);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [feeds, setFeeds] = useState(""); // State to hold roles data
    const [comments, setComments] = useState([]);
    const [reply, setreply] = useState([]);
    const [commentInput, setCommentInput] = useState('');
    const [replyContent, setReplyContent] = useState('');
    const [author, setAuthor] = useState('');

    useEffect(() => {
      fetchFeeds(); // Call the fetch function
  }, []); // Empty dependency array to run only on mount

  const url = API_BASE_URL + '/getFeedsDetailsById/' + id
  const url2 = API_BASE_URL + '/getPostComments/' + id
  const fetchFeeds = async () => {
      try {
          const [response, response2] = await Promise.all([
              axios.get(url),
              axios.get(url2)
          ]);
          setFeeds(response.data[0]); // Set roles data to state
          console.log(response2.data);
          setComments(response2.data);
      } catch (err) {
          // setError(err.message); // Handle error

      }
  };

  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
    <CommanStudentTeacherHeader/>

      <main className="container">
        <div className="row">
          {/* Sidebar */}
        <StudentSidebar/>

          {/* Main Content Area */}
          <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5">
            <GlobalSearch/>

            <div className="row align-items-center my-3">
              <div className="col-11">
                <p className="learm-tilte my-3">Enrolled Courses</p>
              </div>
              <div className="col-1 text-end">
                <img src={ManageEditIcon} alt="Manage Edit" />
              </div>
            </div>

            {/* Feed Section */}
            <div className="row">
              <div className="col-sm-8">
                <div className="mb-3">
                  <img className="img-cover w-100 rounded-4" src={`${Image_Base_Url}${feeds.post_image_path}`} alt="Feed Details" />
                  <div className="col-sm-12 mt-3">
                    <div className="row">
                      <div className="col-6">
                        <img src={HeartIcon} alt="Like" />
                        <img src={CommentIcon} alt="Comment" />
                        <img src={SendIcon} alt="Share" />
                      </div>
                      <div className="col-6 text-end">
                        <span className="dashboard_subitel">Delete Post</span>
                        <img src={BinIcon} alt="Delete" />
                      </div>
                    </div>
                    <div className="col-sm-12">
  <p className="learm-tilte2 my-2">Caption</p>

  <div className="bg-white px-4 pb-3 accordiantion_radius pt-3 comment_box">
    <div className="row">
      <div className="d-flex align-items-start">
        <div className="feed_img me-3">
          <img
            src={ProfileImage}
            alt="Profile"
            className="rounded-circle"
          />
        </div>
        <div>
          <div className="d-flex justify-content-between">
            <p className="course_video_time">@Aariz Mahmood</p>
            <img
              src={BinIcon}
              alt="Delete"
            />
          </div>
          <p className="text-muted fw-500" style={{ width: "90%" }}>
            Best way to revise All Chapters in Physical Chemistry 1: Mind
          </p>
          <div className="d-flex justify-content-between mt-2">
            <p className="text-start font-14">Reply</p>
            <p className="text-start font-14">4 days ago</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="col-sm-12">
  <p className="learm-tilte2 my-2">Caption</p>

  <div className="bg-white px-4 pb-3 accordiantion_radius pt-3 comment_box">
    <div className="row">
      <div className="d-flex align-items-start">
        <div className="feed_img me-3">
          <img
            src={ProfileImage}
            alt="Profile"
            className="rounded-circle"
          />
        </div>
        <div>
          <div className="d-flex justify-content-between">
            <p className="course_video_time">@Aariz Mahmood</p>
            <img
              src={BinIcon}
              alt="Delete"
            />
          </div>
          <p className="text-muted fw-500" style={{ width: "90%" }}>
            Best way to revise All Chapters in Physical Chemistry 1: Mind
          </p>
          <div className="d-flex justify-content-between mt-2">
            <p className="text-start font-14">Reply</p>
            <p className="text-start font-14">4 days ago</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="col-sm-12">
  <p className="learm-tilte2 my-2">Caption</p>

  <div className="bg-white px-4 pb-3 accordiantion_radius pt-3 comment_box">
    <div className="row">
      <div className="d-flex align-items-start">
        <div className="feed_img me-3">
          <img
            src={ProfileImage}
            alt="Profile"
            className="rounded-circle"
          />
        </div>
        <div>
          <div className="d-flex justify-content-between">
            <p className="course_video_time">@Aariz Mahmood</p>
            <img
              src={BinIcon}
              alt="Delete"
            />
          </div>
          <p className="text-muted fw-500" style={{ width: "90%" }}>
            Best way to revise All Chapters in Physical Chemistry 1: Mind
          </p>
          <div className="d-flex justify-content-between mt-2">
            <p className="text-start font-14">Reply</p>
            <p className="text-start font-14">4 days ago</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                {[...Array(7)].map((_, index) => (
                  <div key={index} className="col-sm-12 mb-4 feed_imgs">
                    <img src={FeedImage} alt="Feed" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
     <Footer/>
    </div>
  );
};

export default DashboardPostDetail;
