import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Footer, Navbar } from "../../components";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url, ACCESS_Role_ID, ACCESS_Log_In_User_Id } from '../../constants/apiConstants';
import { formatDate, calculateDifference, dateMonthYears } from "./../Utils";

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Importing assets
import ProfileImage from "../../assets/images/all/Instituteimage.png";
import NotificationIcon from "../../assets/images/icons/notification-bing-svgrepo-com 1.svg";
import qrImage from "../../assets/images/all/qr_code.png";
import EditImage from "../../assets/images/icons/edit-1-svgrepo-com.svg"
import EditIcon from "../../assets/images/icons/edit-4-svgrepo-com 1.svg";
import LocationIcon from "../../assets/images/icons/location-pin-alt-1-svgrepo-com.svg";
import SearchIcon from "../../assets/images/all/search.svg";
import teacherSvg from '../../assets/images/icons/teacehr_+.svg'
import editSvg from '../../assets/images/icons/edit-1-svgrepo-com.svg'
import mediaImage from '../../assets/images/icons/Frame 399.svg'
import StudentSidebar from "../../components/StudentSidebar";
import CommanStudentTeacherHeader from '../../components/CommanStudentTeacherHeader';

const InstituteProfile = (props) => {
    const { id, flags } = useParams();
    console.log(id);
    // console.log(flags);
    const navigate = useNavigate();
    const [showOptions, setShowOptions] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const [profileimg, setProfileimg] = useState(""); // State to hold roles data
    const [profileDataEditable, setprofileDataEditable] = useState(false);
    const [bioInfoEditable, setBioInfoEditable] = useState(false);
    const [OtherInfoEditable, setOtherInfoEditable] = useState(false);
    const [StreamEditable, setStreamEditable] = useState(false);
    const [isSectionOpen, setIsSectionOpen] = useState(false);
    const [isSectionOpen1, setIsSectionOpen1] = useState(false);
    const [facilty, setFacility] = useState("")
    const [profileData, setProfileData] = useState({
        full_name: "",
        email_id: "",
        username: "",
        location: "",
    });
    const [managers, setManagers] = useState([]);
    const [bioInfo, setBioInfo] = useState({
        bio_info: "",
    });

    const [otherInfoDetails, setOtherInfoDetails] = useState({
        no_of_students_studyind: "",
        year_of_establishment: "",
        timings: "",
        hostels: "",
        approved_by: "",
        affiliation: "",
        gender_composition: "",
    });
    const [streamDetails, setStreamDetails] = useState({
        stream_name: '',
        fee: '',
        year: '',
        stream_bio: '',
    });
    const [streamDetails1, setStreamDetails1] = useState([]);
    const [facilitydata,setFacilityData] = useState([])
    const [isEditable2, setIsEditable2] = useState(false);
    const [managerDetails2, setManagerDetails2] = useState({
        fullName: "",
        email: "",
        phone: "",
        location: "",
    });

    const [editingIndex, setEditingIndex] = useState(null); 
    const [streams, setStreams] = useState([]); 
    const [editingIndex2, setEditingIndex2] = useState(null); 

    

    const handleEditClick5 = (index) => {
        setEditingIndex2(index); // Set the index of the facility being edited
    };
    
    const handleChange5 = (index, field, value) => {
        const updatedFacilities = facilitydata.map((facility, i) =>
            i === index ? { ...facility, [field]: value } : facility
        );
        setFacilityData(updatedFacilities);
    };

    const handleEditClick4 = (index) => {
        setEditingIndex(index); // Enable edit mode for the selected stream
    };

    const handleChange4 = (index, field, value) => {
        const updatedStreams = streams.map((stream, i) => 
            i === index ? { ...stream, [field]: value } : stream
        );
        setStreams(updatedStreams); // Update the state with modified data
    };

    const handleSave4 = () => {
        setEditingIndex(null); // Exit edit mode
        // Save to backend or perform additional actions here
        console.log("Updated Streams: ", streams);
    };

    
    const user_id = ACCESS_Log_In_User_Id();
    const flag = "link";  // Example flag parameter
    const encodedUserId = encodeURIComponent(user_id);
    const encodedFlag = encodeURIComponent(flag);
    const profileUrl = `${window.location.origin}/instituteProfile/${encodedUserId}/${encodedFlag}`;

    const urlToShare = profileUrl; // URL to be shared
    //const message = "Name: " + profileData.full_name + "   Email Id: " + profileData.email_id;


    useEffect(() => {
        fetchProfile();
        fetchMediaFile()
        fetchBioInfoDetails();
        fetchPersonalInfoDetails();
        fetchOtherInfoDetails();
        fetchStreamDetails();
        fetchFacilityDetails();
        GetinstituteManagerPersonalInfo()
    }, []); // Empty dependency array to run only on mount

    const fetchProfile = async () => {
        const user_id = ACCESS_Log_In_User_Id();
        axios.get(`${API_BASE_URL}/getprofileImgDetails?user_id=${user_id}`)
            .then(function (response) {
                // console.log("profile imahges ssss",response.data.code);
                if (response.data.code == 200) {
                    console.log(response.data.data[0]);
                    setProfileimg(response.data.data[0]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const fetchMediaFile = async () => {
        const user_id = ACCESS_Log_In_User_Id();
        axios.get(`${API_BASE_URL}/getinstituteMediaFile/${user_id}`)
            .then(function (response) {
                // console.log(response.data.code);
                if (response.data.code == 200) {
                    // console.log("media file will be",response.data.data[0]);
                    
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleEditClick2 = () => {
        setIsEditable2(!isEditable2);
    };

    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setManagerDetails2({ ...managerDetails2, [name]: value });
    };

    const shareToFacebook = () => {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            urlToShare
        )}`;
        window.open(facebookUrl, "_blank");
    };

    const shareToWhatsApp = () => {
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            urlToShare
        )}`;
        window.open(whatsappUrl, "_blank");
    };

    const shareToTwitter = () => {
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            urlToShare)}`;
        window.open(twitterUrl, "_blank");
    };

    const updateProfileImage = (e) => {
// alert("gfgfhgf")
        const file = e.target.files[0];
        if (file) {
            setProfileimg(file.name); // Update the profile image with the uploaded file
            // Prepare the FormData object for the POST request
            const formData = new FormData();
            formData.append('profile_image_name', file); // Append the image file to FormData
            formData.append('role_id', ACCESS_Role_ID());
            formData.append('user_id', ACCESS_Log_In_User_Id());

            try {
                // Use FormData in the POST request to send the file correctly
                let id = null;
                if (profileimg && profileimg.hasOwnProperty('id')) {
                    id = profileimg['id'] ?? null;
                }
                axios.post(`${API_BASE_URL}/createUpdateprofileImg/${id}`, formData)
                    .then((response) => {
                        console.log('Profile image uploaded successfully:', response.data);
                        fetchProfile(); // Fetch updated profile info
                    })
                    .catch((error) => {
                        console.error('Error saving profile:', error);
                    });
            } catch (error) {
                console.error('Error in uploading profile:', error);
            }
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUploadClick = () => {
        if (selectedFile) {
            console.log('File selected:', selectedFile);
            updateMediaImage(selectedFile)
            // Add your upload logic here (e.g., API call to upload the file)
        } else {
            alert('Please select a file before uploading.');
        }
    };

    const updateMediaImage = async (e) => {
        const file = e;
    
        if (file) {
            // Prepare the FormData object for the POST request
            const formData = new FormData();
            formData.append('media_image_name', file); // Append the image file
            formData.append('media_image_path', ''); // Use empty string instead of null for form data
            formData.append('description', ''); // Same for description
            formData.append('role_id', ACCESS_Role_ID());
            formData.append('user_id', ACCESS_Log_In_User_Id());
    
            try {
                const id = null; // Define id properly or use dynamic value if needed
    
                // Perform the POST request
                const response = await axios.post(
                    `${API_BASE_URL}/createinstituteMediaFile/${id}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data', // Explicitly set content type
                        },
                    }
                );
    
                // console.log('Institute media uploaded successfully:', response.data);
                // alert("Institute media uploaded successfully")
                fetchMediaFile()
                // Optionally call fetchProfile or other functions here
            } catch (error) {
                console.error('Error saving media:', error.response?.data || error.message);
            }
        } else {
            console.error('No file selected for upload.');
        }
    };
    

    const fetchBioInfoDetails = async () => {
        const user_id = ACCESS_Log_In_User_Id();
        axios.get(`${API_BASE_URL}/getbioInfoDetails?user_id=${user_id}`)
            .then(function (response) {
                //console.log(response);
                if (response.data.code == 200) {
                    // console.log(response.data.data[0]);
                    //setbioInfoDetails(response.data.data[0]);
                    setBioInfo({
                        id: response.data.data[0]["id"],
                        bio_info: response.data.data[0]['bio_info'],
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const BioInfoEdit = async () => {
        if (bioInfoEditable) {
            try {
                const id = bioInfo['id'] ?? null;
                const response = await axios.post(`${API_BASE_URL}/createUpdatebioInfo/${id}`, {
                    user_id: ACCESS_Log_In_User_Id(),
                    role_id: ACCESS_Role_ID(),
                    bio_info: bioInfo.bio_info
                });

                // Assuming the API returns the updated profile data
                // console.log('Profile updated:', response.data);
                // Optionally update the state with the new data
                setProfileData(response.data);
            } catch (error) {
                console.error('Error saving profile:', error);
            }
        }
        // Toggle the edit mode
        setBioInfoEditable(!bioInfoEditable);
        fetchBioInfoDetails();
        fetchPersonalInfoDetails();
    };
    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setBioInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const fetchPersonalInfoDetails = async () => {
        const user_id = ACCESS_Log_In_User_Id();
        axios.get(`${API_BASE_URL}/getpersonalInfoDetails?user_id=${user_id}`)
            .then(function (response) {
                // console.log(response);
                if (response.data.code == 200) {
                    // console.log(response.data.data[0]);
                    //setpersonalInfoDetails(response.data.data[0]);
                    setProfileData({
                        id: response.data.data[0]["id"],
                        full_name: response.data.data[0]["full_name"],
                        email_id: response.data.data[0]["email_id"],
                        username: response.data.data[0]["username"],
                        location: response.data.data[0]["location"],
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const personalInfoEdit = async () => {
        if (profileDataEditable) {
            try {
                // Assuming the API endpoint is 'https://example.com/api/profile'
                const id = profileData['id'] ?? null;
                const response = await axios.post(`${API_BASE_URL}/createUpdatepersonalInfoDetails/${id}`, {
                    user_id: ACCESS_Log_In_User_Id(),
                    role_id: ACCESS_Role_ID(),
                    full_name: profileData.full_name,
                    email_id: profileData.email_id,
                    username: profileData.username,
                    location: profileData.location
                });

                // Assuming the API returns the updated profile data
                // console.log('Profile updated:', response.data);
                // Optionally update the state with the new data
                setProfileData(response.data);
            } catch (error) {
                console.error('Error saving profile:', error);
            }
        }
        // Toggle the edit mode
        setprofileDataEditable(!profileDataEditable);
        fetchPersonalInfoDetails();
        fetchBioInfoDetails();
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };


    const handleToggleSection = () => {
        setIsSectionOpen(true);
    };

    const handleToggleSection1 = () => {
        setIsSectionOpen1(!isSectionOpen1);
    };
    /// Other info start

    const fetchOtherInfoDetails = async () => {
        const user_id = ACCESS_Log_In_User_Id();
        axios.get(`${API_BASE_URL}/getInstitutesOtherDataById/${user_id}`)
            .then(function (response) {
                // console.log("Access data field is", response);
                if (response.data.code == 200) {
                    setOtherInfoDetails({
                        id: response.data.data[0]["id"],
                        no_of_students_studyind: response.data.data[0]["no_of_students_studyind"],
                        year_of_establishment: response.data.data[0]["year_of_establishment"],
                        timings: response.data.data[0]["timings"],
                        hostels: response.data.data[0]["hostels"],
                        approved_by: response.data.data[0]["approved_by"],
                        affiliation: response.data.data[0]["affiliation"],
                        gender_composition: response.data.data[0]["gender_composition"],
                    });

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const CreateUpdateOtherInfoDetails = async () => {
            console.log("otherInfoDetailsotherInfoDetails",otherInfoDetails)
        // return false
        // if (OtherInfoEditable) {
        try {
            const id = null;
            const response = await axios.post(`${API_BASE_URL}/createUpdateInstitutesOtherData/${id}`, {
                user_id: ACCESS_Log_In_User_Id(),
                role_id: ACCESS_Role_ID(),
                no_of_students_studyind: otherInfoDetails.no_of_students_studyind,
                year_of_establishment: otherInfoDetails.year_of_establishment,
                timings: otherInfoDetails.timings,
                hostels: otherInfoDetails.hostels,
                approved_by: otherInfoDetails.approved_by,
                affiliation: otherInfoDetails.affiliation,
                gender_composition: otherInfoDetails.gender_composition,
            });

            // Assuming the API returns the updated profile data
            console.log('Profile updated updateinstatitue dtaa :', response.data);
            // Optionally update the state with the new data
            setOtherInfoDetails(response.data);
        } catch (error) {
            console.error('Error saving profile:', error);
        }
        // }
        // Toggle the edit mode
        setOtherInfoDetails(!OtherInfoEditable);
        fetchBioInfoDetails();
        fetchPersonalInfoDetails();
        fetchOtherInfoDetails();
        fetchStreamDetails();
    };
    const CreateUpdateOtherInfoDetails1 = async (index) => {
        console.log("otherInfoDetailsotherInfoDetails 12333",streams[index])

    try {
        const updatedStream = streams[index];
        const id = streams[index]?.id;
        const response = await axios.post(`${API_BASE_URL}/createUpdateInstitutesStream/${id}`, {
            user_id: ACCESS_Log_In_User_Id(),
            role_id: ACCESS_Role_ID(),
            stream_name: updatedStream.stream_name,
            fee: updatedStream.fee,
            year: updatedStream.year,
            stream_bio: updatedStream.stream_bio,
        });
        // setStreams(response.data)
        setEditingIndex(null); // Exit edit mode
        fetchStreamDetails();
      
        console.log('Profile updated updateinstatitue dtaa :', response.data);
       
    } catch (error) {
        console.error('Error saving profile:', error);
    }
    // }
    // Toggle the edit mode
    setOtherInfoDetails(!OtherInfoEditable);
    fetchBioInfoDetails();
    fetchPersonalInfoDetails();
    fetchOtherInfoDetails();
    fetchStreamDetails();
};


    const handleOtherInfoDetails = (e) => {
        const { name, value } = e.target;
        setOtherInfoDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    /// Other info end

    /// Stream start

    const fetchStreamDetails = async () => {
        try {
            const user_id = ACCESS_Log_In_User_Id();
            const response = await axios.get(`${API_BASE_URL}/getInstitutesStreamById/${user_id}`);

            if (response.data.code === 200) {
                // console.log("Access data field is", response);

                // Directly use response data instead of mapping unnecessarily
                const data = response.data.data || []; // Default to an empty array if `data` is undefined
                setStreams(response.data.data);
                setStreamDetails1(data); // Assuming `setStreamDetails1` expects an array
            } else {
                console.log("Error: Unexpected response code", response.data.code);
            }
        } catch (error) {
            console.error("Error fetching stream details:", error);
        }
    };
    const fetchFacilityDetails = async () =>{
        try {
            const user_id = ACCESS_Log_In_User_Id();
            const response = await axios.get(`${API_BASE_URL}/getInstitutesFacilitiesById/${user_id}`);
            // console.log("dhdhdhhdhd============dddffff",response)
            if (response.data.code === 200) {
                console.log("Access data field is hhhhhh hhh  =======", response);

                // Directly use response data instead of mapping unnecessarily
                const data = response.data.data || []; // Default to an empty array if `data` is undefined
                setFacilityData(data); // Assuming `setStreamDetails1` expects an array
            } else {
                console.log("Error: Unexpected response code", response.data.code);
            }
        } catch (error) {
            console.error("Error fetching stream details:", error);
        }
    }

    const CreateUpdateStreamDetails = async () => {
        try {
            const id = streamDetails['id'] ?? null;
            const response = await axios.post(`${API_BASE_URL}/createUpdateInstitutesStream/${id}`, {
                user_id: ACCESS_Log_In_User_Id(),
                role_id: ACCESS_Role_ID(),
                stream_name: streamDetails.stream_name,
                fee: streamDetails.fee,
                year: streamDetails.year,
                stream_bio: streamDetails.stream_bio,
            });
            setStreamDetails(response.data);
            setIsSectionOpen(false)
            setShowOptions(!isSectionOpen)
        } catch (error) {
            console.error('Error saving profile:', error);
        }
        setStreamDetails(!StreamEditable);
        setIsSectionOpen(false)
        setShowOptions(!isSectionOpen)
        fetchBioInfoDetails();
        fetchPersonalInfoDetails();
        fetchOtherInfoDetails();
        fetchStreamDetails();
    };




    const GetinstituteManagerPersonalInfo= async () => {
        try {
            const user_id = ACCESS_Log_In_User_Id();
            const response = await axios.get(`${API_BASE_URL}/getinstituteManagerPersonalInfo/${user_id}`);

            if (response.data.code === 200) {
                // console.log("Access data field is institute789788888888", response);
                setManagers(response.data.data);
                
                
            } else {
                // console.log("Error: Unexpected response code", response.data.code);
            }
        } catch (error) {
            console.error("Error fetching stream details:77777777777777777", error);
        }
    };


    const CreateUpdateinstituteManagerPersonalInfo = async () => {
        try {
            const id = managers?.id ?? null; // Replace streamDetails if necessary
            const response = await axios.post(
                `${API_BASE_URL}/createUpdateinstituteManagerPersonalInfo/${id}`,
                {
                    user_id: ACCESS_Log_In_User_Id(),
                    role_id: ACCESS_Role_ID(),
                    full_name: managerDetails2.fullName,
                    femail_id: managerDetails2.email,
                    username: "SampleUsername", // Replace with the correct field
                    phone_no: managerDetails2.phone,
                    location: managerDetails2.location,
                }
            );
            // console.log("Manager updated successfully:", response.data);
            // alert("Manager updated successfully");
            GetinstituteManagerPersonalInfo()
            setIsEditable2(false); 
        } catch (error) {
            console.error("Error saving profile:", error);
            alert("Failed to save profile. Please try again.");
        }
    };
    const CreateUpdateInstitutesFacilities = async () => {
        // console.log("jdsjjdjdjsdjdsjdsjjd", facilty)
        // return false
        try {
            const id = streamDetails['id'] ?? null;
            const response = await axios.post(`${API_BASE_URL}/createUpdateInstitutesFacilities/${id}`, {
                user_id: ACCESS_Log_In_User_Id(),
                role_id: ACCESS_Role_ID(),
                facilities_name: facilty,

            });
            if (response.data.code === 200) {
                // console.log("ddddddddddddddd--------", response);

                // Directly use response data instead of mapping unnecessarily
                // const data = response.data.data || []; // Default to an empty array if `data` is undefined
                // setFacilityData(data); // Assuming `setStreamDetails1` expects an array
            } else {
                console.log("Error: Unexpected response code", response.data.code);
            }
            setIsSectionOpen1(!isSectionOpen1)

        } catch (error) {
            console.error('Error saving profile:', error);
        }
        setIsSectionOpen1(!isSectionOpen1)
        fetchBioInfoDetails();
        fetchPersonalInfoDetails();
        fetchOtherInfoDetails();
        fetchStreamDetails();
        fetchFacilityDetails()
    };

    const updateInstitutesFacilities = async (index) => {
        console.log("jdsjjdjdjsdjdsjdsjjd", facilitydata[index])
        // return false
        const updatedFacility = facilitydata[index];
            // return false
        try {
            const id = updatedFacility['id'] ?? null;
            const response = await axios.post(`${API_BASE_URL}/createUpdateInstitutesFacilities/${id}`, {
                user_id: ACCESS_Log_In_User_Id(),
                role_id: ACCESS_Role_ID(),
                facilities_name: updatedFacility?.facilities_name,

            });
            if (response.data.code === 200) {
                console.log("ddddddddddddddd--------5222", response);
                setEditingIndex2(null);
                fetchFacilityDetails();
                // Directly use response data instead of mapping unnecessarily
                // const data = response.data.data || []; // Default to an empty array if `data` is undefined
                // setFacilityData(data); // Assuming `setStreamDetails1` expects an array
            } else {
                console.log("Error: Unexpected response code", response.data.code);
            }
            setEditingIndex2(null);
        fetchFacilityDetails();
            setIsSectionOpen1(!isSectionOpen1)

        } catch (error) {
            console.error('Error saving profile:', error);
        }
        setIsSectionOpen1(!isSectionOpen1)
        fetchBioInfoDetails();
        fetchPersonalInfoDetails();
        fetchOtherInfoDetails();
        fetchStreamDetails();
        fetchFacilityDetails()
    };
    const handleStreamDetails = (e) => {
        const { name, value } = e.target;
        setStreamDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    /// Stream end
    // console.log("streamDetailsstreamDetails", managers)
    return (
        <div className="bg_dashboard">
            {/* Mobile Menu */}


            <CommanStudentTeacherHeader/>

            {/* Main Container */}
            <div className="container">
                <div className="row">
                    {/* Sidebar */}
                    <StudentSidebar />

                    {/* Main Content */}
                    <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5 ">
                        <div className="row mt-5">
                            <div className="col-md-9 ps-0">
                                <div className="search-box dashboard_search">
                                    <input type="text"
                                        className="common-input common-input--lg dashboard_search_input pill shadow-sm auto-suggestion-input"
                                        placeholder="Search" />
                                    <button type="submit" className="btn btn-main spl_burron  border-0"><img
                                        src={SearchIcon} alt="" /></button>
                                </div>
                            </div>
                            <div className="col-md-2 text-xl-center  mt-3">
                                <img src={NotificationIcon} alt="" />
                            </div>
                            <div className="col-md-1">
                                <div className="dashboard_profile ms-auto">
                                    <img src={profileimg && profileimg.profile_image_path ? `${Image_Base_Url}${profileimg.profile_image_path}` : ProfileImage} alt="" />
                                </div>
                            </div>
                        </div>
                        <p className="learm-tilte my-3">Edit Profile</p>

                        <div className="row p-0 mb-5">
                            <div className="dashboard_card p-0">
                                <div className="row align-items-center p-4">
                                    <div className="col-sm-4 col-lg-4 col-xl-2">
                                        <div className="dasboard_profile mx-auto">
                                            <img src={profileimg && profileimg.profile_image_path ? `${Image_Base_Url}${profileimg.profile_image_path}` : ProfileImage} alt="Profile Image" className="rounded-circle" />
                                        </div>

                                    </div>
                                    <div className="col-sm-4 col-lg-8 col-xl-8 text-center text-lg-start ps-lg-5">
                                        {flags === "website" && (
                                            <button className="btn btn-outline-light btn-outline-dark my-2 my-lg-0" onClick={() => document.getElementById("fileInput").click()}>Upload your college/university logo</button>
                                        )}
                                        <input type="file" id="fileInput" accept="image/*" style={{ display: "none" }}
                                            onChange={updateProfileImage} />

                                        <p className="mt-2 profiel_text text-start">At least 800x800 px recommended.
                                            JPG or PNG is allowed</p>
                                    </div>
                                    <div className="col-sm-4 col-lg-8 col-xl-2 mt-4 mt-sm-0">
                                        <div
                                            className="dasboard_profile_qr mx-auto d-flex justify-content-center align-items-center" onClick={() => setShowOptions(!showOptions)} style={{ cursor: "pointer" }}>
                                            <img src={qrImage} alt="Profile Image" className="" />
                                        </div>

                                        {showOptions && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "10%",
                                                    background: "#fff",
                                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                                    padding: "10px",
                                                    borderRadius: "8px",
                                                    zIndex: 10,

                                                }}
                                            >
                                                <button
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        padding: "10px",
                                                        margin: "5px 0",
                                                        cursor: "pointer",
                                                        border: "none",
                                                        backgroundColor: "transparent",
                                                    }}
                                                    onClick={shareToFacebook}
                                                >
                                                    <img
                                                        src="https://img.icons8.com/fluency/48/000000/facebook-new.png"
                                                        alt="Facebook"
                                                        style={{ width: "24px", marginRight: "10px" }}
                                                    />
                                                    Share to Facebook
                                                </button>
                                                <button
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        padding: "10px",
                                                        margin: "5px 0",
                                                        cursor: "pointer",
                                                        border: "none",
                                                        backgroundColor: "transparent",
                                                    }}
                                                    onClick={shareToWhatsApp}
                                                >
                                                    <img
                                                        src="https://img.icons8.com/color/48/000000/whatsapp.png"
                                                        alt="WhatsApp"
                                                        style={{ width: "24px", marginRight: "10px" }}
                                                    />
                                                    Share to WhatsApp
                                                </button>
                                                <button
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        padding: "10px",
                                                        margin: "5px 0",
                                                        cursor: "pointer",
                                                        border: "none",
                                                        backgroundColor: "transparent",
                                                    }}
                                                    onClick={shareToTwitter}
                                                >
                                                    <img
                                                        src="https://img.icons8.com/fluency/48/000000/twitter.png"
                                                        alt="Twitter"
                                                        style={{ width: "24px", marginRight: "10px" }}
                                                    />
                                                    Share to Twitter
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <hr className="dashboard_line" />
                                <div className="row py-4 px-5">
                                    <div className="dashboard_sub_card">
                                        <div className="row px-0 px-sm-3">
                                            <div className="col-7 col-sm-8">
                                                <p className="profile_text_2">Personal Info</p>
                                            </div>
                                            <div className="col-sm-4 col-5 text-end">
                                                {flags === "website" && (
                                                    <button onClick={personalInfoEdit}>
                                                        <img src={EditIcon} alt="Edit" /> {profileDataEditable ? "Save" : "Edit"}
                                                    </button>
                                                )}

                                            </div>
                                            <div className="col-sm-12 d-flex justify-content-between flex-column flex-lg-row mt-4">
                                                <div className="">
                                                    <p className="">Full Name</p>
                                                    {profileDataEditable ? (
                                                        <input
                                                            type="text"
                                                            name="full_name"
                                                            value={profileData['full_name']}
                                                            onChange={handleChange}
                                                            className="common-input dashboard_editable_input"
                                                        />
                                                    ) : (
                                                        <p className="profile_text_1 mt-2">{profileData['full_name']}</p>
                                                    )}
                                                </div>
                                                <div className="">
                                                    <p className="">Email</p>
                                                    {profileDataEditable ? (
                                                        <input
                                                            type="email"
                                                            name="email_id"
                                                            value={profileData.email_id}
                                                            onChange={handleChange}
                                                            className="common-input dashboard_editable_input"
                                                            readOnly
                                                    
                                                        />
                                                    ) : (
                                                        <p className="profile_text_1 mt-2">{profileData.email_id}</p>
                                                    )}
                                                </div>
                                                <div className="">
                                                    <p className="">Username</p>
                                                    {profileDataEditable ? (
                                                        <input
                                                            type="text"
                                                            name="username"
                                                            value={profileData.username}
                                                            onChange={handleChange}
                                                            className="common-input dashboard_editable_input"
                                                        />
                                                    ) : (
                                                        <p className="profile_text_1 mt-2">{profileData.username}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 mt-4">
                                                <p className="profile_text_2 mb-3">Location</p>

                                                <div className="position-relative">
                                                    {profileDataEditable ? (
                                                        <input
                                                            type="text"
                                                            name="location"
                                                            value={profileData.location}
                                                            onChange={handleChange}
                                                            className="common-input dashboard_editable_input"
                                                        />
                                                    ) : (

                                                        <span class="input-icon input-icon--left text-main">{profileData.location}


                                                        </span>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row px-5 pb-4">
                                    <div className="dashboard_sub_card">
                                        <div className="row px-0 px-sm-3">
                                            <div className="col-7 col-sm-8">
                                                <p className="profile_text_2">Bio</p>
                                            </div>
                                            <div className="col-sm-4 col-5 text-end">
                                                {flags === "website" && (
                                                    <button onClick={BioInfoEdit}>
                                                        <img src={EditIcon} alt="Edit" />
                                                        {bioInfoEditable ? "Save" : "Edit"}
                                                    </button>
                                                )}

                                            </div>
                                            <div className="col-sm-12 mt-4">
                                                {bioInfoEditable ? (
                                                    <textarea
                                                        name="bio_info"
                                                        value={bioInfo.bio_info}
                                                        onChange={handleChange1}
                                                        className="common-input dashboard_editable_input"
                                                        rows="4"
                                                    ></textarea>
                                                ) : (
                                                    <p>{bioInfo.bio_info}</p>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row px-5 pb-4">
                                    <div className="dashboard_sub_card">

                                        <div className="row px-0 px-sm-3">

                                            <div className="col-sm-12 mt-4">
                                                <label htmlFor="numStudents" className="profile_text_2 mb-3">No. of Students Studying (Approx.)</label>
                                                <input
                                                    type="number"
                                                    name="no_of_students_studyind"
                                                    value={otherInfoDetails.no_of_students_studyind}
                                                    onChange={handleOtherInfoDetails}
                                                    className="common-input common-input--md dash_borad_input_1"
                                                    placeholder="Enter number of students"
                                                />
                                            </div>


                                            <div className="col-sm-12 mt-4">
                                                <label htmlFor="yearEstablishment" className="profile_text_2 mb-3">Year of Establishment</label>
                                                <input
                                                    type="number"
                                                    name="year_of_establishment"
                                                    value={otherInfoDetails.year_of_establishment}
                                                    onChange={handleOtherInfoDetails}
                                                    className="common-input common-input--md dash_borad_input_1"
                                                    placeholder="Enter year"
                                                />
                                            </div>


                                            <div className="col-sm-12 mt-4">
                                                <label htmlFor="timings" className="profile_text_2 mb-3">Timings</label>
                                                <input
                                                    type="text"
                                                    name="timings"
                                                    value={otherInfoDetails.timings}
                                                    onChange={handleOtherInfoDetails}
                                                    className="common-input common-input--md dash_borad_input_1"
                                                    placeholder="Enter timings"
                                                />
                                            </div>

                                            <div className="col-sm-12 mt-4">
                                                <label htmlFor="hostels" className="profile_text_2 mb-3">Hostels</label>
                                                <select
                                                    name="hostels"
                                                    value={otherInfoDetails.hostels}
                                                    onChange={handleOtherInfoDetails}
                                                    className="common-input common-input--md dash_borad_input_1"
                                                >
                                                    <option disabled>Available</option>
                                                    <option value="available">Yes</option>
                                                    <option value="not available">No</option>
                                                </select>
                                            </div>




                                            <div className="col-sm-12 mt-4">
                                                <label htmlFor="approvedBy" className="profile_text_2 mb-3">Approved by</label>
                                                <input
                                                    type="text"
                                                    name="approved_by"
                                                    id="approvedBy"
                                                    value={otherInfoDetails.approved_by} // Updated to align with the state object `otherInfoDetails`
                                                    onChange={handleOtherInfoDetails}
                                                    className="common-input common-input--md dash_borad_input_1"
                                                    placeholder="Enter details"
                                                />
                                            </div>





                                            <div className="col-sm-12 mt-4">
                                                <label htmlFor="affiliation" className="profile_text_2 mb-3">Affiliation</label>
                                                <select
                                                    name="affiliation"
                                                    id="affiliation"
                                                    value={otherInfoDetails.affiliation} // Bind to state
                                                    onChange={handleOtherInfoDetails} // Update state dynamically
                                                    className="common-input common-input--md dash_borad_input_1"
                                                >
                                                    <option value="" disabled>Select Affiliation</option> {/* Default placeholder */}
                                                    <option value="private">Private</option>
                                                    <option value="government">Government</option>
                                                    <option value="semi-government">Semi-Government</option>
                                                </select>
                                            </div>





                                            <div className="col-sm-12 mt-4">
                                                <label htmlFor="genderComposition" className="profile_text_2 mb-3">Gender Composition</label>
                                                <div className="d-flex flex-column">
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            id="coed"
                                                            name="gender_composition"
                                                            value="coed"
                                                            className="form-check-input"
                                                            checked={otherInfoDetails.gender_composition === 'coed'}
                                                            onChange={handleOtherInfoDetails}
                                                        />
                                                        <label htmlFor="coed" className="form-check-label">Co-ed</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            id="boys"
                                                            name="gender_composition"
                                                            value="boys"
                                                            className="form-check-input"
                                                            checked={otherInfoDetails.gender_composition === 'boys'}
                                                            onChange={handleOtherInfoDetails}
                                                        />
                                                        <label htmlFor="boys" className="form-check-label">Boys</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            id="girls"
                                                            name="gender_composition"
                                                            value="girls"
                                                            className="form-check-input"
                                                            checked={otherInfoDetails.gender_composition === 'girls'}
                                                            onChange={handleOtherInfoDetails}
                                                        />
                                                        <label htmlFor="girls" className="form-check-label">Girls</label>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-sm-12 mt-4 text-end">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={CreateUpdateOtherInfoDetails}
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        border: 'none',
                                                        color: 'blue',
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    Save
                                                </button>
                                            </div>




                                            <div className="col-sm-12 mt-4">
                                                <div className="d-flex justify-content-between mb-3">
                                                    <label htmlFor="stream" className="profile_text_2">Stream</label>
                                                    <button type="button" className="" onClick={handleToggleSection}>
                                                        <img src={teacherSvg} alt="Add Teacher" />
                                                    </button>
                                                </div>

                                                {/* <div className="d-flex justify-content-between align-items-center">
                                                <p className="common-input common-input--md dash_borad_input_1">{streamDetails?.stream_name}</p>
                                                </div> */}
                                            </div>

                                           
                                            {/* <div className="col-sm-12 mt-4">
                                                {streamDetails1.map((stream, index) => (
                                                    <div key={index} className="border p-3 bg-light mb-4">
                                                       
                                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                                            <label htmlFor={`stream-${index}`} className="profile_text_2">
                                                                Stream
                                                            </label>
                                                            <button type="button" className="" >
                    <p>edit</p>
                </button>
                                                        </div>

                                                        
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="common-input common-input--md dash_borad_input_1">
                                                                {stream.stream_name}
                                                            </p>
                                                        </div>

                                                      
                                                        <h5 className="mt-4">{stream.degree || "Bachelors in Business Management"}</h5>
                                                        <div className="row mt-3 align-items-end">
                                                            <div className="col-sm-4">
                                                                <label htmlFor={`fee-${index}`} className="profile_text_2 mb-3">
                                                                    Fee Structure
                                                                </label>
                                                                <p className="common-input common-input--md dash_borad_input_1">
                                                                    {stream.fee || "N/A"}
                                                                </p>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <label htmlFor={`year-${index}`} className="profile_text_2 mb-3">
                                                                    Year
                                                                </label>
                                                                <p className="common-input common-input--md dash_borad_input_1">
                                                                    {stream.year || "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        
                                                        <div className="mt-3">
                                                            <label htmlFor={`bio-${index}`} className="profile_text_2 mb-3">
                                                                Bio
                                                            </label>
                                                            <p className="common-input common-input--md dash_borad_input_1">
                                                                {stream.stream_bio || "No bio available"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div> */}

<div className="col-sm-12 mt-4">
            {streams.map((stream, index) => (
                <div key={index} className="border p-3 bg-light mb-4">
                    {/* Stream Header */}
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <label htmlFor={`stream-${index}`} className="profile_text_2">
                            Stream
                        </label>
                        {editingIndex === index ? (
                            <button
                                type="button"
                                onClick={() =>CreateUpdateOtherInfoDetails1(index)}
                                className="btn btn-primary"
                            >
                                Save
                            </button>
                        ) : (
                            <button
                                type="button"
                                onClick={() => handleEditClick4(index)}
                                className="btn btn-secondary"
                            >
                                Edit
                            </button>
                        )}
                    </div>

                    {/* Stream Name */}
                    <div className="d-flex justify-content-between align-items-center">
                        {editingIndex === index ? (
                            <input
                                type="text"
                                value={stream.stream_name || ''}
                                onChange={(e) =>
                                    handleChange4(index, 'stream_name', e.target.value)
                                }
                                className="form-control"
                            />
                        ) : (
                            <p className="common-input common-input--md dash_borad_input_1">
                                {stream.stream_name}
                            </p>
                        )}
                    </div>

                    {/* Stream Details */}
                    {/* <h5 className="mt-4">
                        {editingIndex === index ? (
                            <input
                                type="text"
                                value={stream.degree || ''}
                                onChange={(e) =>
                                    handleChange4(index, 'degree', e.target.value)
                                }
                                className="form-control"
                            />
                        ) : (
                            stream.degree || "Bachelors in Business Management"
                        )}
                    </h5> */}
                    <div className="row mt-3 align-items-end">
                        <div className="col-sm-4">
                            <label htmlFor={`fee-${index}`} className="profile_text_2 mb-3">
                                Fee Structure
                            </label>
                            {editingIndex === index ? (
                                <input
                                    type="text"
                                    value={stream.fee || ''}
                                    onChange={(e) =>
                                        handleChange4(index, 'fee', e.target.value)
                                    }
                                    className="form-control"
                                />
                            ) : (
                                <p className="common-input common-input--md dash_borad_input_1">
                                    {stream.fee || "N/A"}
                                </p>
                            )}
                        </div>
                        <div className="col-sm-4">
                            <label htmlFor={`year-${index}`} className="profile_text_2 mb-3">
                                Year
                            </label>
                            {editingIndex === index ? (
                                <input
                                    type="text"
                                    value={stream.year || ''}
                                    onChange={(e) =>
                                        handleChange4(index, 'year', e.target.value)
                                    }
                                    className="form-control"
                                />
                            ) : (
                                <p className="common-input common-input--md dash_borad_input_1">
                                    {stream.year || "N/A"}
                                </p>
                            )}
                        </div>
                    </div>

                    {/* Stream Bio */}
                    <div className="mt-3">
                        <label htmlFor={`bio-${index}`} className="profile_text_2 mb-3">
                            Bio
                        </label>
                        {editingIndex === index ? (
                            <textarea
                                value={stream.stream_bio || ''}
                                onChange={(e) =>
                                    handleChange4(index, 'stream_bio', e.target.value)
                                }
                                className="form-control"
                            />
                        ) : (
                            <p className="common-input common-input--md dash_borad_input_1">
                                {stream.stream_bio || "No bio available"}
                            </p>
                        )}
                    </div>
                </div>
            ))}
        </div>


                                            {isSectionOpen && (
                                                <div className="col-sm-12 mt-2">
                                                    <label htmlFor="stream" className="profile_text_2 mb-3">Stream</label>
                                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                                        <input
                                                            type="text"
                                                            name="stream_name"
                                                            id="stream"
                                                            value={streamDetails.stream_name}
                                                            onChange={handleStreamDetails}
                                                            className="common-input common-input--md dash_borad_input_1"
                                                            placeholder="Business"
                                                        />
                                                    </div>
                                                    <div className="col-sm-12 mt-4">
                                                        <div className="border p-3 bg-light">
                                                            <h5>Bachelors in Business Management</h5>

                                                            <div className="row mt-3 align-items-end">
                                                                <div className="col-sm-4">
                                                                    <label htmlFor="feeAmount" className="profile_text_2 mb-3">Fee Structure</label>
                                                                    <input
                                                                        type="number"
                                                                        name="fee"
                                                                        id="feeAmount"
                                                                        value={streamDetails.fee || ''}
                                                                        onChange={handleStreamDetails}
                                                                        className="common-input common-input--md dash_borad_input_1"
                                                                        placeholder="Enter Amount"
                                                                    />
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <select
                                                                        name="year"
                                                                        id="feeYear"
                                                                        value={streamDetails.year || ''}
                                                                        onChange={handleStreamDetails}
                                                                        className="common-input common-input--md dash_borad_input_1"
                                                                    >
                                                                        <option value="" disabled>Select Year</option>
                                                                        <option value="2015">2015</option>
                                                                        <option value="2016">2016</option>
                                                                        <option value="2017">2017</option>
                                                                        <option value="2018">2018</option>
                                                                        <option value="2019">2019</option>
                                                                        <option value="2020">2020</option>
                                                                        <option value="2021">2021</option>
                                                                        <option value="2022">2022</option>
                                                                        <option value="2023">2023</option>
                                                                        <option value="2024">2024</option>
                                                                        <option value="2025">2025</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="mt-3">
                                                                <label htmlFor="bio" className="profile_text_2 mb-3">Bio</label>
                                                                <textarea
                                                                    id="bio"
                                                                    name="stream_bio"
                                                                    rows="4"
                                                                    value={streamDetails.stream_bio || ''}
                                                                    onChange={handleStreamDetails}
                                                                    className="common-input common-input--md dash_borad_input_1"
                                                                    placeholder="Enter details about the program"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}


                                            <div className="col-sm-12 mt-4 text-end">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={CreateUpdateStreamDetails}
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        border: 'none',
                                                        color: 'blue',
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        


                                           {/* <div className="col-sm-12 mt-4">
                                                <div className="border p-3 bg-light">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <input
                                                            type="text"
                                                            className="form-control border-0 bg-light h5 mb-0"
                                                            value="Masters in Business Management"
                                                            readOnly
                                                        />
                                                        <button type="button" className="btn p-0">
                                                            <img src={editSvg} alt="Edit" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>*/}

                                           




                                        </div>

                                         {/* <div className="col-sm-12 mt-4">


                                                <div className="d-flex justify-content-between align-items-center mb-4">
                                                    <input
                                                        type="text"


                                                        className="common-input common-input--md dash_borad_input_1"
                                                        placeholder="Technology"
                                                    />
                                                </div>
                                                <div className="col-sm-12 mt-1 text-end">
                                                    <span className="learm-tilte2">Add specialization</span>
                                                </div>

                                            </div>

                                            <div className="col-sm-12 mt-4">


                                                <div className="d-flex justify-content-between align-items-center mb-4">
                                                    <input
                                                        type="text"


                                                        className="common-input common-input--md dash_borad_input_1"
                                                        placeholder="Technology"
                                                    />
                                                </div>
                                                <div className="col-sm-12 mt-1 text-end">
                                                    <span className="learm-tilte2">Add specialization</span>
                                                </div>

                                            </div> */}

                                        {/* <div className="col-sm-12 mt-4">
                                         
                                            <div className="d-flex justify-content-between mb-3">
                                                <label htmlFor="Facilities" className="profile_text_2">Facilities</label>
                                                <button
                                                    type="button"
                                                    className=""
                                                    onClick={handleToggleSection1}
                                                >
                                                    <img src={teacherSvg} alt="Add Teacher" />
                                                </button>
                                            </div>

                                            
                                           {facilitydata?.map((stream, index) => <div className="d-flex justify-content-between align-items-center mb-4">
                                                <p className="common-input common-input--md dash_borad_input_1">
                                                    {stream?.facilities_name}
                                                </p>
                                            </div>)}

                                           
                                            {isSectionOpen1 && (
                                                <div className="d-flex justify-content-between align-items-center mb-4">
                                                    <input
                                                        type="text"
                                                        name="facility_name"
                                                        id="facility"
                                                        onChange={(e) => setFacility(e.target.value)} // Corrected the onChange handler
                                                        className="common-input common-input--md dash_borad_input_1"
                                                        placeholder="Business"
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-sm-12 mt-4 text-end">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={CreateUpdateInstitutesFacilities}
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        border: 'none',
                                                        color: 'blue',
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    Save
                                                </button>
                                        </div> */}

<div className="col-sm-12 mt-4">
    {/* Facilities Header */}
    <div className="d-flex justify-content-between mb-3">
        <label htmlFor="Facilities" className="profile_text_2">Facilities</label>
        <button
            type="button"
            className=""
            onClick={handleToggleSection1}
        >
            <img src={teacherSvg} alt="Add Teacher" />
        </button>
    </div>

    {/* Facilities List */}
    {facilitydata?.map((facility, index) => (
        <div key={index} className="d-flex justify-content-between align-items-center mb-4">
            {editingIndex2 === index ? (
                <input
                    type="text"
                    value={facility.facilities_name || ''}
                    onChange={(e) =>
                        handleChange5(index, 'facilities_name', e.target.value)
                    }
                    className="common-input common-input--md dash_borad_input_1"
                />
            ) : (
                <p className="common-input common-input--md dash_borad_input_1">
                    {facility.facilities_name}
                </p>
            )}
            {editingIndex2 === index ? (
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={()=>updateInstitutesFacilities(index)}
                >
                    Save
                </button>
            ) : (
                <button
                    type="button"
                    
                    className="btn btn-secondary"
                    onClick={() => handleEditClick5(index)}
                >
                    Edit
                </button>
            )}
        </div>
    ))}

    {/* Add New Facility Section */}
    {isSectionOpen1 && (
        <div className="d-flex justify-content-between align-items-center mb-4">
            <input
                type="text"
                name="facility_name"
                id="facility"
                value={facilty}
                onChange={(e) => setFacility(e.target.value)}
                className="common-input common-input--md dash_borad_input_1"
                placeholder="Enter Facility Name"
            />
        </div>
    )}

    {/* Save Button */}
    <div className="col-sm-12 mt-4 text-end">
        <button
            className="btn btn-primary"
            onClick={CreateUpdateInstitutesFacilities}
            style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline',
            }}
        >
            Save
        </button>
    </div>
</div>




                                    </div>
                                </div>


                                <div className="row px-5 pb-4">
            <div className="dashboard_sub_card">
                <div className="row px-0 px-sm-3">
                    <div className="col-sm-12">
                        <label htmlFor="mediaUpload" className="profile_text_2 mb-3 learn-title2">
                            Upload Media
                        </label>

                        <div className="position-relative">
                            <input
                                type="text"
                                className="common-input common-input--md dash_borad_input_1"
                                id="courseTitle"
                                placeholder={selectedFile ? selectedFile.name : "No file selected"}
                                maxLength="60"
                                readOnly
                            />
                            <span
                                className="character-count position-absolute"
                                style={{
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                }}
                            >
                                <label htmlFor="mediaFile" className="media-btn" style={{ cursor: 'pointer' }}>
                                    <img className="me-2" src={mediaImage} alt="Media Icon" />
                                    <span>Media</span>
                                </label>
                                <input
                                    type="file"
                                    id="mediaFile"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </span>
                        </div>

                        <label className="note learm-tilte2 fw-500 font-14">
                            Note: All files should be at least 720p and less than 4.0 GB.
                        </label>

                        <div className="mt-3 text-end">
                            <button
                                onClick={handleUploadClick}
                                className="btn btn_cousre bg-main-btn text-white py-2 px-4 fw-500 font-20"
                            >
                                Upload File
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

                          
                                <div className="row px-5 pb-4">
            <div className="dashboard_sub_card">
                <div className="row px-0 px-sm-3">
                    <div className="col-7 col-sm-8">
                        <p className="profile_text_2">Personal Info</p>
                    </div>
                    <div className="col-sm-4 col-5 text-end">
                        <span
                            className="learm-tilte2"
                            style={{ cursor: "pointer" }}
                            onClick={isEditable2 ? CreateUpdateinstituteManagerPersonalInfo : handleEditClick2}
                        >
                            {isEditable2 ? "Save" : "Add Manager"}
                        </span>
                    </div>
                    <div className="col-sm-12 d-flex justify-content-between flex-column flex-lg-row mt-4">
                        <div>
                            <p>Full Name</p>
                            {isEditable2 ? (
                                <input
                                    type="text"
                                    name="fullName"
                                    value={managerDetails2.fullName}
                                    onChange={handleInputChange2}
                                    className="common-input"
                                />
                            ) : (
                                <p className="profile_text_1 mt-2">
                                    {managerDetails2.fullName}
                                </p>
                            )}
                        </div>
                        <div>
                            <p>Email</p>
                            {isEditable2 ? (
                                <input
                                    type="email"
                                    name="email"
                                    value={managerDetails2.email}
                                    onChange={handleInputChange2}
                                    className="common-input"
                                />
                            ) : (
                                <p className="profile_text_1 mt-2">
                                    {managerDetails2.email}
                                </p>
                            )}
                        </div>
                        <div>
                            <p>phone</p>
                            {isEditable2 ? (
                                <input
                                    type="text"
                                    name="phone"
                                    value={managerDetails2.phone}
                                    onChange={handleInputChange2}
                                    className="common-input"
                                />
                            ) : (
                                <p className="profile_text_1 mt-2">
                                    {managerDetails2.phone}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-12 mt-4">
                        <p className="profile_text_2 mb-3">Location</p>
                        <div className="position-relative">
                            <input
                                type="url"
                                name="location"
                                value={managerDetails2.location || ""}
                                onChange={handleInputChange2}
                                className={`common-input common-input--md dash_borad_input common-input--withLeftIcon ${isEditable2 ? "" : "disabled"}`}
                                id="facebookUrl"
                                placeholder="Facebook Profile Url"
                                disabled={!isEditable2}
                            />
                            <span className="input-icon input-icon--left text-main">
                                <img src={LocationIcon} alt="" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {!isEditable2 && (<div className="row px-5 pb-4">
            {managers?.map((stream, index) => <div className="dashboard_sub_card">
                <div className="row px-0 px-sm-3">
                    <div className="col-7 col-sm-8">
                        <p className="profile_text_2">Personal Info</p>
                    </div>
                    
                   
                    <div className="col-sm-12 d-flex justify-content-between flex-column flex-lg-row mt-4">
                        <div>
                            <p>Full Name</p>
                                <p className="profile_text_1 mt-2">
                                    {stream.full_name}
                                </p>
                           
                        </div>
                        <div>
                            <p>Email</p>
                           
                                <p className="profile_text_1 mt-2">
                                    {stream.email_id}
                                </p>
                           
                        </div>
                        <div>
                            <p>phone</p>
                           
                                <p className="profile_text_1 mt-2">
                                    {stream.phone_no}
                                </p>
                          
                        </div>
                    </div>
                    <div className="col-sm-12 mt-4">
                        <p className="profile_text_2 mb-3">Location</p>
                        <div className="position-relative">
                            {/* <input
                                type="url"
                                name="location"
                                value={managerDetails2.location || ""}
                                onChange={handleInputChange2}
                                className={`common-input common-input--md dash_borad_input common-input--withLeftIcon ${isEditable2 ? "" : "disabled"}`}
                                id="facebookUrl"
                                placeholder="Facebook Profile Url"
                                disabled={!isEditable2}
                            /> */}
                            <p className={`common-input common-input--md dash_borad_input common-input--withLeftIcon`}>{stream.location}</p>
                            <span className="input-icon input-icon--left text-main">
                                <img src={LocationIcon} alt="" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>)}

                            </div>


                        </div>

                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default InstituteProfile;
