import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Import images


import ManageEditIcon from "../../assets/images/icons/manage_edit.svg";
import ProfileImage from "../../assets/images/all/profiel.png";

import { Footer } from "../../components";
import StudentSidebar from "../../components/StudentSidebar";
import GlobalSearch from "../../components/GlobalSearch";
import CommanStudentTeacherHeader from "../../components/CommanStudentTeacherHeader";

const StudentChatList = () => {
  const navigate = useNavigate();
  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
    <CommanStudentTeacherHeader/>

      {/* Main Content */}
      <div className="container">
        <div className="row">
          {/* Sidebar */}
          <StudentSidebar/>

          {/* Main Content Area */}
          <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5">
          <GlobalSearch/>

            {/* Additional Content */}
            <div className="row align-items-center my-3">
              <div className="col-7 d-flex">
                <div className="text-md-start me-3">
                  <a href="/" className="btn btn_cousre px-2 py-2">General</a>
                </div>
                <div className="text-md-start">
                  <a href="/" className="btn btn_cousre px-2 py-2" style={{ background: "#DDD" }}>Group</a>
                </div>
              </div>
              <div className="col-5 text-end">
                <span className="learm-tilte2 font-14 font-lg-16 me-0">Requests (6)</span>
                <img src={ManageEditIcon} alt="Manage Edit" />
              </div>
            </div>

            {/* Example Cards */}
            <div className="row gy-3 p-0 mb-5">
              {[1, 2, 3, 4].map((item) => (
                <div key={item} className="dashboard_card p-0">
                  <div className="row align-items-end p-4" onClick={()=>navigate('/DashboardChatBox')}>
                    <div className="col-2 col-sm-1">
                      <div className="chat_icons mx-auto position-relative">
                        <img src={ProfileImage} alt="Profile" className="rounded-circle" />
                        <div className="circle_dot"></div>
                      </div>
                    </div>
                    <div className="col-8 col-sm-10">
                      <p className="course_video_time fw-500">Sarah Solomon</p>
                      <p className="mt-1 single-line-text">
                        At least 800x800 px recommended. JPG or PNG is allowed
                      </p>
                    </div>
                    <div className="col-2 col-sm-1 text-end">
                      <span className="mt-2 text-end">10 m</span>
                    </div>
                  </div>
                </div>
              ))}
              <span className="learm-tilte2 font-16 text-center">View More</span>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default StudentChatList;
