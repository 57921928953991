import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Footer, Navbar } from "../../components";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url, ACCESS_Role_ID, ACCESS_Log_In_User_Id } from '../../constants/apiConstants';
import ProfileImage from "../../assets/images/all/profiel.png";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";
import EditIcon from "../../assets/images/icons/edit-1-svgrepo-com.svg";

import StudentSidebar from '../../components/StudentSidebar';
import GlobalSearch from '../../components/GlobalSearch';
import { getAPI, postAPI, uploadFileAPI } from "../../services"
import CommanStudentTeacherHeader from '../../components/CommanStudentTeacherHeader';


function Studentdashboard(props) {
    const { user_id } = useParams();
    const [isEditable, setIsEditable] = useState(false);
    const [isEditable1, setIsEditable1] = useState(false);
    const navigate = useNavigate();
    const [profileimg, setProfileimg] = useState(""); // State to hold roles data
    // const [bioInfoDetails, setbioInfoDetails] = useState("");
    // const [personalInfoDetails, setpersonalInfoDetails] = useState("");
    // const [errors, setErrors] = useState({});
    const [profileData, setProfileData] = useState({
        full_name: "",
        email_id: "",
        username: "",
        location: "",
        //bio_info: "",
    });
    const [bioInfo, setBioInfo] = useState({
        bio_info: "",
    });
    useEffect(() => {
        fetchProfile();
        fetchBioInfoDetails();
        fetchPersonalInfoDetails();
    }, []); // Empty dependency array to run only on mount



    // const fetchProfile = async () => {
    //     try {
    //         const user_id = ACCESS_Log_In_User_Id();
    //         console.log("1111111", user_id, ACCESS_TOKEN_NAME())
    //         const response = await getAPI(`/getprofileImgDetails?user_id=${user_id}`)
    //         console.log("yyyyyyyyyhhhhh", response)
    //         setProfileimg(response.data[0]);
    //     } catch (error) {
    //         console.error("Error fetching profile:", error);
    //     }
    // };

    const fetchProfile = async () => {
        const user_id = ACCESS_Log_In_User_Id();
        axios.get(`${API_BASE_URL}/getprofileImgDetails?user_id=${user_id}`)
          .then(function (response) {
            // console.log(response.data.code);
            if (response.data.code == 200) {
              //console.log(response.data.data[0]);
              setProfileimg(response.data.data[0]);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      };
    


    const updateProfileImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfileimg(file.name); // Update the profile image with the uploaded file
            // Prepare the FormData object for the POST request
            const formData = new FormData();
            formData.append('profile_image_name', file); // Append the image file to FormData
            formData.append('role_id', ACCESS_Role_ID());
            formData.append('user_id', ACCESS_Log_In_User_Id());

            try {
                // Use FormData in the POST request to send the file correctly
                let id = null;
                if (profileimg && profileimg.hasOwnProperty('id')) {
                    id = profileimg['id'] ?? null;
                }
                axios.post(`${API_BASE_URL}/createUpdateprofileImg/${id}`, formData)
                    .then((response) => {
                        console.log('Profile image uploaded successfully:', response.data);
                        fetchProfile(); // Fetch updated profile info
                    })
                    .catch((error) => {
                        console.error('Error saving profile:', error);
                    });
            } catch (error) {
                console.error('Error in uploading profile:', error);
            }
        }
    };


    // const updateProfileImage = async (e) => {
    //     const file = e.target.files[0];
    //     console.log("dddbdbdbdbd",file)
    //     // return false
    //     if (file) {
    //         setProfileimg(file.name);

    //         const formData = new FormData();
    //         formData.append("profile_image_name", file);
    //         formData.append("role_id", ACCESS_Role_ID());
    //         formData.append("user_id", ACCESS_Log_In_User_Id());

    //         try {
    //             let id = null;
    //             if (profileimg && profileimg.hasOwnProperty("id")) {
    //                 id = profileimg["id"] ?? null;
    //             }

    //             const endpoint = id
    //                 ? `/createUpdateprofileImg/${id}`
    //                 : `/createUpdateprofileImg`;

    //             const response = await uploadFileAPI(endpoint, formData);
    //             console.log("Profile image uploaded successfully:", response);

    //             fetchProfile();
    //         } catch (error) {
    //             console.error("Error uploading profile image:", error);
    //         }
    //     }
    // };

    const fetchBioInfoDetails = async () => {
        const user_id = ACCESS_Log_In_User_Id();
        axios.get(`${API_BASE_URL}/getbioInfoDetails?user_id=${user_id}`)
            .then(function (response) {
                //console.log(response);
                if (response.data.code == 200) {
                    // console.log(response.data.data[0]);
                    //setbioInfoDetails(response.data.data[0]);
                    setBioInfo({
                        id: response.data.data[0]["id"],
                        bio_info: response.data.data[0]['bio_info'],
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        // try {
        //     const user_id = ACCESS_Log_In_User_Id();
        //     console.log("1111111", user_id, ACCESS_TOKEN_NAME())
        //     const response = await getAPI(`/getbioInfoDetails?user_id=${user_id}`)
        //     console.log("yyyyyyyyyhhhhh", response)
        //     setbioInfoDetails(response.data[0]);
        //     setBioInfo({
        //         id: response.data[0]["id"],
        //         bio_info: response.data[0]['bio_info'],
        //     });
        // } catch (error) {
        //     console.error("Error fetching profile:", error);
        // }
    };

    const BioInfoEdit = async () => {
        if (isEditable1) {
            const id = bioInfo['id'] ?? null;
            try {
                // Assuming the API endpoint is 'https://example.com/api/profile'
                const response = await axios.post(`${API_BASE_URL}/createUpdatebioInfo/${id}`, {
                    user_id: ACCESS_Log_In_User_Id(),
                    role_id: ACCESS_Role_ID(),
                    bio_info: bioInfo.bio_info
                });

                // Assuming the API returns the updated profile data
                console.log('Profile updated:', response.data);
                // Optionally update the state with the new data
                setProfileData(response.data);
            } catch (error) {
                console.error('Error saving profile:', error);
            }

            // try {
            //     // const id = profileData['id'] ?? null;
            //     // console.log("1111111", user_id, ACCESS_TOKEN_NAME())
            //     const response = await postAPI(`/createUpdatebioInfo/${id}`, {
            //                 user_id: ACCESS_Log_In_User_Id(),
            //                 role_id: ACCESS_Role_ID(),
            //                 bio_info: bioInfo.bio_info
            //             });
            //             setProfileData(response.data);
            // } catch (error) {
            //     console.error("Error fetching profile:", error);
            // }
        }
        // Toggle the edit mode
        setIsEditable1(!isEditable1);
        fetchBioInfoDetails();
        fetchPersonalInfoDetails();
    };
    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setBioInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const fetchPersonalInfoDetails = async () => {
        const user_id = ACCESS_Log_In_User_Id();
        axios.get(`${API_BASE_URL}/getpersonalInfoDetails?user_id=${user_id}`)
            .then(function (response) {
                //console.log(response);
                if (response.data.code == 200) {
                    //console.log(response.data.data[0]['full_name']);
                    //setpersonalInfoDetails(response.data.data[0]);
                    setProfileData({
                        id: response.data.data[0]["id"],
                        full_name: response.data.data[0]["full_name"],
                        email_id: response.data.data[0]["email_id"],
                        username: response.data.data[0]["username"],
                        location: response.data.data[0]["location"],
                    });
                }
                console.log("profileData");
                console.log(profileData);
            })
            .catch(function (error) {
                console.log(error);
            });

        // try {
        //     const user_id = ACCESS_Log_In_User_Id();
        //     console.log("1111111", user_id, ACCESS_TOKEN_NAME())
        //     const response = await getAPI(`/getpersonalInfoDetails?user_id=${user_id}`)
        //     console.log("yyyyyyyyyhhhhh", response)
        //     if (response.data.code == 200) {
        //         setProfileData({
        //             id: response.data[0]["id"],
        //             full_name: response.data[0]["full_name"],
        //             email_id: response.data[0]["email_id"],
        //             username: response.data[0]["username"],
        //             location: response.data[0]["location"],
        //         });
        //     }
        // } catch (error) {
        //     console.error("Error fetching profile:", error);
        // }


    };
    const personalInfoEdit = async () => {
        if (isEditable) {
            try {
                const id = profileData['id'] ?? null;
                const response = await axios.post(`${API_BASE_URL}/createUpdatepersonalInfoDetails/${id}`, {
                    user_id: ACCESS_Log_In_User_Id(),
                    role_id: ACCESS_Role_ID(),
                    full_name: profileData.full_name,
                    email_id: profileData.email_id,
                    username: profileData.username,
                    location: profileData.location
                });

                // Assuming the API returns the updated profile data
                console.log('Profile updated:', response.data);
                // Optionally update the state with the new data
                setProfileData(response.data);
            } catch (error) {
                console.error('Error saving profile:', error);
            }


            // try {
            //     const id = profileData['id'] ?? null;
            //     // console.log("1111111", user_id, ACCESS_TOKEN_NAME())
            //     const response = await postAPI(`/createUpdatepersonalInfoDetails/${id}`, {
            //         user_id: ACCESS_Log_In_User_Id(),
            //         role_id: ACCESS_Role_ID(),
            //         full_name: profileData.full_name,
            //         email_id: profileData.email_id,
            //         username: profileData.username,
            //         location: profileData.location
            //     })
            //     console.log("dhhdhdhdh 123456",response)
            //     setProfileData(response.data);
            // } catch (error) {
            //     console.error("Error fetching profile:", error);
            // }


        }
        // Toggle the edit mode
        setIsEditable(!isEditable);
        fetchPersonalInfoDetails();
        fetchBioInfoDetails();
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleLoginNavigate = () => {
        navigate("/login"); // Navigate to login screen
      };
    
      // Log out and refresh page
      const handleLogOutNavigate = () => {
        localStorage.removeItem("ACCESS_TOKEN_NAME"); // Remove token
        navigate("/logOut"); // Navigate to logout route
        window.location.reload(); // Refresh the page
      };
    
      // Navigate to QR Reader
      const handleNavigateToQRReader = () => {
        navigate("/qrReader"); // Navigate to QR Reader screen
      };

    return (
        <div className="bg_dashboard">
            {/* Mobile Menu */}
            <CommanStudentTeacherHeader/>




            <div className="container" >
                <div className="row" >
                    {/* Sidebar */}
                    <StudentSidebar />

                    {/* Main Content */}
                    <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5">

                        <GlobalSearch profileImage={profileimg && profileimg.profile_image_path ? `${Image_Base_Url}${profileimg.profile_image_path}` : ProfileImage} />



                        <p className="learn-title my-3">Edit Profile</p>

                        {/* Profile Card */}
                        <div className="dashboard_card p-0">
                            <div class="row align-items-center p-4">
                                <div class="col-sm-4 col-lg-4 col-xl-2">
                                    <div class="dasboard_profile mx-auto">
                                        <img src={profileimg && profileimg.profile_image_path ? `${Image_Base_Url}${profileimg.profile_image_path}` : ProfileImage} alt="Profile Image" class="rounded-circle" />
                                    </div>

                                </div>
                                <div class="col-sm-4 col-lg-8 col-xl-10 text-center text-lg-start">
                                    <button className="btn btn-outline-light my-2 my-lg-0" onClick={() => document.getElementById("fileInput").click()}>Upload new photo</button>
                                    <input type="file" id="fileInput" accept="image/*" style={{ display: "none" }}
                                        onChange={updateProfileImage} />
                                    <p class="mt-2 profiel_text text-start">At least 800x800 px recommended.
                                        JPG or PNG is allowed</p>
                                </div>
                            </div>
                            <hr className="dashboard_line" />

                            {/* Personal Info Section */}
                            <div className="row py-4 px-5">
                                <div className="dashboard_sub_card">
                                    <div className="row px-0 px-sm-3">
                                        <div className="col-7 col-sm-8">
                                            <p className="profile_text_2">Personal Info</p>
                                        </div>
                                        <div className="col-sm-4 col-5 text-end">
                                            <button onClick={personalInfoEdit}>
                                                <img src={EditIcon} alt="Edit" /> {isEditable ? "Save" : "Edit"}
                                            </button>
                                        </div>
                                        <div className="col-sm-12 d-flex justify-content-between flex-column flex-lg-row mt-4">
                                            <div><p>Full Name</p>
                                                {isEditable ? (
                                                    <input
                                                        type="text"
                                                        name="full_name"
                                                        value={profileData['full_name']}
                                                        onChange={handleChange}
                                                        className="common-input dashboard_editable_input"
                                                    />
                                                ) : (
                                                    <p className="profile_text_1 mt-2">{profileData['full_name']}</p>
                                                )}
                                            </div>
                                            <div><p>Email</p>
                                                {isEditable ? (
                                                    <input
                                                        type="email"
                                                        name="email_id"
                                                        value={profileData.email_id}
                                                        onChange={handleChange}
                                                        className="common-input dashboard_editable_input"
                                                        readOnly
                                                    />
                                                ) : (
                                                    <p className="profile_text_1 mt-2">{profileData.email_id}</p>
                                                )}</div>
                                            <div><p>Username</p>
                                                {isEditable ? (
                                                    <input
                                                        type="text"
                                                        name="username"
                                                        value={profileData.username}
                                                        onChange={handleChange}
                                                        className="common-input dashboard_editable_input"
                                                    />
                                                ) : (
                                                    <p className="profile_text_1 mt-2">{profileData.username}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mt-4">
                                            <p class="profile_text_2 mb-3">Location</p>

                                            <div class="position-relative">
                                                {isEditable ? (
                                                    <input
                                                        type="text"
                                                        name="location"
                                                        value={profileData.location}
                                                        onChange={handleChange}
                                                        className="common-input dashboard_editable_input"
                                                    />
                                                ) : (
                                                    // <input type="url"
                                                    //     class="common-input common-input--md dash_borad_input common-input--withLeftIcon"
                                                    //     id="facebookUrl" />
                                                    <span class="input-icon input-icon--left text-main">{profileData.location}

                                                        {/* <img
                                                    src={LocationIcon}
                                                    alt="" /> */}
                                                    </span>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Bio Section */}
                            <div className="row px-5 pb-4">
                                <div className="dashboard_sub_card">
                                    <div className="row px-0 px-sm-3">
                                        <div className="col-7 col-sm-8">
                                            <p className="profile_text_2">Bio</p>
                                        </div>
                                        <div className="col-sm-4 col-5 text-end">
                                            <button onClick={BioInfoEdit}>
                                                <img src={EditIcon} alt="Edit" />
                                                {isEditable1 ? "Save" : "Edit"}
                                            </button>
                                        </div>
                                        <div className="col-sm-12 mt-4">
                                            {isEditable1 ? (
                                                <textarea
                                                    name="bio_info"
                                                    value={bioInfo.bio_info}
                                                    onChange={handleChange1}
                                                    className="common-input dashboard_editable_input"
                                                    rows="4"
                                                ></textarea>
                                            ) : (
                                                <p>{bioInfo.bio_info}</p>
                                            )}
                                            {/* <p className="font-weight">
                                                Simply dummy text of the printing and typesetting
                                                industry. Lorem Ipsum has
                                                been the industry's standard dummy text ever since the 1500s, when an
                                                unknown printer took a galley of type and scrambled it to make a type
                                                specimen book.
                                            </p>

                                            <p class="mt-3 font-weight">
                                                Simply dummy text of the printing and typesetting
                                                industry. Lorem Ipsum has
                                                been the industry's standard dummy text ever since the 1500s, when an
                                                unknown printer took a galley of type and scrambled it to make a type
                                                specimen book.
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
}

export default Studentdashboard;



