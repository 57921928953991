import axios from "axios";
import { API_BASE_URL, ACCESS_TOKEN_NAME } from "../src/constants/apiConstants";

// Retrieve token from localStorage


// Axios Instance with Base Configuration
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json", // Default header
  },
});

// Request Interceptor to Attach Token in Headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = ACCESS_TOKEN_NAME();
    if (token) {
        console.log("services token will be",token)
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// GET API Function
export const getAPI = async (endpoint, params = {}) => {
  try {
    const response = await axiosInstance.get(endpoint, { params });
    console.log("jdjdjdjdj",response)
    return response.data;
  } catch (error) {
    console.error("GET API Error:", error);
    throw error;
  }
};

// POST API Function
export const postAPI = async (endpoint, data = {}) => {
  try {
    const response = await axiosInstance.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("POST API Error:", error);
    throw error;
  }
};



export const uploadFileAPI = async (endpoint, formData) => {
    try {
      const token = ACCESS_TOKEN_NAME();
      const response = await axios.post(`${API_BASE_URL}${endpoint}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Required for file upload
          Authorization: `Bearer ${token}`, // Add authorization token
        },
      });
      return response.data;
    } catch (error) {
      console.error("File Upload Error:", error);
      throw error;
    }
  };
