import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import '../role/../header/style.css';
//import './styles.css';
import { Header } from "../role";
import { Adminmain, AdminNavbar } from "../role";
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url } from '../../../constants/apiConstants';
import axios from 'axios';


function CreateCourse() {
    const [status, setStatus] = useState("");
    const [courses, setCourses] = useState("");
    const navigate = useNavigate();
    
    // const handelSubmit = (e) => {
    //     e.preventDefault();
        
    //     const payload = {
    //         "course_name": courses,
    //         "status": status,
    //       }
    //     axios.post(API_BASE_URL + '/createCourses', payload)
    //         .then(function (response) {
    //             // console.log(response.data.access_token);
    //             if (response.status === 200) {                   
    //                 navigate("/admin/listCourse");
    //             }
    //             else {
    //                // props.showError("Username does not exists");
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // };


    const handelSubmit = (e) => {
        e.preventDefault();
        
        const payload = {
            "course_name": courses,
            "status": status,
        };
    
        const headers = {
            "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, 
            "Content-Type": "application/json",
        };
    
        axios.post(API_BASE_URL + '/createCourses', payload, { headers })
            .then(function (response) {
                if (response.status === 200) {                   
                    navigate("/admin/listCourse");
                } else {
                    // Handle other status codes if needed
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    
    return (
        <>
            <div>
                <Header />
                <div className="main-container">
                    <AdminNavbar />
                    {/* <Adminmain /> */}
                    <div className="main">

                        <div className="report-container">
                            <div className="report-header">
                                <h1 className="recent-Articles">Create Course</h1>
                            </div>

                            <div className="report-body">
                                <div className="user-form">
                                    <form onSubmit={handelSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">
                                                Course Name
                                            </label>
                                            <input type="text" className="form-control"
                                                id="role_name" name="role_name" value={courses} onChange={(e) => setCourses(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label htmlFor="email" className="form-label">
                                                Status
                                            </label>
                                            <select className="form-control" id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                                                <option value="">Select Status</option>
                                                <option value="1">Active</option>
                                                <option value="0">In Active</option>
                                            </select>
                                        </div>
                                        <button type="submit" className="btn btn-primary submit-btn">
                                            EDIT
                                        </button>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

export default CreateCourse;