import React ,{useEffect,useState,useRef} from 'react';
import { Footer, Navbar } from "../components";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME,Image_Base_Url,ACCESS_Role_ID,ACCESS_Log_In_User_Id } from '../constants/apiConstants';
import '../assets/css/bootstrap.min.css';
import '../assets/css/fontawesome-all.min.css';
import '../assets/css/slick.css';
import '../assets/css/line-awesome.min.css';
import '../assets/css/main.css';
import '../assets/css/responsive.css';
import SearchImage from '../../src/assets/images/all/search.svg'
import LogoImage from '../../src/assets/images/all/scan-svgrepo-com 1.svg'
import ProfileImage from '../../src/assets/images/all/profiel.png'
import GroupImage from '../../src/assets/images/icons/Group 85.svg'
import ArticleImage from '../../src/assets/images/icons/design-distribution-of-elements-of-an-article-svgrepo-com 1.svg'
import FeddImage from '../../src/assets/images/all/fedd.png'
import shapeImage from "../../src/assets/images/all/shape_1.png"
import shapeImage1 from "../../src/assets/images/icons/Unions.svg"

const Feed = () => {
  const [feed, setFeed] = useState([]); // State to hold roles data
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null); // State to hold error messages
  const navigate = useNavigate();
  console.log("ACCESS_Log_In_User_Id"+ACCESS_Log_In_User_Id());
  //const ACCESS_TOKEN_NAME = localStorage.getItem('ACCESS_TOKEN_NAME');
  // useEffect(() => {
  //   const url = API_BASE_URL + '/feeds'
  //   const fetchRoles = async () => {
  //       try {
  
  //           const response = await axios.get(url);
  //           console.log(response.data);
  //           setFeed(response.data); // Set roles data to state
  //           setLoading(false); // Set loading to false
  //       } catch (err) {
  //           setError(err.message); // Handle error
  //           setLoading(false); // Set loading to false
  //       }
  //   };
  
  //   fetchRoles(); // Call the fetch function
  // }, []); // Empty dependency array to run only on mount

  useEffect(() => {
    const url = API_BASE_URL + '/feeds';
  
    const fetchRoles = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace with your actual token
            "Content-Type": "application/json",
          },
        });
        console.log(response.data);
        setFeed(response.data); // Set roles data to state
        setLoading(false);
      } catch (err) {
        console.error("Error fetching feeds:", err); // Log error
        setError(err.message); // Handle error
        setLoading(false);
      } finally {
        setLoading(false); // Set loading to false
      }
    };
  
    fetchRoles(); // Call the fetch function
  }, []); // Empty dependency array to run only on mount
  

  const fileInputRef = useRef(null);

    const handleMediaClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        console.log('Selected files:', files);
     
    };
const showModal = () =>{
  const bootstrap = require('bootstrap');
  const myModal = new bootstrap.Modal(document.getElementById('postModal'), {
      backdrop: 'static',
      keyboard: false
  });
  myModal.show();
}

  
  return (
    <div>
      {/* Scroll to Top */}
      <div className="progress-wrap">
        <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>

      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <ul className="nav-menu flx-align nav-menu--mobile">
            <li className="nav-menu__item activePage">
              <a href="/" className="nav-menu__link">Home</a>
            </li>
            {/* Add other menu items here */}
          </ul>
          <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
            <a href="/" className="btn btn-main pill sign_btn px-sm-4">
              Signup/Login
            </a>
          </div>
        </div>
      </div>

      <img className="shape_top d-lg-block d-none" src={shapeImage} alt="" />
      <img className="shape_top_6 d-xl-block d-none" src={shapeImage1} alt="" />

      <main className="change-gradient">
        {/* Header */}
        {/* <header>
              <div className="logo"></div>
             
              <div className="header-menu d-lg-block d-none ">
                  <ul className="nav-menu flx-align">
                      <li className="nav-menu__item">
                          <a href="/" className="nav-menu__link">Home</a>
                      </li>
                      <li className="nav-menu__item">
                          <a href="/course" className="nav-menu__link">Courses</a>
                      </li>
                      <li className="nav-menu__item">
                          <a href="/Feed" className="nav-menu__link">Feed</a>
                      </li>
                      <li className="nav-menu__item">
                          <a href="" className="nav-menu__link">Learn with us</a>
                      </li>
                      <li className="nav-menu__item">
                          <a href="/teacher" className="nav-menu__link">Teachers</a>
                      </li>
                      <li className="nav-menu__item">
                          <a href="" className="nav-menu__link">Institute</a>
                      </li>
                      <li className="nav-menu__item">
                          <a href="" className="nav-menu__link">About Us</a>
                      </li>
                  </ul>
              </div>
            
              <div className="header-right flx-align pe-lg-5">
                  <div className="header-right__inner gap-3 flx-align d-lg-flex d-none">
                      <a href="" className="btn btn-main pill sign_btn px-sm-4">
                          Signup/Login
                      </a>
                      <a href="" className="btn cicrle_btn btn-main">
                          <img src={LogoImage} alt="" />
                      </a>
                  </div>
                  <button type="button" className="toggle-mobileMenu d-lg-none">
                      <i className="las la-bars"></i>
                  </button>
              </div>

          </header> */}
          <Navbar/>

       
        <section className="hero section-bg z-index-1">
          <div className="container py-5 container-two">
            <div className="row align-items-center gy-sm-5 gy-4" style={{ marginTop: '10px' }}>
              <div className="col-lg-12 order-1 order-lg-0">
                <div className="position-relative">
                  <div className="search-box">
                    <input
                      type="text"
                      className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                      placeholder="Search"
                    />
                    <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                      <img src={SearchImage} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Feed Section */}
        <section className="pb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-sm-10 col-md-12">
                <div className="spl_cards card mb-3">
                  <div className="row">
                    <div className="col-12 d-flex">
                      <div className="feed_img me-3">
                        <img src={ProfileImage} alt="" />
                      </div>
                      <form action="#" className="spl_form">
                        <div className="row gy-2">
                          <div className="col-12">
                            <input
                              type="text"
                              className="form-control common-input common-input--bg"
                              placeholder="Write what’s in your mind?"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-sm-12 mt-4 d-flex justify-content-center">
                    
                    {ACCESS_TOKEN_NAME() ? (
                      <div className="me-5">
                        <span onClick={()=>showModal()} className="course-time">
                          <img className="me-2" src={GroupImage} alt="" /> Media
                        </span>
                      </div>
                     ) : (
                      <div className="me-5">
                        
                      </div>
                    )}
                      <div>
                        <span className="course-time">
                          <img
                            className="me-2"
                            src={ArticleImage}
                            alt=""
                          />{' '}
                          Article
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                {/* Modal Structure */}
                <div className="modal upload_modal fade show" id="postModal" tabIndex="-1" aria-labelledby="postModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg upload_popup">
                        <div className="modal-content">
                            <div className="modal-header">
                                <span className="d-flex align-items-center">
                                    <div className="feed_img me-3">
                                        <img src={ProfileImage} alt="Profile Image" className="rounded-circle" />
                                    </div>
                                    <div>
                                        <p>Aariz Mahmood</p>
                                        <small className="text-muted ms-2">Public</small>
                                    </div>
                                </span>
                                <button type="button" className="btn-close btn_cls uplaod_close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <textarea className="form-control" placeholder="Write what's in your mind"></textarea>
                            </div>
                            <div className="modal-footer justify-content-between">
            <span className="text-start" onClick={handleMediaClick} style={{ cursor: 'pointer' }}>
                <img className="me-2" src={GroupImage} alt="Media Icon" /> Media
            </span>
            <button type="button" className="btn btn_cousre">Upload</button>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept="image/*,video/*,audio/*,.pdf,.doc,.docx,.ppt,.pptx,.txt"
                multiple
            />
        </div> 
                        </div>
                    </div>
                </div>

              <div className="col-lg-10 col-sm-10 col-md-12 mt-5">
                <div className="row">
                 
                  {/* Repeatable Image Cards */}
                  {/* {[...Array(7)].map((_, index) => ( */}
                  {feed.map((item, i) => (
                    <div key={i} className="col-sm-4 col-md-6 col-lg-6 col-xl-4 mb-4 feed_imgs">
                       <Link to={`/feeddetail/${item.id}`} className="feed_imgs">
                                                
                      <img src={`${Image_Base_Url}${item.post_image_path}`} alt="" />
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Footer */}
        <Footer/>
        {/* <footer className="footer-section">
          <img className="shape_top_10 d-lg-block d-none" src="assets/images/icons/Union-1.svg" alt="" />
          <div className="container container-two">
            <div className="row gy-sm-5">
              <div className="col-xl-6 col-sm-6">
                <div className="position-relative mb-5">
                  <div className="search-box">
                    <input
                      type="text"
                      className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                      placeholder="Search"
                    />
                    <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                      <img src={SearchImage} alt="" />
                    </button>
                  </div>
                </div>
                <div className="footer-widget">
                  <h3>Follow us on</h3>
                  <ul className="social-icon-list">
                    <li className="social-icon-list__item">
                      <a href="https://www.facebook.com" className="social-icon-list__link flx-center">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="social-icon-list__item">
                      <a href="https://www.pinterest.com" className="social-icon-list__link flx-center">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li className="social-icon-list__item">
                      <a href="https://www.linkedin.com" className="social-icon-list__link flx-center">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-5 offset-xl-1 col-sm-6">
                <div className="footer-widget">
                  <h3>Location</h3>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.330999415546!2d78.44399507462819!3d17.44386460117675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917f6ecb6987%3A0x7d6d85b768a4c33b!2sFg%20Designs!5e0!3m2!1sen!2sin!4v1707451998655!5m2!1sen!2sin"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Location"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
      </main>
    </div>
  );
};

export default Feed;
