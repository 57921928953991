import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import '../role/../header/style.css';
//import './styles.css';
import { Header } from "../role";
import { Adminmain, AdminNavbar } from "../role";
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url } from '../../../constants/apiConstants';
import axios from 'axios';


function EditRole() {
    const { id } = useParams();
    const [status, setStatus] = useState("");
    const [role, setRole] = useState("");
    const navigate = useNavigate();
    // useEffect(() => {
    //     const url = API_BASE_URL + '/getRoleDataById/' + id
    //     const fetchRoles = async () => {
    //         try {
    //             const response = await axios.get(url);
    //             setRole(response.data[0]['role_name']);
    //             setStatus(response.data[0]['status']);
    //         } catch (err) {
    //             // setError(err.message); // Handle error

    //         }
    //     };

    //     fetchRoles(); // Call the fetch function
    // }, []); // Empty dependency array to run only on mount

    // const handelSubmit = (e) => {
    //     e.preventDefault();
        
    //     const payload = {
    //         "role_name": role,
    //         "status": status,
    //       }
    //     axios.put(API_BASE_URL + '/updateRoleDataById/' + id, payload)
    //         .then(function (response) {
    //             // console.log(response.data.access_token);
    //             if (response.status === 200) {                   
    //                 navigate("/admin/listRole");
    //             }
    //             else {
    //                // props.showError("Username does not exists");
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // };


    useEffect(() => {
        const url = API_BASE_URL + '/getRoleDataById/' + id;
    
        const fetchRoles = async () => {
            try {
                const response = await axios.get(url, {
                    headers: {
                        "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace with your token
                        "Content-Type": "application/json",
                    },
                });
                setRole(response.data[0]['role_name']);
                setStatus(response.data[0]['status']);
            } catch (err) {
                console.error(err); // Handle error
            }
        };
    
        fetchRoles(); // Call the fetch function
    }, [id]); // Add 'id' to dependencies if it changes
    
    const handelSubmit = (e) => {
        e.preventDefault();
        
        const payload = {
            "role_name": role,
            "status": status,
        };
    
        axios.put(API_BASE_URL + '/updateRoleDataById/' + id, payload, {
            headers: {
                "Authorization": `Bearer ${ACCESS_TOKEN_NAME()}`, // Replace with your token
                "Content-Type": "application/json",
            },
        })
        .then(function (response) {
            if (response.status === 200) {                   
                navigate("/admin/listRole");
            } else {
                // Handle other status codes if needed
            }
        })
        .catch(function (error) {
            console.error(error); // Log the error
        });
    };
    
    return (
        <>
            <div>
                <Header />
                <div className="main-container">
                    <AdminNavbar />
                    {/* <Adminmain /> */}
                    <div className="main">

                        <div className="report-container">
                            <div className="report-header">
                                <h1 className="recent-Articles">Edit Role</h1>
                            </div>

                            <div className="report-body">
                                <div className="user-form">
                                    <form onSubmit={handelSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">
                                                Role Name
                                            </label>
                                            <input type="text" className="form-control"
                                                id="role_name" name="role_name" value={role} onChange={(e) => setRole(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label htmlFor="email" className="form-label">
                                                Status
                                            </label>
                                            <select className="form-control" id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                                                <option value="">Select Status</option>
                                                <option value="1">Active</option>
                                                <option value="0">In Active</option>
                                            </select>
                                        </div>
                                        <button type="submit" className="btn btn-primary submit-btn">
                                            EDIT
                                        </button>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

export default EditRole;