import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../assets/css/bootstrap.min.css";
import "../assets/css/fontawesome-all.min.css";
import "../assets/css/slick.css";
import "../assets/css/line-awesome.min.css";
import "../assets/css/main.css";
import "../assets/css/responsive.css";

import DropDownIcon from "../assets/images/icons/drop-down-svgrepo-com 1.svg";
import EditIcon from "../assets/images/icons/edit-4-svgrepo-com 1.svg";
import EditActiveIcon from "../assets/images/icons/edit-4-svgrepo-com_active.svg";
import CourseIcon from "../assets/images/icons/course-diary-svgrepo-com.svg";
import CourseActiveIcon from "../assets/images/icons/course-diary-svgrepo-com_active.svg";
import UploadIcon from "../assets/images/icons/upload-square-svgrepo-com 1.svg";
import UploadActiveIcon from "../assets/images/icons/upload-square-svgrepo-com_active.svg";
import AssignmentIcon from "../assets/images/icons/return-order-svgrepo-com 1.svg";
import AssignmentActiveIcon from "../assets/images/icons/return-order-svgrepo-com_active.svg";
import CertificateIcon from "../assets/images/icons/certificate-check-svgrepo-com 1.svg";
import CertificateActiveIcon from "../assets/images/icons/certificate-check-svgrepo-com_active.svg";
import MessageIcon from "../assets/images/icons/email-envelope-letter-mail-message-svgrepo-com 1.svg";
import MessageActiveIcon from "../assets/images/icons/email-envelope-letter-mail_active.svg";
import HistoryIconActive from "../assets/images/icons/history-svgrepo-com_active.svg";
import HistoryIcon from "../assets/images/icons/history-svgrepo-com 1.svg";

const Sidebar = () => {
    const navigate = useNavigate();

    const handleLogOutNavigate = () => {
        localStorage.removeItem("ACCESS_TOKEN_NAME"); // Remove token
        navigate("/logOut"); // Navigate to logout route
        window.location.reload(); // Refresh the page
      };

    return (
        <div className="col-sm-3 dashboard_right d-none d-xl-block cust_row" style={{ background: 'white' }}>
            <div className="padding_100" onClick={()=>navigate("/")}>
                <img src={DropDownIcon} alt="dropdown-icon" />
                <span className="ms-2">Back</span>
            </div>

            <div className="dashboard-sidebar">
                <button type="button" className="dashboard-sidebar__close d-lg-none d-flex">
                    <i className="las la-times"></i>
                </button>
                <div className="dashboard-sidebar__inner">
                    <ul className="sidebar-list">
                        <li className="sidebar-list__item activePage">
                            <a href="dashboard.html" className="sidebar-list__link">
                                <span className="sidebar-list__icon">
                                    <img src={EditIcon} alt="edit-icon" className="icon" />
                                    <img src={EditActiveIcon} alt="edit-active-icon" className="icon icon-active" />
                                </span>
                                <span className="text">Edit Profile</span>
                            </a>
                        </li>
                        <li className="sidebar-list__item">
                            <a href="/TeacherChenal" className="sidebar-list__link">
                                <span className="sidebar-list__icon">
                                    <img src={CourseIcon} alt="course-icon" className="icon" />
                                    <img src={CourseActiveIcon} alt="course-active-icon" className="icon icon-active" />
                                </span>
                                <span className="text">Your Channel</span>
                            </a>
                        </li>
                     
                        <li className="sidebar-list__item">
                            <a href="/TeacherOrderAssign" className="sidebar-list__link">
                                <span className="sidebar-list__icon">
                                    <img src={AssignmentIcon} alt="assignment-icon" className="icon" />
                                    <img src={AssignmentActiveIcon} alt="assignment-active-icon" className="icon icon-active" />
                                </span>
                                <span className="text">Assignments Order</span>
                            </a>
                        </li>
                      
                        <li className="sidebar-list__item">
                            <a href="/DashboardChat" className="sidebar-list__link">
                                <span className="sidebar-list__icon">
                                    <img src={MessageIcon} alt="message-icon" className="icon" />
                                    <img src={MessageActiveIcon} alt="message-active-icon" className="icon icon-active" />
                                </span>
                                <span className="text">Messages</span>
                            </a>
                        </li>
                        <li className="sidebar-list__item">
                            <a href="/TeacherHistory" className="sidebar-list__link">
                                <span className="sidebar-list__icon">
                                    <img src={HistoryIcon} alt="history-icon" className="icon" />
                                    <img src={HistoryIconActive} alt="history-active-icon" className="icon icon-active" />
                                </span>
                                <span className="text">History</span>
                            </a>
                        </li>
                        {/* {localStorage.getItem("ACCESS_TOKEN_NAME") && (
                  <button
                    onClick={handleLogOutNavigate}
                    className="btn btn-main pill sign_btn px-sm-4"
                  >
                    Logout
                  </button>
                )} */}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
