// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-container {
    display: flex;
    gap: 20px;
    justify-content: start;
    align-items: center;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #666;
    margin-top: 20px;
}

.tab-item {
    position: relative;
    cursor: pointer;
    padding: 5px 10px;
    transition: color 0.3s ease;
}

.tab-item.active {
    color: #ff6600; /* Active tab color */
    font-weight: bold;
}

.tab-item:hover {
    color: #ff6600; /* Hover effect */
}

.underline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ff6600; /* Underline color */
    border-radius: 2px;
    margin-top: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/css/Tabs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,2BAA2B;AAC/B;;AAEA;IACI,cAAc,EAAE,qBAAqB;IACrC,iBAAiB;AACrB;;AAEA;IACI,cAAc,EAAE,iBAAiB;AACrC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,WAAW;IACX,yBAAyB,EAAE,oBAAoB;IAC/C,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".tabs-container {\n    display: flex;\n    gap: 20px;\n    justify-content: start;\n    align-items: center;\n    font-family: Arial, sans-serif;\n    font-size: 16px;\n    color: #666;\n    margin-top: 20px;\n}\n\n.tab-item {\n    position: relative;\n    cursor: pointer;\n    padding: 5px 10px;\n    transition: color 0.3s ease;\n}\n\n.tab-item.active {\n    color: #ff6600; /* Active tab color */\n    font-weight: bold;\n}\n\n.tab-item:hover {\n    color: #ff6600; /* Hover effect */\n}\n\n.underline {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 2px;\n    background-color: #ff6600; /* Underline color */\n    border-radius: 2px;\n    margin-top: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
