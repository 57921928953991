import React, { useEffect, useState } from 'react';
import { Footer, Navbar } from "../components";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME,Image_Base_Url,ACCESS_Role_ID,ACCESS_Log_In_User_Id } from '../constants/apiConstants';
import '../assets/css/bootstrap.min.css';
import '../assets/css/fontawesome-all.min.css';
import '../assets/css/slick.css';
import '../assets/css/line-awesome.min.css';
import '../assets/css/main.css';
import createImage from '../../src/assets/images/all/create.png';
import { useLocation } from 'react-router-dom';

const RegisterOtp = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState('');
      const [location1, setLocation1] = useState({
            latitude: null,
            longitude: null,
            address: null,
            error: null,
        });
    const { userName, useremail, userPassword, usermobile, position } = location.state || {}; // Handle the case where state might be undefined
    const [otp, setOtp] = useState(new Array(6).fill(''));


    const handleChange = (element, index) => {
        if (isNaN(element.value)) return; // Ensure it's a number

        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Move to the next input automatically if the current one is filled
        if (element.value && element.nextSibling) {
            element.nextSibling.focus();
        }
    };

       useEffect(() => {
            const getUserLocation = () => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const { latitude, longitude } = position.coords;
    
                            setLocation1((prevState) => ({
                                ...prevState,
                                latitude,
                                longitude,
                                error: null,
                            }));
    
                            // Fetch location name using Geocoding API
                            fetch(
                                `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=aaf3d4ea0ca84ea5ad65a3fb35966c0d`
                            )
                                .then((response) => response.json())
                                .then((data) => {
                                    console.log("data will be display here",data)
                                    if (data && data.results && data.results[0]) {
                                        setLocation1((prevState) => ({
                                            ...prevState,
                                            address: data.results[0].formatted,
                                        }));
                                    }
                                })
                                .catch((error) => {
                                    setLocation1((prevState) => ({
                                        ...prevState,
                                        error: "Failed to fetch location name.",
                                    }));
                                });
                        },
                        (error) => {
                            setLocation1((prevState) => ({
                                ...prevState,
                                latitude: null,
                                longitude: null,
                                error: error.message,
                            }));
                        }
                    );
                } else {
                    setLocation1((prevState) => ({
                        ...prevState,
                        latitude: null,
                        longitude: null,
                        error: "Geolocation is not supported by this browser.",
                    }));
                }
            };
    
            getUserLocation();
        }, []);

    const handleKeyDown = (e, index) => {
        // If the backspace key is pressed and the input is empty
        if (e.key === 'Backspace' && otp[index] === '') {
            // Focus on the previous input box
            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }
    };

    const handleSubmit = (e) => {
        // alert(JSON.stringify(location1.latitude))
        
        e.preventDefault();
        // Handle OTP submission here
        console.log("Entered OTP:", otp.join(''));
        var otps = otp.join('');
        const payload = {
            "name": userName,
            "email": useremail,
            "password": userPassword,
            "roleId": position,
            "mobile_no": usermobile,
            "code": otps,
            "latitude":location1.latitude,
            "longitude":location1.longitude,
            "address":location1.address
        }
        // console.log("payload information",payload)
        // return false
        setErrorMessage('');
        //console.log(otp);
        axios.post(API_BASE_URL + '/verify-phone', payload)
            .then(function (response) {
                console.log(response);
                console.log(response.data.message);
                if (response.data.code == 200) {
                    redirectToLogin();
                }
                else {
                    setErrorMessage(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
const redirectToLogin = () => {
        navigate("/login");
    }
    
    const inputStyle = {
        width: '80px',
        height: '80px',
        margin: '0 5px',
        fontSize: '24px',
        textAlign: 'center',
        borderRadius: '40px', 
        border: '1px solid #D9D9D9',
        outline: 'none',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        backgroundColor: '#f3f3f3',
    };

    const inputFocusStyle = {
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
        borderColor: '#6A67CE',
        backgroundColor:'#6A67CE',
    };

    return (
        <section className="account d-flex flex-wrap" style={{ height: '100vh' }}>
            {/* Left side image section */}
            <div className="account__left position-relative d-md-flex">
                <img src={createImage} alt="Create Account" className="account__img" />
            </div>

            {/* Right side form section */}
            <div className="account__right flx-align d-flex justify-content-center align-items-start align-items-md-center">
                <div className="account-content">
                    <h2 className="login-heading font-heading text-end mb-4">Create an Account</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="row gy-3 d-flex justify-content-center align-items-center account__right_box">
                            {/* Verification Code Inputs */}
                            <div className="col-12">
                                <label htmlFor="verification-code" className="form-label mb-2 font-18 font-heading fw-600">
                                    Enter Verification Code
                                </label>
                                <p className="mb-3">
                                    You might have received an OTP on your email id or mobile phone
                                </p>
                                <div className="d-flex">
                                    {otp.map((value, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            className="spl_input common-input common-input--bg verification-code"
                                            maxLength="1"
                                            value={value}
                                            onChange={(e) => handleChange(e.target, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                            onFocus={(e) => e.target.select()}
                                            style={{
                                                ...inputStyle,
                                                ...(value !== '' && inputFocusStyle),
                                            }}
                                            required
                                        />
                                    ))}
                                </div>
                                {errorMessage && (
                    <span className="error-message">{errorMessage}</span>)}
                            </div>
                            
                            <div className="col-12 mt-4">
                                <button type="submit" className="btn btN_login btn-main btn-lg w-100 pill">Create Account</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default RegisterOtp;
