import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Import images
import DropDownIcon from "../../assets/images/icons/drop-down-svgrepo-com 1.svg";
import EditIcon from "../../assets/images/icons/edit-4-svgrepo-com 1.svg";
import EditImage from "../../assets/images/icons/edit-1-svgrepo-com.svg";
import teacherImage from '../../assets/images/icons/teacehr_+.svg';
import plusImage from '../../assets/images/icons/white+.svg'
import downArrowImage from '../../assets/images/icons/down-arrow-5-svgrepo-com (2) 4.svg'
import UpImage from '../../assets/images/icons/down-arrow-5-svgrepo-com (2) 3.svg'
import EditActiveIcon from "../../assets/images/icons/edit-4-svgrepo-com_active.svg";
import CourseIcon from "../../assets/images/icons/course-diary-svgrepo-com.svg";
import CourseActiveIcon from "../../assets/images/icons/course-diary-svgrepo-com_active.svg";
import UploadIcon from "../../assets/images/icons/upload-square-svgrepo-com 1.svg";
import UploadActiveIcon from "../../assets/images/icons/upload-square-svgrepo-com_active.svg";
import AssignmentIcon from "../../assets/images/icons/return-order-svgrepo-com 1.svg";
import AssignmentActiveIcon from "../../assets/images/icons/return-order-svgrepo-com_active.svg";
import CertificateIcon from "../../assets/images/icons/certificate-check-svgrepo-com 1.svg";
import CertificateActiveIcon from "../../assets/images/icons/certificate-check-svgrepo-com_active.svg";
import MessageIcon from "../../assets/images/icons/email-envelope-letter-mail-message-svgrepo-com 1.svg";
import MessageActiveIcon from "../../assets/images/icons/email-envelope-letter-mail_active.svg";
import NotificationIcon from "../../assets/images/icons/notification-bing-svgrepo-com 1.svg";
import ManageEditIcon from "../../assets/images/icons/manage_edit.svg";
import ProfileImage from "../../assets/images/all/profiel.png";
import SearchIcon from "../../assets/images/all/search.svg";
import HistoryIconActive from "../../assets/images/icons/history-svgrepo-com_active.svg";
import HistoryIcon from "../../assets/images/icons/history-svgrepo-com 1.svg";
import FeedImage from "../../assets/images/all/fedd.png";
import { Footer } from "../../components";
import { searchIcon } from "@progress/kendo-svg-icons";
import TeacherSideBar from '../../components/TeacherSidebar'
import CommanStudentTeacherHeader from "../../components/CommanStudentTeacherHeader";

const TeacherCourse = () => {
  const navigate = useNavigate();
  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
    <CommanStudentTeacherHeader/>

      {/* Main Content */}
      <div className="container">
        <div className="row">
          {/* Sidebar */}
          {/* <div className="col-sm-3 dashboard_right d-none d-xl-block">
            <div className="padding_100" onClick={()=>navigate('/DashboardChat')}>
              <img src={DropDownIcon} alt="Back" />
              <span className="ms-2">Back</span>
            </div>
            <div className="dashboard-sidebar">
              <button type="button" className="dashboard-sidebar__close d-lg-none d-flex">
                <i className="las la-times"></i>
              </button>
              <div className="dashboard-sidebar__inner">
                <ul className="sidebar-list">
                  <li className="sidebar-list__item activePage">
                    <a href="dashboard.html" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={EditIcon} alt="Edit Profile" className="icon" />
                        <img src={EditActiveIcon} alt="Edit Profile Active" className="icon icon-active" />
                      </span>
                      <span className="text">Edit Profile</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/StudentProfileCourse" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={CourseIcon} alt="Courses" className="icon" />
                        <img src={CourseActiveIcon} alt="Courses Active" className="icon icon-active" />
                      </span>
                      <span className="text">Courses</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/DashboardPost" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={UploadIcon} alt="Posts" className="icon" />
                        <img src={UploadActiveIcon} alt="Posts Active" className="icon icon-active" />
                      </span>
                      <span className="text">Posts</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/StudentOrderAssign" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={AssignmentIcon} alt="Assignments Order" className="icon" />
                        <img src={AssignmentActiveIcon} alt="Assignments Order Active" className="icon icon-active" />
                      </span>
                      <span className="text">Assignments Order</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/StudentCertificate" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={CertificateIcon} alt="Certificates" className="icon" />
                        <img src={CertificateActiveIcon} alt="Certificates Active" className="icon icon-active" />
                      </span>
                      <span className="text">Certificates</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/DashboardChat" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={MessageIcon} alt="Messages" className="icon" />
                        <img src={MessageActiveIcon} alt="Messages Active" className="icon icon-active" />
                      </span>
                      <span className="text">Messages</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="dashboard-profile.html" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={HistoryIcon} alt="History" className="icon" />
                        <img src={HistoryIconActive} alt="History Active" className="icon icon-active" />
                      </span>
                      <span className="text">History</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <TeacherSideBar/>

      

          {/* Main Content Area */}  
           <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5 ">
                <div className="row mt-5">
                    <div className="col-md-9 ps-0">
                        <div className="search-box dashboard_search">
                            <input type="text"
                                className="common-input common-input--lg dashboard_search_input pill shadow-sm auto-suggestion-input"
                                placeholder="Search"/>
                            <button type="submit" className="btn btn-main spl_burron  border-0"><img
                                    src={SearchIcon} alt=""/></button>
                        </div>
                    </div>
                    <div className="col-md-2 text-xl-center  mt-3">
                        <img src={NotificationIcon} alt=""/>
                    </div>
                    <div className="col-md-1">
                        <div className="dashboard_profile ms-auto">
                            <img src={ProfileImage} alt=""/>
                        </div>
                    </div>
                </div>
            
                <div className="row px-0 py-3">
                    <div className="col-sm-12">
                        <h2 className="learm-tilte">Course </h2>
                        <p>Start putting together your course by creating sections, lectures and practice activities
                            (quizzes, coding exercises and assignments).</p>
                        <p>Use your course outline to structure your content and label your sections and lectures
                            clearly. If you're intending to offer your course for free, the total length of video
                            content must be less than 2 hours.</p>
                    </div>
                </div>
                <div className="row p-0">
                    <div className="dashboard_card p-0">
                     
                        <div className="row px-0 px-sm-3 mt-4">
                            <div className="col-sm-12">
                                <div className="dashboard_card p-4">
                                    <div className="">
                                        <span>Lecture 1: Introduction</span>
                                        <div className="position-relative">
                                            <select className="common-input common-input--md dash_borad_input_1" id="lectureContent">
                                                <option selected disabled>Content</option>
                                                <option value="1">Video</option>
                                                <option value="2">Quiz</option>
                                                <option value="3">Assignment</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <button type="button" className="btn btn-secondary text-white px-4 py-2 d-flex align-items-center mt-3">
                                        <img src={plusImage} alt="" style={{ marginRight: '8px' }}/>
                                        Curriculum Item
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                
                    <div className="row px-0 px-sm-3 mt-4">
                        <div className="col-sm-12 text-start">
                            <button type="button"
                                className="btn btn_cousre bg-main-btn text-white py-2 px-4 fw-500 font-16">Add New
                                Section</button>
                        </div>
                    </div>
                </div>

                <div className="row mb-5 mt-3">
                    <div className="col-sm-12 d-flex justify-content-between">
                        <span className="learm-tilte2 font-16"><img src={downArrowImage} alt="" /> Previous</span>
                        <span className="learm-tilte2 font-16">Next <img src={UpImage} alt="" /></span>
                    </div>
                </div>

            </div>
         
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TeacherCourse;
