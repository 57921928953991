import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Footer, Navbar } from "../../components";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url, ACCESS_Role_ID, ACCESS_Log_In_User_Id } from '../../constants/apiConstants';
import { formatDate, calculateDifference, dateMonthYears, renderStars } from "../Utils";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/SliderStyles.css";// Custom styles
import "../../css/Tabs.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";
import SearchIcon from "../../assets/images/all/search.svg";

import StarIcon from "../../assets/images/all/star-svgrepo-com.svg";
import LectureImage from "../../assets/images/all/leachure.png";
import { debounce } from 'lodash'; // Optional for debouncing
import shapeImage from "../../assets/images/all/shape_1.png"
import shapeImage1 from "../../assets/images/icons/Unions.svg"
import shapeImage2 from "../../assets/images/icons/Union-2.svg"
import SliderImagess from "../../assets/images/course/image-1.png"
import SliderImagess2 from "../../assets/images/course/image-2.png"

const CustomArrow = ({ className, style, onClick, isLeft }) => {
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'white',
                color: 'black',
                borderRadius: '50%',
                position: 'absolute',
                top: '50%', // Center vertically
                transform: 'translateY(-50%)', // Adjust for arrow size
                [isLeft ? 'left' : 'right']: '10px', // Adjust horizontal position
                zIndex: 2, // Ensure it's above the image
                width: '40px',
                height: '40px',
                cursor: 'pointer',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={onClick}
        >
            {isLeft ? '<' : '>'}
        </div>
    );
};

const TeacherDetail = (props) => {
    const { id } = useParams();
    console.log("45454646464", id)
    const [ids, setIds] = useState(id);
    console.log(ids);
    const [mainSlider, setMainSlider] = useState(null);
    const [thumbSlider, setThumbSlider] = useState(null);
    const [activeTab, setActiveTab] = useState("All");

    const [profileimg, setProfileimg] = useState(""); // State to hold roles data
    const [bioInfo, setBioInfo] = useState({
        bio_info: "",
    });
    const [profileData, setProfileData] = useState({
        id: "",
        full_name: "",
        email_id: "",
        username: "",
        location: "",
    });

    const [otherInfoDetails, setOtherInfoDetails] = useState({
        no_of_students_studyind: "",
        year_of_establishment: "",
        timings: "",
        hostels: "",
        approved_by: "",
        affiliation: "",
        gender_composition: "",
    });

    const [streamDetails1, setStreamDetails1] = useState([]);
    const [facilitydata, setFacilityData] = useState([])
    const [instituteFollower, setInstituteFollower] = useState([]);
    const [rating, setRating] = useState(0);
      const [instituteId, setinstituteId] = useState([]);
      const [averageRatings, setAverageRatings] = useState([]);
    const tabs = ["All", "Management", "Technology", "Design"];
    const location = useLocation();
    const { message } = location.state || {};
    console.log("message //////", message)
    const sliderImages = [
        { src: SliderImagess, alt: "Woxsen University 1" },
        { src: SliderImagess2, alt: "Woxsen University 2" },
        { src: SliderImagess, alt: "Woxsen University 3" },
        { src: SliderImagess2, alt: "Woxsen University 4" },
        { src: SliderImagess, alt: "Woxsen University 5" },
    ];



    // Main slider settings
    const mainSliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        asNavFor: thumbSlider,
        prevArrow: <CustomArrow isLeft={true} />,
        nextArrow: <CustomArrow isLeft={false} />,
    };



    const thumbnailSliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        focusOnSelect: true, // Enable click to select thumbnail
        asNavFor: mainSlider, // Synchronize with main slider
        arrows: false, // Hide arrows for thumbnail slider
    };

    useEffect(() => {
        fetchProfile();

        fetchBioInfoDetails();
        fetchPersonalInfoDetails();
        fetchOtherInfoDetails();
        fetchStreamDetails();
        fetchFacilityDetails();
        // GetinstituteManagerPersonalInfo()
    }, []);
    
    const fetchProfile = async () => {
        // alert(ids)
        axios.get(`${API_BASE_URL}/getprofileImgDetails?user_id=${ids}`)
            .then(function (response) {
                // console.log(response.data.code);
                if (response.data.code == 200) {
                    console.log("4454444544545======", response.data.data[0]);
                    setProfileimg(response.data.data[0]);
                }
            })
            .catch(function (error) {
                console.log("1111121212122112", error);
            });
    };



    const fetchBioInfoDetails = async () => {

        axios.get(`${API_BASE_URL}/getbioInfoDetails?user_id=${ids}`)
            .then(function (response) {
                //console.log(response);
                if (response.data.code == 200) {
                    // console.log(response.data.data[0]);
                    //setbioInfoDetails(response.data.data[0]);
                    setBioInfo({
                        id: response.data.data[0]["id"],
                        bio_info: response.data.data[0]['bio_info'],
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const fetchPersonalInfoDetails = async () => {

        axios.get(`${API_BASE_URL}/getpersonalInfoDetails?user_id=${ids}`)
            .then(function (response) {
                console.log(response);
                if (response.data.code == 200) {
                    console.log(response.data.data[0]);
                    //setpersonalInfoDetails(response.data.data[0]);
                    setProfileData({
                        id: response.data.data[0]["id"],
                        full_name: response.data.data[0]["full_name"],
                        email_id: response.data.data[0]["email_id"],
                        username: response.data.data[0]["username"],
                        location: response.data.data[0]["location"],
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    useEffect(() => {
        const fetchInstituteFollowers = async () => {
        axios.get(`${API_BASE_URL}/getInstitutefollowers?institute_id=${profileData?.id}`)
            .then(function (response) {
                if (response.status == 200) {
                    setInstituteFollower(response.data);
                }
            })
            .catch(function (error) {
                console.log("1111121212122112", error);
            });
        };
    
        fetchInstituteFollowers();
      }, [profileData]); // Run the effect when cartItems changes
    
    const fetchOtherInfoDetails = async () => {

        axios.get(`${API_BASE_URL}/getInstitutesOtherDataById/${ids}`)
            .then(function (response) {
                if (response.data.code == 200) {
                    setOtherInfoDetails({
                        id: response.data.data[0]["id"],
                        no_of_students_studyind: response.data.data[0]["no_of_students_studyind"],
                        year_of_establishment: response.data.data[0]["year_of_establishment"],
                        timings: response.data.data[0]["timings"],
                        hostels: response.data.data[0]["hostels"],
                        approved_by: response.data.data[0]["approved_by"],
                        affiliation: response.data.data[0]["affiliation"],
                        gender_composition: response.data.data[0]["gender_composition"],
                    });

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const fetchStreamDetails = async () => {
        try {
            const user_id = ACCESS_Log_In_User_Id();
            const response = await axios.get(`${API_BASE_URL}/getInstitutesStreamById/${ids}`);

            if (response.data.code === 200) {
                // Directly use response data instead of mapping unnecessarily
                const data = response.data.data || []; // Default to an empty array if `data` is undefined
                setStreamDetails1(data); // Assuming `setStreamDetails1` expects an array
            } else {
                console.log("Error: Unexpected response code", response.data.code);
            }
        } catch (error) {
            console.error("Error fetching stream details:", error);
        }
    };
    const fetchFacilityDetails = async () => {
        try {
            const user_id = ACCESS_Log_In_User_Id();
            const response = await axios.get(`${API_BASE_URL}/getInstitutesFacilitiesById/${ids}`);
            if (response.data.code === 200) {
                // Directly use response data instead of mapping unnecessarily
                const data = response.data.data || []; // Default to an empty array if `data` is undefined
                setFacilityData(data); // Assuming `setStreamDetails1` expects an array
            } else {
                console.log("Error: Unexpected response code", response.data.code);
            }
        } catch (error) {
            console.error("Error fetching stream details:", error);
        }
    }


    const GetinstituteManagerPersonalInfo = async () => {
        try {
            const user_id = ACCESS_Log_In_User_Id();
            const response = await axios.get(`${API_BASE_URL}/getinstituteManagerPersonalInfo/${user_id}`);

            if (response.data.code === 200) {
                setManagers(response.data.data);
            } else {
                // console.log("Error: Unexpected response code", response.data.code);
            }
        } catch (error) {
            console.error("Error fetching stream details:77777777777777777", error);
        }
    };
// Rating start
const fetchRatings = async () => {
    
    try {
     
        promises =  axios.get(`${API_BASE_URL}/ratings/institute/${profileData?.id}`)
        console.log(promises);
      const responses = await Promise.all(promises);
      console.log(responses);
      const courserating = responses?.map(response => response.data.data);
      const averageRatingsss = courserating?.map((ratingsArray) => {
        return calculateAverageRating(ratingsArray);
      });

      //setRatings(courserating);
      setAverageRatings(averageRatingsss);
      renderStars(averageRatingsss)
    } catch (err) {
      
    } finally {
      
    }
  };
  useEffect(() => {
    fetchRatings();
  }, [profileData]);

  const calculateAverageRating = (ratingsArray) => {
    const totalRating = ratingsArray.reduce((sum, ratingObj) => sum + ratingObj.rating, 0);
    return (totalRating / ratingsArray.length).toFixed(2); // Return average rounded to 2 decimal places

  };

  // Rating end
    return (
        <div className="course-page">
            {/* Header */}

            <img className="shape_top d-lg-block d-none" src={shapeImage} alt="" />
            <img className="shape_top_6 d-xl-block d-none" src={shapeImage1} alt="" />

            <Navbar />

            <section class="hero section-bg z-index-1">
                <div class="container py-5 container-two">
                    <div class="row align-items-center gy-sm-5 gy-4">
                        <div class="col-lg-12 order-1 order-lg-0">
                            <div class="position-relative">
                                <div class="search-box">
                                    <input type="text"
                                        class="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                                        placeholder="Search" />
                                    <button type="submit" class="btn btn-main spl_burron btn-icon icon border-0"><img
                                        src={SearchIcon} alt="" /></button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="pb-5">
                {/* <img class="shape_top_7 d-lg-block d-none" src={shapeImage2} alt=""/> */}
                <div class="container cours_deataisl">
                    <div class="row justify-content-center">

                        <div class="col-lg-10 col-sm-10 col-md-12">

                            <div class="row mb-5">

                                <div class="col-sm-3 col-md-4 col-lg-4 col-xl-2">
                                    <div class="boost_profile text-center mx-auto mx-lg-0">
                                        <img src={profileimg && profileimg.profile_image_path ? `${Image_Base_Url}${profileimg.profile_image_path}` : null} alt="" />
                                    </div>

                                </div>

                                <div class="col-sm-9 col-md-8 col-lg-8 col-xl-10">

                                    <div class="row ps-3 ps-lg-5">
                                        <div class="col-sm-12 order-lg-0">
                                            <div class="d-flex justify-content-center justify-content-lg-between">
                                                <div class="text-center text-lg-start">
                                                    <h4 class="learm-tilte mb-0">{profileData?.full_name}</h4>
                                                    <span class="learm-tilte2">{instituteFollower} Followers</span>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-sm-12 order-lg-2 mt-3 mt-lg-0">
                                            <p>{bioInfo.bio_info}</p>
                                        </div>
                                    </div>


                                </div>
                            </div>


                            <div className="course-details">
                                <div className="position-relative">
                                    <div className="container mt-5">
                                        <div className="main-slider mb-4">
                                            <Slider {...mainSliderSettings} ref={(slider) => setMainSlider(slider)}>
                                                <div>
                                                    <img src={SliderImagess} alt="Woxsen University" />
                                                </div>
                                                <div>
                                                    <img src={SliderImagess2} alt="Woxsen University" />
                                                </div>
                                                <div>
                                                    <img src={SliderImagess} alt="Woxsen University" />
                                                </div>
                                                <div>
                                                    <img src={SliderImagess2} alt="Woxsen University" />
                                                </div>
                                                <div>
                                                    <img src={SliderImagess} alt="Woxsen University" />
                                                </div>
                                            </Slider>
                                        </div>
                                        <div className="thumbnail-slider">
                                            <Slider {...thumbnailSliderSettings} ref={(slider) => setThumbSlider(slider)}>
                                                <div>
                                                    <img src={SliderImagess} alt="Thumbnail 1" />
                                                </div>
                                                <div>
                                                    <img src={SliderImagess} alt="Thumbnail 2" />
                                                </div>
                                                <div>
                                                    <img src={SliderImagess2} alt="Thumbnail 3" />
                                                </div>
                                                <div>
                                                    <img src={SliderImagess} alt="Thumbnail 2" />
                                                    <p style={{ position: 'absolute', bottom: "45%" }}>30+ More</p>
                                                </div>
                                                <div>
                                                    <img src={SliderImagess2} alt="Thumbnail 3" />
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div class="row my-4">
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 order-1 order-md-0">

                                    <div class="course-rating d-flex justify-content-between align-items-center text-end">
                                        <div>
                                            <span href="#" class="btn btn_cousre py-2 px-3">4.5</span>
                                            <span class="ms-2">600 Ratings</span>
                                        </div>
                                        <div>
                                            <span href="#" class="btn btn_cousre py-2 call px-3"><img
                                                src="assets/images/icons/call-svgrepo-com 1.svg" alt="" /> Call</span>
                                            <span href="#" class="btn btn_cousre py-2 whatsapp px-3 ms-2"><img
                                                src="assets/images/icons/message-square-list-svgrepo-com 1.svg" alt="" />
                                                WhatasApp</span>
                                        </div>

                                    </div>


                                    <div class="col-sm-12 d-flex flex-column flex-sm-row my-2">
                                        <p class="course-upload me-sm-5">Uploaded: 15 April 2024</p>
                                        <p class="course-language ms-sm-5 text-md-end">Available in: English, Hindi, Telugu,
                                            Tamil</p>

                                    </div>
                                </div>

                            </div>

                            <div class="row my-5">

                                <div class="container course-catogires my-5">

                                    <div className="tabs-container">
                                        {tabs.map((tab, index) => (
                                            <div
                                                key={index}
                                                className={`tab-item ${activeTab === tab ? "active" : ""}`}
                                                onClick={() => setActiveTab(tab)}
                                            >
                                                {tab}
                                                {activeTab === tab && <div className="underline"></div>}
                                            </div>
                                        ))}
                                    </div>




                                    <div class="tab-content mt-5">


                                        {activeTab === "All" && (<div class="tab-pane fade show active" id="all" role="tabpanel">

                                            <div class="row mb-4">

                                                <div class="col-md-4 mb-3">
                                                    <div class="card p-3 border-light shadow-sm">
                                                        <h5 class="learn-title2">Bachelors in Business Management</h5>
                                                        <p>₹18,00,000 / 3 years</p>
                                                        <a href="#" class="learn-title2">view details</a>
                                                    </div>
                                                </div>


                                                <div class="col-md-4 mb-3">
                                                    <div class="card p-3 border-light shadow-sm">
                                                        <h5 class="learn-title2">Masters in Business Management</h5>
                                                        <p>₹13,00,000 / 2 years</p>
                                                        <a href="#" class="learn-title2">view details</a>
                                                    </div>
                                                </div>


                                            </div>


                                            <h4 class="learn-title2">Overview</h4>
                                            <div class="row">

                                                <div class="col-md-4 mb-3">
                                                    <h5>Year of establishment</h5>
                                                    <p class="learn-title2">{otherInfoDetails?.no_of_students_studyind}</p>
                                                </div>


                                                <div class="col-md-4 mb-3">
                                                    <h5>Timings</h5>
                                                    {/* <p><span class="learn-title2">Monday - Friday:</span> 9:30 am - 6:30 pm
                                                    </p> */}
                                                    <p><span class="learn-title2">{otherInfoDetails?.timings}</span>
                                                    </p>
                                                    {/* <p><span>Sat - Sun:</span> closed</p> */}
                                                </div>


                                                <div class="col-md-4 mb-3">
                                                    <h5>Hotels</h5>
                                                    <p class="learn-title2">{otherInfoDetails?.hostels}</p>
                                                </div>


                                                <div class="col-md-4 mb-3">
                                                    <h5>Approved by</h5>
                                                    <p class="learn-title2">{otherInfoDetails?.approved_by}</p>
                                                </div>


                                                <div class="col-md-4 mb-3">
                                                    <h5>Affiliated</h5>
                                                    <p>{otherInfoDetails?.affiliation}</p>
                                                </div>


                                                <div class="col-md-4 mb-3">
                                                    <h5>Gender Composition</h5>
                                                    <p>{otherInfoDetails?.gender_composition}</p>
                                                </div>

                                                <div className="col-md-4 mb-3">
                                                    <h5>Stream</h5>
                                                    {streamDetails1.map((stream, index) => (
                                                        <ul className="learn-title2" key={index}>
                                                            <li>{stream?.stream_name}</li>
                                                            <li>{stream?.fee}</li>
                                                            <li>{stream?.year}</li>
                                                        </ul>
                                                    ))}
                                                </div>



                                                <div class="col-md-4 mb-3">
                                                    <h5>Facilities</h5>
                                                    {facilitydata?.map((stream, index) => <ul class="learn-title2">
                                                        <li> {stream?.facilities_name}</li>
                                                    </ul>)}
                                                </div>

                                            </div>

                                        </div>)}


                                        {/* <div class="tab-pane fade" id="management" role="tabpanel"> */}

                                        {activeTab === "Management" && (<div class="row mb-4">

                                            <div class="col-md-4 mb-3">
                                                <div class="card p-3 border-light shadow-sm">
                                                    <h5>MBA in Finance</h5>
                                                    ₹15,00,000 / 2 years
                                                    <a href="#" class='learn-title2'>view details</a>
                                                </div>
                                            </div>


                                            <div class="col-md-4 mb-3">
                                                <div class="card p-3 border-light shadow-sm">
                                                    MBA in Marketing
                                                    ₹14,50,000 / 2 years
                                                    <a href="#" class='learn-title2'>view details</a>
                                                </div>
                                            </div>

                                        </div>)}



                                        {/* </div> */}


                                        {/* <div class="tab-pane fade" id="technology" role="tabpanel"> */}

                                        {activeTab === "Technology" && (<div class="row mb-4">

                                            <div class="col-md-4 mb-3">
                                                <div class="card p-3 border-light shadow-sm">
                                                    <h5 class="learn-title2">B.Tech in Computer Science</h5>
                                                    <p>₹12,00,000 / 4 years</p>
                                                    <a href="#" class="learn-title2">view details</a>
                                                </div>
                                            </div>


                                            <div class="col-md-4 mb-3">
                                                <div class="card p-3 border-light shadow-sm">
                                                    <h5 class="learn-title2">B.Tech in Information Technology</h5>
                                                    <p>₹11,50,000 / 4 years</p>
                                                    <a href="#" class="learn-title2">view details</a>
                                                </div>
                                            </div>


                                            <div class="col-md-4 mb-3">
                                                <div class="card p-3 border-light shadow-sm">
                                                    <h5 class="learn-title2">M.Tech in Artificial Intelligence</h5>
                                                    <p>₹14,00,000 / 2 years</p>
                                                    <a href="#" class="learn-title2">view details</a>
                                                </div>
                                            </div>

                                        </div>)}


                                        {/* </div> */}

                                        {/* <div class="tab-pane fade" id="technology" role="tabpanel"> */}

                                        {activeTab === "Technology" && (<div class="row mb-4">

                                            <div class="col-md-4 mb-3">
                                                <div class="card p-3 border-light shadow-sm">
                                                    <h5 class="learn-title2">B.Tech in Computer Science</h5>
                                                    <p>₹12,00,000 / 4 years</p>
                                                    <a href="#" class="learn-title2">view details</a>
                                                </div>
                                            </div>


                                            <div class="col-md-4 mb-3">
                                                <div class="card p-3 border-light shadow-sm">
                                                    <h5 class="learn-title2">B.Tech in Information Technology</h5>
                                                    <p>₹11,50,000 / 4 years</p>
                                                    <a href="#" class="learn-title2">view details</a>
                                                </div>
                                            </div>


                                            <div class="col-md-4 mb-3">
                                                <div class="card p-3 border-light shadow-sm">
                                                    <h5 class="learn-title2">M.Tech in Artificial Intelligence</h5>
                                                    <p>₹14,00,000 / 2 years</p>
                                                    <a href="#" class="learn-title2">view details</a>
                                                </div>
                                            </div>

                                        </div>)}

                                        {/* </div> */}


                                        {/* <div class="tab-pane fade" id="design" role="tabpanel"> */}

                                        {activeTab === "Design" && (<div class="row mb-4">

                                            <div class="col-md-4 mb-3">
                                                <div class="card p-3 border-light shadow-sm">
                                                    <h5 class="learn-title2">B.Des in Graphic Design</h5>
                                                    <p>₹10,00,000 / 4 years</p>
                                                    <a href="#" class="learn-title2">view details</a>
                                                </div>
                                            </div>


                                            <div class="col-md-4 mb-3">
                                                <div class="card p-3 border-light shadow-sm">
                                                    <h5 class="learn-title2">B.Des in Interior Design</h5>
                                                    <p>₹9,50,000 / 4 years</p>
                                                    <a href="#" class="learn-title2">view details</a>
                                                </div>
                                            </div>


                                            <div class="col-md-4 mb-3">
                                                <div class="card p-3 border-light shadow-sm">
                                                    <h5 class="learn-title2">M.Des in Fashion Design</h5>
                                                    <p>₹13,00,000 / 2 years</p>
                                                    <a href="#" class="learn-title2">view details</a>
                                                </div>
                                            </div>


                                            <div class="col-md-4 mb-3">
                                                <div class="card p-3 border-light shadow-sm">
                                                    <h5 class="learn-title2">B.Des in Product Design</h5>
                                                    ₹10,50,000 / 4 years
                                                    <a href="#" class='learn-title2'>view details</a>
                                                </div>
                                            </div>


                                            <div class='col-md-4 mb-3'>
                                                M.Des in Industrial Design
                                                ₹12,00,000 / 2 years
                                                view details
                                            </div>

                                        </div>)}



                                        {/* </div> */}


                                    </div>

                                </div>


                            </div>




                            <div class="row my-5">

                                <h4 class="course-title course-titles mb-4">Similar Colleges</h4>


                                <div class="col-sm-3 mb-3">
                                    <div class="card spl_cards p-0">
                                        <div class="card-body">

                                            <img src={SliderImagess2} alt="How to Solve Physics Problems"
                                                class="img-cover w-100 h-100 rounded-start" />
                                            <h4 class="other_course-title font-24 mt-3">How to Solve Physics Problems</h4>



                                            <div class="row">


                                                <p class="course-description text-muted">
                                                    Simply dummy text of the printing and typesetting industry. Lorem
                                                    Ipsum has been the industry's standard dummy text.
                                                </p>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 mb-3">
                                    <div class="card spl_cards p-0">
                                        <div class="card-body">

                                            <img src={SliderImagess2} alt="How to Solve Physics Problems"
                                                class="img-cover w-100 h-100 rounded-start" />
                                            <h4 class="other_course-title font-24 mt-3">How to Solve Physics Problems</h4>



                                            <div class="row">


                                                <p class="course-description text-muted">
                                                    Simply dummy text of the printing and typesetting industry. Lorem
                                                    Ipsum has been the industry's standard dummy text.
                                                </p>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 mb-3">
                                    <div class="card spl_cards p-0">
                                        <div class="card-body">

                                            <img src={SliderImagess2} alt="How to Solve Physics Problems"
                                                class="img-cover w-100 h-100 rounded-start" />
                                            <h4 class="other_course-title font-24 mt-3">How to Solve Physics Problems</h4>



                                            <div class="row">


                                                <p class="course-description text-muted">
                                                    Simply dummy text of the printing and typesetting industry. Lorem
                                                    Ipsum has been the industry's standard dummy text.
                                                </p>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 mb-3">
                                    <div class="card spl_cards p-0">
                                        <div class="card-body">

                                            <img src={SliderImagess2} alt="How to Solve Physics Problems"
                                                class="img-cover w-100 h-100 rounded-start" />
                                            <h4 class="other_course-title font-24 mt-3">How to Solve Physics Problems</h4>



                                            <div class="row">


                                                <p class="course-description text-muted">
                                                    Simply dummy text of the printing and typesetting industry. Lorem
                                                    Ipsum has been the industry's standard dummy text.
                                                </p>

                                            </div>


                                        </div>
                                    </div>
                                </div>




                                <p class=" text-center mt-3 fw-500">View More</p>

                            </div>

                        </div>



                    </div>
                </div>


                <img class="shape_top_9 d-lg-block d-none" src="assets/images/icons/Union.svg" alt="" />


            </section>


            {/* Footer */}
            <Footer />
        </div>
    );
};

export default TeacherDetail;
