import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Import images

import FeedImage from "../../assets/images/all/fedd.png";
import { Footer } from "../../components";
import { searchIcon } from "@progress/kendo-svg-icons";
import TeacherSideBar from '../../components/TeacherSidebar'
import GlobalSearch from "../../components/GlobalSearch";
import CommanStudentTeacherHeader from "../../components/CommanStudentTeacherHeader";


const TeacherHistory = () => {
  const navigate = useNavigate();
  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
    <CommanStudentTeacherHeader/>
      

      {/* Main Content */}
      <div className="container">
        <div className="row">
          {/* Sidebar */}
          <TeacherSideBar/>

          {/* Main Content Area */}
          <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5 ">
                <GlobalSearch/>


                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <p className="learm-tilte my-3">History</p>
                    </div>
                </div>

                <div className="col-sm-12 order-lg-0 mb-5">
                    <div className="d-flex justify-content-center justify-content-lg-between">
                        <div className="text-center text-lg-start">
                            <h2 className="profile_text_2 mb-0">Today</h2>
                        </div>
                        <div className="text-end psotion_absoltu ">
                            <span className="learm-tilte2">View All</span>
                        </div>

                    </div>
                </div>

                <div className="row gy-3 p-0 mb-5">

                  
                    <div className="col-sm-4">
                        <div className="dashboard_card p-3">
                            <div className="feed_imgs" style={{ height: '140px' }}>
                                <img className="rounded-3" src={FeedImage} alt=""/>
                            </div>
                            <span className="course_video_time mt-2">Atomic & Nuclear Particle
                                Physics</span>
                            <span className="dashboard_subitel">Instructor: Alok Jain</span>
                        </div>
                    </div>
                 
                    <div className="col-sm-4">
                        <div className="dashboard_card p-3">
                            <div className="feed_imgs" style={{ height: '140px' }}>
                                <img className="rounded-3" src={FeedImage} alt=""/>
                            </div>
                            <span className="course_video_time mt-2">Atomic & Nuclear Particle
                                Physics</span>
                            <span className="dashboard_subitel">Instructor: Alok Jain</span>
                        </div>
                    </div>
         
                    <div className="col-sm-4">
                        <div className="dashboard_card p-3">
                            <div className="feed_imgs" style={{ height: '140px' }}>
                                <img className="rounded-3" src={FeedImage} alt=""/>
                            </div>
                            <span className="course_video_time mt-2">Atomic & Nuclear Particle
                                Physics</span>
                            <span className="dashboard_subitel">Instructor: Alok Jain</span>
                        </div>
                    </div>
                  

                </div>


                <div className="col-sm-12 order-lg-0 mb-5">
                    <div className="d-flex justify-content-center justify-content-lg-between">
                        <div className="text-center text-lg-start">
                            <h2 className="profile_text_2 mb-0">Yesterday</h2>
                        </div>
                        <div className="text-end psotion_absoltu ">
                            <span className="learm-tilte2">View All</span>
                        </div>

                    </div>
                </div>

                <div className="row gy-3 p-0 mb-5">

                  
                    <div className="col-sm-4">
                        <div className="dashboard_card p-3">
                            <div className="feed_imgs" style={{ height: '140px' }}>
                                <img className="rounded-3" src={FeedImage} alt=""/>
                            </div>
                            <span className="course_video_time mt-2">Atomic & Nuclear Particle
                                Physics</span>
                            <span className="dashboard_subitel">Instructor: Alok Jain</span>
                        </div>
                    </div>
                   
                    <div className="col-sm-4">
                        <div className="dashboard_card p-3">
                            <div className="feed_imgs" style={{ height: '140px' }}>
                                <img className="rounded-3" src={FeedImage} alt=""/>
                            </div>
                            <span className="course_video_time mt-2">Atomic & Nuclear Particle
                                Physics</span>
                            <span className="dashboard_subitel">Instructor: Alok Jain</span>
                        </div>
                    </div>
                
                    <div className="col-sm-4">
                        <div className="dashboard_card p-3">
                            <div className="feed_imgs" style={{ height: '140px' }}>
                                <img className="rounded-3" src={FeedImage} alt=""/>
                            </div>
                            <span className="course_video_time mt-2">Atomic & Nuclear Particle
                                Physics</span>
                            <span className="dashboard_subitel">Instructor: Alok Jain</span>
                        </div>
                    </div>
                

                </div>


                <div className="col-sm-12 order-lg-0 mb-5">
                    <div className="d-flex justify-content-center justify-content-lg-between">
                        <div className="text-center text-lg-start">
                            <h2 className="profile_text_2 mb-0">Friday  Oct 08</h2>
                        </div>
                        <div className="text-end psotion_absoltu ">
                            <span className="learm-tilte2">View All</span>
                        </div>

                    </div>
                </div>

                <div className="row gy-3 p-0 mb-5">

                  
                    <div className="col-sm-4">
                        <div className="dashboard_card p-3">
                            <div className="feed_imgs" style={{ height: '140px' }}>
                                <img className="rounded-3" src={FeedImage} alt=""/>
                            </div>
                            <span className="course_video_time mt-2">Atomic & Nuclear Particle
                                Physics</span>
                            <span className="dashboard_subitel">Instructor: Alok Jain</span>
                        </div>
                    </div>
                  
                    <div className="col-sm-4">
                        <div className="dashboard_card p-3">
                            <div className="feed_imgs" style={{ height: '140px' }}>
                                <img className="rounded-3" src={FeedImage} alt=""/>
                            </div>
                            <span className="course_video_time mt-2">Atomic & Nuclear Particle
                                Physics</span>
                            <span className="dashboard_subitel">Instructor: Alok Jain</span>
                        </div>
                    </div>
                
                    <div className="col-sm-4">
                        <div className="dashboard_card p-3">
                            <div className="feed_imgs" style={{ height: '140px' }}>
                                <img className="rounded-3" src={FeedImage} alt=""/>
                            </div>
                            <span className="course_video_time mt-2">Atomic & Nuclear Particle
                                Physics</span>
                            <span className="dashboard_subitel">Instructor: Alok Jain</span>
                        </div>
                    </div>
                 

                </div>

            </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TeacherHistory;
