// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-slider img {
    width: 100%;
    height: 400px;
}

.thumbnail-slider img {
    width: 250px;
    height: 160px;
    margin: 5px;
    cursor: pointer;
}

.thumbnail-slider p {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 5px;
    font-size: 12px;
}

.slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Slider Container */
.slick-slider {
    position: relative;
}

.slick-slide img {
    display: block;
    margin: 0 auto;
    border-radius: 8px;
}

/* Custom Arrows */
.slick-prev  {
    z-index: 2;
    margin-left: -20px;
}
.slick-next {
    z-index: 2;
    margin-right: -20px;
   
}

.slick-prev::before, .slick-next::before {
    content: ''; 
}



`, "",{"version":3,"sources":["webpack://./src/css/SliderStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,oCAAoC;IACpC,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA,qBAAqB;AACrB;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,kBAAkB;AACtB;;AAEA,kBAAkB;AAClB;IACI,UAAU;IACV,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,mBAAmB;;AAEvB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".main-slider img {\n    width: 100%;\n    height: 400px;\n}\n\n.thumbnail-slider img {\n    width: 250px;\n    height: 160px;\n    margin: 5px;\n    cursor: pointer;\n}\n\n.thumbnail-slider p {\n    position: absolute;\n    bottom: 10px;\n    left: 10px;\n    background-color: rgba(0, 0, 0, 0.6);\n    color: #fff;\n    padding: 5px;\n    font-size: 12px;\n}\n\n.slick-slide {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n/* Slider Container */\n.slick-slider {\n    position: relative;\n}\n\n.slick-slide img {\n    display: block;\n    margin: 0 auto;\n    border-radius: 8px;\n}\n\n/* Custom Arrows */\n.slick-prev  {\n    z-index: 2;\n    margin-left: -20px;\n}\n.slick-next {\n    z-index: 2;\n    margin-right: -20px;\n   \n}\n\n.slick-prev::before, .slick-next::before {\n    content: ''; \n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
