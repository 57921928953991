import React, { useEffect, useState } from 'react';
import { Footer, Navbar } from "../../components";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url, ACCESS_Role_ID, ACCESS_Log_In_User_Id } from '../../constants/apiConstants';
import { formatDate, calculateDifference, dateMonthYears, renderStars } from "../../pages/Utils";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";
import SearchIcon from "../../assets/images/all/search.svg";

import StarIcon from "../../assets/images/all/star-svgrepo-com.svg";
import LectureImage from "../../assets/images/all/leachure.png";
import { debounce } from 'lodash'; // Optional for debouncing
import shapeImage from "../../assets/images/all/shape_1.png"
import shapeImage1 from "../../assets/images/icons/Unions.svg"

const Teacher = () => {
  const [teacher, setTeacher] = useState([]);
  const [bioInfo, setbioInfo] = useState([]);
  const [profileImg, setprofileImg] = useState([]);
  const [teacherFollower, setTeacherFollower] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null); // State to hold error messages
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [teacherId, setteacherId] = useState([]);
  const [averageRatings, setAverageRatings] = useState([]);

  const [openFilter, setOpenFilter] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followedBasedOnUserId, setFollowedBasedOnUserId] = useState([]);

  // Search Start
  const [query, setQuery] = useState('');
  const [searchTeachers, setSearchTeachers] = useState([]);

  // Handle search input change
  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  // Debounced search function to limit API calls
  const handleSearch = debounce(async () => {
    if (!query.trim()) return;

    setLoading(true);
    setError('');

    try {
      const response = await axios.get(API_BASE_URL + `/teacherSearch?query=${query}`);
      console.log("sdfsdfsd sdfsdf sdfsdfsd",response.data.data);
      // setTeachers(response.data.teachers);
      setSearchTeachers(response.data.data);
      setLoading(false);
      // setInstitutes(response.data.institutes);
    } catch (err) {
      setError('An error occurred while searching.');
    } finally {
      setLoading(false);
    }
  }, 500); // Debounce delay of 500ms

  // Trigger search on key press (after debouncing)
  useEffect(() => {
    handleSearch();
  }, [query]); // Trigger handleSearch when the query changes
  // Handle teacher click to navigate to the detail page
  const handleTeacherClick = (teacherId) => {
    navigate(`/TeacherDetail/${teacherId}`); // Navigate to the teacher detail page
  };

  // Search End


  // Follow and unfollow start
  useEffect(() => {
    fetchFollowBasedOnUserId();
  }, []); // Empty dependency array to run only on mount
  const fetchFollowBasedOnUserId = async () => {
    try {
      const response = await axios.get(API_BASE_URL + `/followers?user_id= ${ACCESS_Log_In_User_Id()}`) // Replace with actual user id

      console.log("response");
      console.log(response.data);
      setFollowedBasedOnUserId(response.data);
      console.log("response");
      //setCourse(response.data); // Set roles data to state
      setLoading(false); // Set loading to false
    } catch (err) {
      setError(err.message); // Handle error
      setLoading(false); // Set loading to false
    }
  };
  const [followingStatus, setFollowingStatus] = useState(
    teacher?.reduce((acc, item) => {
      acc[item.id] = item.isFollowing || false; // Initialize each teacher with its follow status
      return acc;
    }, {})
  );

  const toggleFollow = async (id) => {
    const newFollowStatus = !followingStatus[id]; // Toggle follow status for the specific teacher
    setFollowingStatus((prevState) => ({
      ...prevState,
      [id]: newFollowStatus, // Update the follow status of the specific teacher
    }));

    const apiUrl = newFollowStatus ? API_BASE_URL + '/follow' : API_BASE_URL + '/unfollow';
    try {
      const response = await axios.post(apiUrl, {
        user_id: ACCESS_Log_In_User_Id(),
        teacher_id: id,
      });
      fetchFollowBasedOnUserId();
      console.log(response.data);
    } catch (error) {
      console.error(error);
      // Revert the state change if the API request fails
      setFollowingStatus((prevState) => ({
        ...prevState,
        [id]: !newFollowStatus, // Revert the follow status in case of an error
      }));
    }
  };



  useEffect(() => {
    const url = API_BASE_URL + '/getTeachersData'
    const fetchTeacher = async () => {
      try {
        const response = await axios.get(url);
// console.log("12121212121212",response);
        setTeacher(response.data.data.personalInfo); // Set roles data to state
        setbioInfo(response.data.data.bioInfo); // Set roles data to state
        setprofileImg(response.data.data.profileImg); // Set roles data to state
        console.log(teacher);
        console.log(bioInfo);
        console.log(profileImg);

        setLoading(false); // Set loading to false
      } catch (err) {
        setError(err.message); // Handle error
        setLoading(false); // Set loading to false
      }
    };

    fetchTeacher(); // Call the fetch function
  }, []); // Empty dependency array to run only on mount
  useEffect(() => {
    const fetchTeacherFollowers = async () => {
      try {
        // Create an array of promises for each product API call
        const promises = teacher?.map(item =>
          axios.get(`${API_BASE_URL}/getTeacherfollowers?teacher_id=${item.id}`)
        );
        // Wait for all the promises to resolve
        const responses = await Promise.all(promises);
        const teacherFollowers = responses?.map(response => response.data);
        console.log(teacherFollowers);
        setTeacherFollower(teacherFollowers);
      } catch (err) {
        // Handle errors
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeacherFollowers();
  }, [teacher]); // Run the effect when cartItems changes

  const toggleFilter = (filter) => {
    setOpenFilter((prev) => (prev === filter ? null : filter));
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };
  // Rating start
  const fetchRatings = async () => {
    setLoading(true);
    try {
      const promises = teacher?.map(item =>
        axios.get(`${API_BASE_URL}/ratings/${item.id}`)
      );
      const responses = await Promise.all(promises);
      const courserating = responses?.map(response => response.data.data);
      const averageRatingsss = courserating?.map((ratingsArray) => {
        return calculateAverageRating(ratingsArray);
      });

      //setRatings(courserating);
      setAverageRatings(averageRatingsss);
      renderStars(averageRatingsss)
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRatings();
  }, [teacher]);

  const handleRating = async (star, teacherId) => {
    setRating(star);
    setteacherId(teacherId);
    await axios.post(API_BASE_URL + '/ratings', {
      user_id: ACCESS_Log_In_User_Id(), // Replace with actual user id
      teacher_id: teacherId,
      rating: star,
    });
    // fetchRatings(); 
  };

 

// const handleRating = async (star, teacherId) => {
//   try {
//     // Set local state for rating and teacherId
//     setRating(star);
//     setTeacherId(teacherId);

//     // Make API call to submit rating
//     const response = await axios.post(`${API_BASE_URL}/ratings`, {
//       user_id: ACCESS_Log_In_User_Id(), // Ensure this function returns a valid user ID
//       teacher_id: teacherId,
//       rating: star,
//     });

//     console.log('Rating submitted successfully:', response.data);
    
//     // Uncomment this if you want to fetch updated ratings
//     // fetchRatings(); 
//   } catch (error) {
//     // Handle errors appropriately
//     if (error.response) {
//       // Server-side error (4xx, 5xx responses)
//       console.error('Server error:', error.response.data);
//     } else if (error.request) {
//       // Network error
//       console.error('Network error:', error.request);
//     } else {
//       // Other errors
//       console.error('Error:', error.message);
//     }

//     alert('Error submitting rating. Please try again.');
//   }
// };

  const calculateAverageRating = (ratingsArray) => {
    const totalRating = ratingsArray.reduce((sum, ratingObj) => sum + ratingObj.rating, 0);
    return (totalRating / ratingsArray.length).toFixed(2); // Return average rounded to 2 decimal places

  };

  // Rating end
  return (
    <div>
      {/* Scroll to Top */}
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>

      <img className="shape_top d-lg-block d-none" src={shapeImage} alt="" />
      <img className="shape_top_6 d-xl-block d-none" src={shapeImage1} alt="" />

      {/* Mobile Menu */}
      {/* {mobileMenuOpen && (
        <div className="mobile-menu d-lg-none d-block">
          <button
            type="button"
            className="close-button"
            onClick={toggleMobileMenu}
          >
            <i className="las la-times"></i>
          </button>
          <div className="mobile-menu__inner">
            <div className="mobile-menu__menu">
            
              <ul className="nav-menu flx-align nav-menu--mobile">
                <li className="nav-menu__item activePage">
                  <a href="/" className="nav-menu__link">
                    Home
                  </a>
                </li>
                <li className="nav-menu__item">
                  <a href="/course.html" className="nav-menu__link">
                    Courses
                  </a>
                </li>
                <li className="nav-menu__item">
                  <a href="/" className="nav-menu__link">
                    Feed
                  </a>
                </li>
                <li className="nav-menu__item">
                  <a href="/" className="nav-menu__link">
                    Videos
                  </a>
                </li>
                <li className="nav-menu__item">
                  <a href="/" className="nav-menu__link">
                    Teachers
                  </a>
                </li>
                <li className="nav-menu__item">
                  <a href="/" className="nav-menu__link">
                    Institutes
                  </a>
                </li>
              </ul>
            
              <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
                <a href="/" className="btn btn-main pill sign_btn px-sm-4">
                  Signup/Login
                </a>
              </div>
            </div>
          </div>
        </div>
      )} */}

          <Navbar/>
      {/* Header */}
      {/* <header>
        <div className="logo"></div>
        <div className="header-menu d-lg-block d-none">
          <ul className="nav-menu flx-align">
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Home
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/course" className="nav-menu__link">
                Courses
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/Feed" className="nav-menu__link">
                Feed
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Learn with us
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Teachers
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Institute
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                About Us
              </a>
            </li>
          </ul>
        </div>
        <div className="header-right flx-align pe-lg-5">
          <div className="header-right__inner gap-3 flx-align d-lg-flex d-none">
            <a href="/" className="btn btn-main pill sign_btn px-sm-4">
              Signup/Login
            </a>
            <a href="/" className="btn cicrle_btn btn-main">
              <img src={LogoImage} alt="Logo" />
            </a>
          </div>
          <button type="button" className="toggle-mobileMenu d-lg-none">
            <i className="las la-bars"></i>
          </button>
        </div>
      </header> */}

      {/* <Navbar /> */}

      {/* Banner Section */}
      <section className="hero section-bg z-index-1">
        <div className="container py-5 container-two">
          <div className="row align-items-center gy-sm-5 gy-4">
            <div
              className="col-lg-12 order-1 order-lg-0"
              style={{ marginTop: "110px" }}
            >
              <div className="position-relative">
                <div className="search-box">
                  {/* <input
                    type="text"
                    className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                    placeholder="Search"
                  />
                  <button
                    type="submit"
                    className="btn btn-main spl_burron btn-icon icon border-0"
                  >
                    <img src={SearchIcon} alt="Search" />
                  </button> */}
                  <input
                    type="text"
                    value={query}
                    onChange={handleChange}
                    placeholder="Search for teacher"
                    className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                  />

                  {loading && <p>Loading...</p>}
                  {error && <p style={{ color: 'red' }}>{error}</p>}

                  {searchTeachers.length > 0 ? (
                    <ul>
                      {searchTeachers?.map((teacher) => (
                        <li key={teacher.id} onClick={() => handleTeacherClick(teacher.id)}>
                          {teacher.name} - {teacher.bio_info}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Filter Sidebar */}
      <section className="pb-5">
        <div className="container">
          <div className="row gy-4 justify-content-center">
            {/* Filter Sidebar */}
            <div className="col-xl-3 col-lg-3">
              <p className="course-titles d-none d-xl-block">Filter</p>

              <div className="d-block d-lg-none">
                <div className="filter-sidebar p-0">
                  <div className="filter-sidebar__pills d-lg-none d-flex gap-2 overflow-auto">
                    <button
                      className="btn-outline-primary_1 rounded-pill flex-shrink-0"
                      type="button"
                      data-filter="filter"
                      style={{ paddingRight: "10px" }}
                    >
                      <img
                        src="assets/images/icons/filter-edit-svgrepo-com (1) 1.svg"
                        alt=""
                      />
                    </button>

                    <button
                      className="filter-sidebar__button btn-outline-primary_1 rounded-pill flex-shrink-0"
                      type="button"
                      data-filter="category"
                    >
                      Category
                    </button>
                    <button
                      className=" filter-sidebar__button btn-outline-primary_1 rounded-pill flex-shrink-0"
                      type="button"
                      data-filter="rating"
                    >
                      Rating
                    </button>
                    <button
                      className="filter-sidebar__button btn-outline-primary_1 rounded-pill flex-shrink-0"
                      type="button"
                      data-filter="dateUpdated"
                    >
                      Date Updated
                    </button>
                  </div>

                  <div className="collapse spaldrop_down" id="categoryCollapse">
                    <ul className="filter-sidebar-list">
                      <li className="filter-sidebar-list__item">
                        <a href="#" className="filter-sidebar-list__text">
                          All Categories <span className="qty">25489</span>
                        </a>
                      </li>
                      <li className="filter-sidebar-list__item">
                        <a href="#" className="filter-sidebar-list__text">
                          Site Template <span className="qty">12,501</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="collapse spaldrop_down" id="ratingCollapse">
                    <ul className="filter-sidebar-list">
                      <li className="filter-sidebar-list__item">
                        <div className="filter-sidebar-list__text">
                          <div className="common-check common-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="rating"
                              id="viewAll"
                            />
                            <label className="form-check-label" for="viewAll">
                              {" "}
                              View All{" "}
                            </label>
                          </div>
                          <span className="qty">(1859)</span>
                        </div>
                      </li>
                      <li className="filter-sidebar-list__item">
                        <div className="filter-sidebar-list__text">
                          <div className="common-check common-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="rating"
                              id="oneStar"
                            />
                            <label className="form-check-label" for="oneStar">
                              {" "}
                              1 Star and above
                            </label>
                          </div>
                          <span className="qty">(785)</span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div
                    className="collapse spaldrop_down"
                    id="dateUpdatedCollapse"
                  >
                    <ul className="filter-sidebar-list">
                      <li className="filter-sidebar-list__item">
                        <div className="filter-sidebar-list__text">
                          <div className="common-check common-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="dateUpdated"
                              id="anyDate"
                            />
                            <label className="form-check-label" for="anyDate">
                              {" "}
                              Any Date{" "}
                            </label>
                          </div>
                          <span className="qty">5,203</span>
                        </div>
                      </li>
                      <li className="filter-sidebar-list__item">
                        <div className="filter-sidebar-list__text">
                          <div className="common-check common-radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="dateUpdated"
                              id="lastYear"
                            />
                            <label className="form-check-label" for="lastYear">
                              {" "}
                              In the last year
                            </label>
                          </div>
                          <span className="qty">1,258</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="d-none d-lg-block">
                <div className="filter-sidebar">
                  <div className="filter-sidebar__item">
                    <button
                      type="button"
                      className="filter-sidebar__button font-16 text-capitalize fw-500"
                    >
                      Category
                    </button>
                    <div className="filter-sidebar__content">
                      <ul className="filter-sidebar-list">
                        <li className="filter-sidebar-list__item">
                          <a href="" className="filter-sidebar-list__text">
                            All Categories <span className="qty">25489</span>
                          </a>
                        </li>
                        <li className="filter-sidebar-list__item">
                          <a href="" className="filter-sidebar-list__text">
                            Site Template <span className="qty">12,501</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="filter-sidebar__item">
                    <button
                      type="button"
                      className="filter-sidebar__button font-16 text-capitalize fw-500"
                    >
                      Rating
                    </button>
                    <div className="filter-sidebar__content">
                      <ul className="filter-sidebar-list">
                        <li className="filter-sidebar-list__item">
                          <div className="filter-sidebar-list__text">
                            <div className="common-check common-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radio"
                                id="veiwAll"
                              />
                              <label className="form-check-label" for="veiwAll">
                                {" "}
                                View All@
                              </label>
                            </div>
                            <span className="qty">(1859)</span>
                          </div>
                        </li>
                        <li className="filter-sidebar-list__item">
                          <div className="filter-sidebar-list__text">
                            <div className="common-check common-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radio"
                                id="oneStar"
                              />
                              <label className="form-check-label" for="oneStar">
                                {" "}
                                1 Star and above
                              </label>
                            </div>
                            <span className="qty">(785)</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="filter-sidebar__item">
                    <button
                      type="button"
                      className="filter-sidebar__button font-16 text-capitalize fw-500"
                    >
                      Date Updated
                    </button>
                    <div className="filter-sidebar__content">
                      <ul className="filter-sidebar-list">
                        <li className="filter-sidebar-list__item">
                          <div className="filter-sidebar-list__text">
                            <div className="common-check common-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radio"
                                id="anyDate"
                              />
                              <label className="form-check-label" for="anyDate">
                                {" "}
                                Any Date
                              </label>
                            </div>
                            <span className="qty"> 5,203</span>
                          </div>
                        </li>
                        <li className="filter-sidebar-list__item">
                          <div className="filter-sidebar-list__text">
                            <div className="common-check common-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radio"
                                id="lastYear"
                              />
                              <label
                                className="form-check-label"
                                for="lastYear"
                              >
                                {" "}
                                In the last year
                              </label>
                            </div>
                            <span className="qty">1,258</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Course Card */}
            <div className="col-lg-9 col-xl-8 col-sm-12 col-md-12">
              <div className="learn_withcard teachers_card">
                {teacher?.map((item, i) => (
                  <div className="row">
                    {profileImg?.map((items, index) => (
                      i === index && (
                        <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-3">
                          <div className="img">
                            <img
                              src={`${Image_Base_Url}${items.profile_image_path}`}
                              alt="How to Solve Physics Problems"
                              className="img-cover w-100 h-100"
                            />
                          </div>
                        </div>
                      )
                    ))}
                    <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-9">
                      <div className="row ps-3 ps-lg-0">
                        <div className="col-sm-8 order-lg-0">
                          <div className="d-flex justify-content-between">
                            <div>
                              {/* <h4 className="learm-tilte mb-0">{item.full_name}</h4> */}
                              <div><Link className="learm-tilte mb-0" to={`/TeacherDetail/${item.user_id}`}>{item.full_name}
                                                           
                                                          </Link></div>
                              {ACCESS_TOKEN_NAME() ? (
                                // Render follow/unfollow button dynamically based on teacher's follow status
                                // Check if there are any followed courses
                                followedBasedOnUserId && followedBasedOnUserId.length > 0 ? (
                                  // Find if the current item's ID is in followedBasedOnUserId
                                  <button
                                    key={item.id} className="btn btn_cousre learn_follow px-4 py-2"
                                    onClick={() => toggleFollow(item.id)}
                                  >
                                    {followedBasedOnUserId.some(items => items.teacher_id === item.id) ? 'Unfollow' : 'Follow'}
                                  </button>
                                ) : (
                                  <button className="btn btn_cousre learn_follow px-4 py-2" onClick={() => toggleFollow(item.id)}
                                  >
                                    {followingStatus[item.id] ? 'Unfollow' : 'Follow'} </button>
                                )
                              ) : (
                                teacherFollower?.map((follower, index) => {
                                  if (i === index) {
                                    return <span key={index} className="learn-title2">{follower} Followers</span>;
                                  }
                                  return null;
                                })
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 order-3 order-lg-1 text-lg-end">
                          <div className="course-rating d-flex">
                            {/* {[...Array(5)].map((_, index) => (
                              <span key={index} className="Stars" title="5 Stars">
                                <img src={StarIcon} alt="5 stars" />
                              </span>
                            ))} */}
                            {ACCESS_TOKEN_NAME() && ACCESS_Role_ID() == 1 ? (
                              [...Array(5)]?.map((_, index) => (
                                //i === index ? ( // Use a ternary operator to conditionally render
                                <span
                                  key={index + 1}
                                  onClick={() => handleRating(index + 1, item.id)} // Function to handle user click
                                  style={{
                                    cursor: 'pointer',
                                    color: index + 1 <= rating ? '#FFD700' : '#ccc', // Filled star if rating is equal or higher
                                    fontSize: '24px',
                                  }}
                                >
                                  ★
                                </span>
                                // ) : null // Return null if the condition is not met
                              ))
                            ) : (
                              averageRatings?.map((item, index) => {
                                if (i == index) {
                                  const numberValue = item === "NaN" ? NaN : parseFloat(item);
                                  return (
                                    <div>
                                      {isNaN(numberValue) ? renderStars(0) : renderStars(numberValue)}
                                    </div>
                                  );
                                }
                              })
                            )}
                          </div>
                        </div>
                        <div className="col-sm-12 order-lg-2">
                          {bioInfo?.map((itemss, index) => (
                            i === index && (
                              <p className="course-description text-muted mt-xl-3">
                                {itemss.bio_info}
                              </p>
                            )
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section >

      {/* Footer */}
      < Footer />
    </div >
  );
};

export default Teacher;
